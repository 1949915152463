// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-page {
  max-width: 500px;
  padding: 40px;
  margin: 0 auto;
}

.form-page h1 {
  font-size: 24px;
}

.modal .form-page {
  padding: 12px 16px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".form-page {\n  max-width: 500px;\n  padding: 40px;\n  margin: 0 auto;\n}\n\n.form-page h1 {\n  font-size: 24px;\n}\n\n.modal .form-page {\n  padding: 12px 16px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
