import React, {Component} from 'react'

import Icon from 'components/Shared/Icon'

class ItemHeader extends Component {
  render() {
    const { title, subtitle, icon, renderActions } = this.props

    return (
      <div className="panel-item-header">
        {icon && <Icon type={icon} />}
        <div className="panel-item-header-wrapper">
          <span className="panel-item-header-subtitle">{subtitle}</span>
          <span className="panel-item-header-title">{title}</span>
        </div>
        {renderActions ? (
          <div className="panel-item-header-actions">{renderActions()}</div>
        ) : null}
      </div>
    )
  }
}

export default ItemHeader
