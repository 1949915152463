// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-image-control {
}

.library-image-control .wrapped-select {
  margin: 10px -16px 0;
  height: 44px;
  background: #f1f1f1;
  box-shadow: none;
}

.image-control-dynamic-image-option .icon {
  background: #ef4c30;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ImageControl.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,oBAAoB;EACpB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".library-image-control {\n}\n\n.library-image-control .wrapped-select {\n  margin: 10px -16px 0;\n  height: 44px;\n  background: #f1f1f1;\n  box-shadow: none;\n}\n\n.image-control-dynamic-image-option .icon {\n  background: #ef4c30;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
