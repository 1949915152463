// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-state.greedy.editor-empty-canvas {
  position: relative;
  z-index: 1;
  margin-top: 64px;
  margin-left: 431px;
  width: auto;
  transition: margin 0.2s;
}

.offline-banner-visible .empty-state.greedy.editor-empty-canvas {
  margin-top: 96px;
}

.editor-empty-canvas > *:last-child {
  margin-bottom: 50px;
}
.editor-empty-canvas h1 {
  font-size: 24px;
}
.editor-empty-canvas p {
  font-size: 14px;
}
.editor-empty-canvas .add-screen-link {
  color: #3e87f8 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/template/2d/Editor/Canvas/Empty/Empty.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,uBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAMI;EACE,mBAAA;AAHN;AAOE;EACE,eAAA;AALJ;AAQE;EACE,eAAA;AANJ;AASE;EACE,yBAAA;AAPJ","sourcesContent":["@import '../../../../../../../styles/_colors.scss';\n\n.empty-state.greedy.editor-empty-canvas {\n  position: relative;\n  z-index: 1;\n  margin-top: 64px;\n  margin-left: 431px;\n  width: auto;\n  transition: margin 0.2s;\n}\n\n.offline-banner-visible .empty-state.greedy.editor-empty-canvas {\n  margin-top: 96px;\n}\n\n.editor-empty-canvas {\n  > * {\n    &:last-child {\n      margin-bottom: 50px;\n    }\n  }\n\n  h1 {\n    font-size: 24px;\n  }\n\n  p {\n    font-size: 14px;\n  }\n\n  .add-screen-link {\n    color: $teal !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
