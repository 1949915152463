import React, {Component} from 'react'
import {connect} from 'react-redux'
import {sort} from 'common/utils'

import {getHoverSelection, getSelection, setLayersHover, setSelection,} from 'ducks/editor/selection'

import {getPath, selectObjects} from 'ducks/editor/objects'
import {setActiveAddTab, setActiveTab} from 'ducks/editor/tabs'

import { updateApp } from "../../../ducks/apps/actions";
import { getApp } from "ducks/apps/selectors";
import EmptyState from 'components/Shared/EmptyState'
import {PanelItem} from 'components/Shared/Panel'
import {showModal} from "../../../ducks/editor/modals";
import {withRouter} from "react-router-dom";

class LayersMenu extends Component {
    handleClick = async () => {
        const { setActiveTab, setActiveAddTab, showModal } = this.props
        let { history, appId, deleteApp } = this.props


        history.push(`/apps/${appId}/layers`)
    }

    renderScreens = () => {
        let { app, objects, setLayersHover, setSelection } = this.props

        if (!app || objects.length === 0) {
            return <EmptyState>Nothing drawn yet</EmptyState>
        }


        // sort objects A-Z by name
        const sorted = sort(objects, obj => obj.order)

        return sorted.map(object => {
            let { id, name } = object

            let icon = null

            /*switch (id) {
              case authComponentId:
                icon = 'welcome-screen'

                break
              case launchComponentId:
                icon = 'home'

                break
              default:
                icon = 'normal-screen'

                break
            }*/

            const handleMouseEnter = () => setLayersHover(id)
            const handleMouseLeave = () => setLayersHover(null)
            const handleClick = () => {

                const {app, updateApp} =  this.props
                updateApp(app.id, {launchComponentId: object.id})

            }

            return (
                <PanelItem
                    key={id}
                    content={name}
                    title={name}
                    icon={icon}
                    fluid
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                />
            )
        })
    }

    render() {
        return (
            <div className="layers-wrapper">
                <h2 className="left-panel-title">
                    Управление слоями
                </h2>

                {this.renderScreens()}

                <PanelItem
                    title="Добавить слой ложимент"
                    icon="plus"
                    color="screens"
                    uppercase
                    fluid
                    onClick={this.handleClick}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, { appId }) => {
    return {
        appId,
        app: getApp(state, appId),
        objects: selectObjects(state),
        selection: getSelection(state).map(id => getPath(state, id)),
        hoverSelection: getHoverSelection(state).map(id => getPath(state, id)),
    }
}

const mapDispatchToProps = {
    showModal,
    setActiveTab,
    setActiveAddTab,
    setLayersHover,
    setSelection,
    updateApp
}

const ConnectedLayersMenu = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LayersMenu))

export default ConnectedLayersMenu
