import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import {withRouter} from 'react-router-dom'

import {ADD, BOXES, SCREENS, SETTINGS} from 'utils/tabs'

import {getActiveTab, setActiveTab} from 'ducks/editor/tabs'
import {getSelection, setSelection} from 'ducks/editor/selection'

import {Panel} from 'components/Shared/Panel'
import {SettingsPanel} from 'components/AppDetail/Settings'
import AddPanel from 'components/Editor/AddObject/Panel'
import PanelBox from 'components/Editor/PanelBox'
import EditPanel from 'components/Editor/RightPanel'
import ScreensPanel from 'components/Editor/Screens'


import { getEditingShape } from 'ducks/editor/shapeEditing'
import { setEditingShape } from 'ducks/editor/shapeEditing'


import './LeftPanel.scss'

class LeftPanel extends Component {
  componentDidMount() {
    const { activeTab, setActiveTab } = this.props

    if (!activeTab) {
      const path = window.location.pathname

      if (
        path.includes('/screens')
      ) {
        return setActiveTab(null)
      }


    }
  }

  renderSub() {

    let { match, selection, activeTab } = this.props

    if (selection.length > 0) return <EditPanel />

    switch (activeTab) {
      case ADD:
        /*<BodyClass className="offline-banner-visible" />*/
        return <AddPanel />
      case BOXES:
        return <PanelBox appId={match.params.appId}/>
      case SETTINGS:
        return <SettingsPanel />
      case SCREENS:
        return <ScreensPanel appId={match.params.appId} />
      default:
        return null
    }

  }

  cl = () => {
    
    let { setEditingShape, isEditingShape } = this.props

    if(isEditingShape){
      setEditingShape()
    }
    
  }

  render() {

    let { activeTab, selection, setEditingShape, isEditingShape } = this.props
    let children = this.renderSub()

    return (
      <div onMouseDown={this.cl}>
        {}
        <Panel className={classNames('edit-bar', { 'edit-bar-empty': children === null })} >
          {children}
        </Panel>
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params
  const selection = getSelection(state)

  return {
    isEditingShape: getEditingShape(state),
    activeTab: getActiveTab(state),
    selection
  }
}

const mapDispatchToProps = {
  setActiveTab,
  setSelection,
  setEditingShape,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(LeftPanel)

export default withRouter(connected)
