import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
    COMPONENT,
    dataTypes,
    ELLIPSE,
    GROUP,
    IMAGE,
    IMAGE_UPLOAD,
    LABEL,
    LINE,
    RECTANGLE,
    SECTION,
    SHAPE,
} from 'common/constants'

import {
    alignObjects,
    flipObjects,
    getComponentId,
    getCurrentAppId,
    selectObjects,
    updateObject,
    updateObjects,
} from '../../../ducks/editor/objects'

import { getEditingShape } from 'ducks/editor/shapeEditing'

import {getSelection} from '../../../ducks/editor/selection'
import {PanelSection} from '../../Shared/Panel'
import EmptyState from '../../Shared/EmptyState'

import InspectBody from './Body'
import BorderRadiusControl, {getMaxBorderRadius} from './BorderRadiusControl'


import MultiSelectionControl from './MultiSelectionControl'
import GroupControl from './GroupControl'

import 'styles/Inspect.css'


import ObjectAlignControl from "./ObjectAlignControl";
import Scrollbar from "../../Shared/Scrollbar";

const STYLES_ACCORDION = new Set([
    COMPONENT,
    LABEL,
    SHAPE,
    SECTION,
    ELLIPSE,
    IMAGE_UPLOAD,
    IMAGE,
  RECTANGLE,
    GROUP,
    LINE,
])

class InspectMenu extends Component {

    handleChange = value => {
        let {objects, updateObjects} = this.props

        let newObjects = objects.map(({id}) => ({...value, id}))
        updateObjects(newObjects)
    }

    handleAlign = direction => {

        let {alignObjects} = this.props
        alignObjects(direction)
    }

    handleFlip = direction => {

        let {flipObjects} = this.props
        flipObjects(direction)
    }

    handleComposedValueChange = newValue => {
        const {composedValue: value} = newValue

        if (value === 'uploaded') {
            this.handleChange({imageType: 'uploaded', imageBinding: null})
        } else if (value === 'url') {
            this.handleChange({imageType: 'url', imageBinding: null})
        } else if (typeof value !== 'undefined') {
            this.handleChange({
                imageType: 'dynamic',
                imageBinding: value,
                filename1x: null,
            })
        } else {
            this.handleChange(newValue)
        }
    }

    getFieldOptions(includeNone = false) {
        let {fields} = this.props

        let simpleTypes = [
            dataTypes.TEXT,
            dataTypes.NUMBER,
            dataTypes.DATE,
            dataTypes.BOOLEAN,
        ]

        let options = []

        if (includeNone) {
            options.push({label: 'None', value: null})
            options.push(null)
        }


        return options
    }

    render() {
        //return <EmptyState>Nothing Selected</EmptyState>
        const {appId, componentId, objects} = this.props

        if (!objects || objects.length === 0) {
            return <EmptyState>Пусто</EmptyState>
        }

        const object = objects.length === 1 ? objects[0] : null

        if (objects.length > 1 || (object && STYLES_ACCORDION.has(object.type))) {
            const skipFeatures = []
            const result = []

            /*result.push(
                <FlipControl
                    objects={objects}
                    name={"flip"}
                    onChange={this.handleFlip}
                    key="flip-control"
                />
            )*/


            if (objects.length > 1) {
                result.push(
                    <ObjectAlignControl
                        objects={objects}
                        name={"align"}
                        onChange={this.handleAlign}
                        key="object-align-control"
                    />
                )
            }
            result.push(
                <InspectBody objects={objects} skipFeatures={skipFeatures}/>
            )


            if (object && object.type === SECTION) {
                skipFeatures.push('borderRadius')

                result.push(
                    <BorderRadiusControl
                        key="border-radius-control"
                        onChange={this.handleChange}
                        value={object.borderRadius}
                        name="borderRadius"
                        min={0}
                        max={getMaxBorderRadius(object)}
                    />
                )
            }

            if (object && object.type === LINE) {
                skipFeatures.push('shadow', 'borderRadius')
            }

            if (object && object.type === GROUP) {
                result.push(<GroupControl object={object} key="group-control"/>)
            }


            if (objects.length > 1) {
                result.push(
                    <MultiSelectionControl
                        objects={objects}
                        key="multi-selection-control"
                    />
                )
            }


            return <PanelSection title="Свойства">{result}</PanelSection>
        }

        return (
            <PanelSection title="Properties">
                <InspectBody objects={objects}/>
            </PanelSection>
        )
    }
}

const mapStateToProps = (state, props) => {

    
    const appId = getCurrentAppId(state)
    const selection = getSelection(state)
    const objects = selectObjects(state, selection)
    let object = {}
    let componentId = null

    if (selection.length === 0) return {appId}

    if (selection.length === 1) {
        componentId = getComponentId(state, selection[0])
        object = objects[0]
    }

    return {
        appId,
        componentId,
        objects,
        bindingOptions: null,
        isEditingShape: getEditingShape(state),
    }
}

export default connect(mapStateToProps, {updateObjects, updateObject, alignObjects, flipObjects})(
    InspectMenu
)
