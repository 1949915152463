import React from 'react'
import ReactDOM from 'react-dom'
import {dataTypes, LIBRARY_COMPONENT} from 'common/constants'
import {getId, traverseMapObject} from 'common/utils'

import {getAuthToken} from './auth'

import {getObjectName} from './naming'
import {capitalize} from './strings'
import {normalizeColor} from './colors'
import {assetURL} from './assets'
import {getFontFamily} from './type'
import {deepSet} from './objects'

const noop = () => {}
const bucket = process.env.REACT_APP_COMPONENT_REGISTRY_BUCKET
const defaultURL = `http://localhost:8083/api/component-marketplace-prod${bucket}`
const localURL = `${process.env.REACT_APP_DEV_SERVER}/libraries`
const cache = {}

// Returns promise
export const loadLibrary = (name, version) => {
  let baseURL = version === 'dev' ? localURL : defaultURL
  let url

  if (version === 'dev') {
    url = `${baseURL}/editor-${version}.js`
    url = `${url}?authToken=${getAuthToken()}&timestamp=${+new Date()}`
  } else {
    url = `${baseURL}/editor-${version}.js`
  }

  // Check if already in cache
  if (cache[url]) {
    return cache[url]
  }

  let script = document.createElement('script')
  script.src = url
  script.setAttribute('crossorigin', '')
  document.body.appendChild(script)

  let promise = new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      resolve()
    })

    script.addEventListener('error', () => {
      reject(new Error(`Script could not load: ${url}`))
    })
  })

  cache[url] = promise

  return promise
}

export const removeLibrary = name => {
  const { scripts } = document

  // remove appended script from page
  Object.entries(scripts).forEach(([key]) => {
    const script = scripts[key]

    if (script?.src.includes(name)) {
      delete cache?.[script.src]
      document.body.removeChild(script)
    }
  })

  // remove library from protonLibraries object
  delete window.protonLibraries?.[name]
}

export const getLibraryComponent = (libraryName, version, componentName) => {
  let library = getLibrary(libraryName, version)

  if (!library) {
    return null
  }

  return library.components[componentName]
}

export const getLibrary = (libraryName, version) => {
  let versionMap = window.protonLibraries?.[libraryName]
  if (!version && versionMap) version = Object.keys(versionMap)?.[0]

  return versionMap?.[version]
}

export const getAppLibrary = (app, libraryName) => {
  let dependencies = getLibraryDependencies(app)
  let dependency = dependencies?.filter(({ name }) => name === libraryName)?.[0]
  let version = dependency?.version

  return getLibrary(libraryName, version)
}

export const getComponentInfo = (libraryName, version, componentName) => {
  let library = getLibrary(libraryName, version)

  if (!library) {
    return null
  }

  return library.config.components.filter(c => c.name === componentName)[0]
}

export const getAppComponent = (app, libraryName, componentName) => {
  let library = getAppLibrary(app, libraryName)

  if (!library) {
    return null
  }

  return library.config.components.filter(c => c.name === componentName)[0]
}

export const getDefaultProps = config => {
  let props = getDefaultMap(config.props)

  if (config.childComponents) {
    for (let child of config.childComponents) {
      props[child.name] = getDefaultMap(child.props)
    }
  }

  return {
    positioning: props.positioning,
    attributes: props,
  }
}

const getDefaultMap = props => {
  let result = {}

  if (!Array.isArray(props)) {
    throw new Error('Invalid props:', props)
  }

  for (let prop of props) {
    if (typeof prop.default !== 'undefined' && !prop.global) {
      if (prop.default !== null) {
        result[prop.name] = prop.default
      }
    }
  }

  return result
}

export const getLibraryDependencies = app => {
  // TODO: Add app-specific libraries
  let localLibraries = getLocalLibraries()

  const libraries = app?.libraries || []

  let libs = libraries?.filter(lib => {
    return !localLibraries.includes(lib.name)
  })

  libs = libs.concat(
    localLibraries.map(lib => ({
      name: lib,
      version: 'dev',
    }))
  )

  return libs
}

export const getLibraryMenu = app => {
  let libraries = getLibraryDependencies(app)

  let options = []

  libraries.forEach(({ name, version }) => {
    let library = getLibrary(name, version)

    if (library) {
      options.push({
        label: library.config.displayName,
        library: library.config.name,
        children: getComponentOptions(library),
      })
    }
  })

  return options
}

const getComponentOptions = library => {
  return library.config.components.map(component => ({
    library: library.config.name,
    name: library.config.displayName,
    label: component.displayName,
    icon: 'component',
    value: {
      type: LIBRARY_COMPONENT,
      options: {
        ...getDefaultProps(component),
        libraryName: library.config.name,
        libraryVersion: library.config.version,
        componentName: component.name,
        width: component.defaultWidth,
        height: component.defaultHeight,
        snappingRules: component.snappingRules,
      },
    },
  }))
}

export const calculateSnappedPosition = (obj, parent) => {
  let snappingRules = obj.snappingRules || {}

  let newX = obj.x
  let newY = obj.y
  let newWidth = obj.width
  let newHeight = obj.height

  if (parent) {
    if (snappingRules.lock) {
      let { lock } = snappingRules

      if ('left' in lock) {
        newX = parent.x + lock.left
      }

      if ('right' in lock && 'left' in lock) {
        newWidth = parent.width - lock.left - lock.right
      } else if ('right' in lock) {
        newX = parent.x + parent.width - lock.right - obj.width
      }

      if ('top' in lock) {
        newY = parent.y + lock.top
      }

      if ('bottom' in lock && 'top' in lock) {
        newHeight = parent.height - lock.top - lock.bottom
      } else if ('bottom' in lock) {
        newY = parent.y + parent.height - lock.bottom - obj.height
      }
    }
  }

  return {
    ...obj,
    x: newX,
    y: newY,
    width: newWidth,
    height: newHeight,
  }
}

export const evaluateLibraryProps = (
  config,
  props = {},
  getLabel,
  { branding, libraryGlobals } = {}
) => {
  let result = {}
  let referenceMap = {}
  let childComponents = config?.childComponents || []

  for (let child of childComponents) {
    let value = evaluateLibraryProps(child, props[child.name], getLabel, {
      branding,
      libraryGlobals: libraryGlobals?.[child.name] || {},
    })

    if (child.role === 'listItem' && child.reference) {
      let ref = child.reference

      referenceMap[ref] = referenceMap[ref] || {}
      referenceMap[ref][child.name] = value

      if (value.styles) {
        result = deepSet(result, [child.name], value)
      }
    } else {
      result[child.name] = value
    }
  }

  let refProps = config?.props?.filter(p => p.role === 'listItem')
  let normalProps = config?.props?.filter(p => p.role !== 'listItem')

  if (refProps) {
    for (let prop of refProps) {
      let value = getPropValue(prop, props[prop.name], getLabel, {
        branding,
        props,
        libraryGlobals,
      })

      let ref = prop.reference

      referenceMap[ref] = referenceMap[ref] || {}
      referenceMap[ref][prop.name] = value
    }
  }

  if (normalProps) {
    for (let prop of normalProps) {
      let propValue = getPropValue(prop, props[prop.name], getLabel, {
        children: referenceMap[prop.name],
        branding,
        props,
        libraryGlobals,
      })

      if (prop.role === 'autosaveInput') {
        result[prop.name] = { value: propValue }
      } else {
        result[prop.name] = propValue
      }

      if (prop.styles) {
        const key = ['styles', prop.name]

        result = deepSet(result, key, getPropStyles(props, prop, { branding }))
      }
    }
  }

  return result
}

const getPropValue = (prop, value, getLabel, opts = {}) => {
  let { branding, children, props, libraryGlobals } = opts

  if (prop.global) {
    value = libraryGlobals[prop.name]
  }

  if (prop.type === dataTypes.LIST) {
    return [1, 2, 3].map(id => ({
      ...children,
      id,
    }))
  }

  if (value && Array.isArray(value) && prop.type === 'text') {
    return value
      .map(itm => getPropValue(prop, itm, getLabel, children, opts))
      .join('')
      .replace(/ +/g, ' ')
  }

  if (value && value.type === 'formula') {
    if (value.formula.length === 1 && typeof value.formula[0] === 'string') {
      return +value.formula[0]
    }

    return 0
  }

  if (value && value.type === 'binding') {
    if (prop.type === dataTypes.TEXT) return getLabel(value.source)

    if (prop.type === dataTypes.IMAGE) {
      if ('options' in value && typeof value.options !== 'undefined') {
        const { options } = value

        if (typeof options.placeholderImageEnabled === 'undefined') {
          return undefined
        }

        if (typeof options.placeholderImage === 'undefined') return undefined

        const { placeholderImage, placeholderImageEnabled } = options

        if (placeholderImageEnabled && placeholderImage) {
          return assetURL(placeholderImage)
        }
      }
    }

    return undefined
  }

  if (value && prop.type === 'action') {
    return noop
  }

  if (value && prop.type === dataTypes.COLOR) {
    return normalizeColor(value, branding, props)
  }

  return value
}

const getPropStyles = (prop, config, opts = {}) => {
  const { branding } = opts

  let propStyles = { ...config?.styles }
  const keys = Object.keys(propStyles)

  const target = prop?.styles?.[config.name] || prop

  for (const key of keys) {
    let style = target?.[key] || config?.styles?.[key]

    switch (key) {
      case 'fontFamily': {
        propStyles[key] = getFontFamily(style, branding)

        break
      }
      case 'fontSize': {
        if (typeof style === 'number') {
          style = `${style}px`
        } else if (typeof style === 'string') {
          if (!style.includes('px')) style = `${style}px`
        }

        propStyles[key] = style

        break
      }
      case 'color': {
        propStyles[key] = normalizeColor(style, branding, prop)

        break
      }
      case 'textAlignment': {
        propStyles.textAlign = style
        delete propStyles.textAlignment

        break
      }

      default: {
        propStyles[key] = style

        break
      }
    }
  }

  return propStyles
}

export const getComponentIcon = (library, version, component) => {
  if (version === 'dev') return null

  return `${defaultURL}/${library}/${version}/icons/${component}-${version}.png`
}

export const updateComponentBounds = (obj, libraryGlobals) => {
  let { libraryName, componentName } = obj
  let library = getAppLibrary(null, libraryName)
  let config = getAppComponent(null, libraryName, componentName) || {}
  let resizeY = !!config?.resizeY
  let resizeX = 'resizeX' in config ? config?.resizeX : true

  // Skip if vertically-resizable
  if (resizeY && resizeX) {
    return obj
  }

  let Component = library?.components?.[componentName]

  let props = evaluateLibraryProps(
    config,
    obj.attributes,
    () => 'Hello world',
    { libraryGlobals }
  )

  let el = document.createElement('div')

  if (resizeX) {
    el.style.width = `${obj.width}px`
  }

  el.style.position = 'fixed'
  el.style.top = '100%'
  el.style.top = 0
  el.style.zIndex = 100000
  el.style.opacity = 0
  el.style.pointerEvents = 'none'
  el.style.backgroundColor = 'red'

  document.body.appendChild(el)

  let rect

  try {
    ReactDOM.render(<Component editor {...props} />, el)
    rect = el.getBoundingClientRect()
  } catch (err) {
    console.log('Error in library component:', err)
  }

  document.body.removeChild(el)

  if (!rect || rect.height === 0 || rect.width === 0) {
    return obj
  }

  let { width, height } = rect
  let result = { ...obj }

  if (!resizeX) {
    result.width = width
  }

  if (!resizeY) {
    result.height = height
  }

  return result
}

export const resetLibraryBindingIds = obj => {
  if (obj.type !== LIBRARY_COMPONENT) {
    return obj
  }

  let idMap = {}

  let newObject = traverseMapObject(obj, obj => {
    if (obj.type === 'binding') {
      let newId = getId()
      idMap[obj.id] = newId

      return { ...obj, id: newId }
    }

    return obj
  })

  newObject = traverseMapObject(newObject, obj => {
    if (obj.listObjectId && idMap[obj.listObjectId]) {
      return { ...obj, listObjectId: idMap[obj.listObjectId] }
    }

    return obj
  })

  return newObject
}

export const getLibraryPropLabel = (app, object, path) => {
  let childComponentName
  let propName

  if (path.length === 2) {
    childComponentName = path[0]
    propName = path[1]
  } else {
    propName = path[0]
  }

  let { libraryName, componentName } = object
  let config = getAppComponent(app, libraryName, componentName)

  let childComponent =
    childComponentName &&
    config.childComponents.filter(c => c.name === childComponentName)[0]

  let propParent = childComponent || config
  let prop = propParent.props.filter(p => p.name === propName)[0]

  let label = [
    getObjectName(object),
    childComponent && childComponent.displayName,
    prop.displayName || capitalize(prop.name),
  ]

  return label.filter(itm => itm).join(' ')
}

export const evaluateEnabled = (enabled, values) => {
  values = values || {}

  if (!enabled) {
    return true
  }

  for (let key of Object.keys(enabled)) {
    let targetValues = enabled[key]

    if (!Array.isArray(targetValues)) {
      targetValues = [targetValues]
    }

    if (!targetValues.includes(values[key])) {
      return false
    }
  }

  return true
}
