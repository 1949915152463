import {match as stableMatch} from './stablematch';
import {sort} from './arrays';
import fastLevenshtein from 'fast-levenshtein';


// Implements stable marriage problem to fuzzy-match two lists of labels
// Preferences are symmetrical, and therefore matches will be optimal
// LISTA & LISTB are arrays of strings


export const matchLists = (listA, listB) => {
  var result = {};
  var len = Math.max(listA.length, listB.length);

  var getAPreferences = function getAPreferences(a) {
    a = clean(a);
    return sort(listB, function (b) {
      return fastLevenshtein.get(a, clean(b));
    });
  };

  var getBPreferences = function getBPreferences(b) {
    b = clean(b);
    return sort(listA, function (a) {
      return fastLevenshtein.get(a, clean(b));
    });
  };

  var matches = stableMatch(listA, listB, getAPreferences, getBPreferences);

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = matches[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var match = _step.value;
      result[match[0]] = match[1];
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return result;
};

var clean = function clean(str) {
  return str.toLowerCase();
};
