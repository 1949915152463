// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-preview-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editor-preview-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 8px;
  color: #333 !important;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border: none !important;
}

.editor-preview-button:hover, .editor-preview-button:focus {
  background-color: #f5f5f5;
}

.editor-preview-button:active {
  background-color: #ddd;
}

.editor-preview-button .icon {
  background: #333c53;
  margin-right: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/PreviewButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;EACtB,8CAA8C;EAC9C,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B","sourcesContent":[".editor-preview-button-wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.editor-preview-button {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-top: 0;\n  padding-bottom: 0;\n  margin-right: 8px;\n  color: #333 !important;\n  background-color: #fff;\n  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);\n  border: none !important;\n}\n\n.editor-preview-button:hover, .editor-preview-button:focus {\n  background-color: #f5f5f5;\n}\n\n.editor-preview-button:active {\n  background-color: #ddd;\n}\n\n.editor-preview-button .icon {\n  background: #333c53;\n  margin-right: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
