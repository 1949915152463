// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-upload-image {
  pointer-events: none;
  opacity: 0.5;
}

.image-upload-backdrop {
  pointer-events: all;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CreateImage.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".image-upload-image {\n  pointer-events: none;\n  opacity: 0.5;\n}\n\n.image-upload-backdrop {\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
