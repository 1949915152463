// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-selection-box {
  fill: none;
  stroke: #ffc100;
  stroke-width: 1px;
  pointer-events: none;
}

.hover-selection-box.thicc {
  stroke-width: 2px;
  fill: rgba(255, 255, 255, 0.1);
}

.data-bindings-hover .hover-selection-box {
  stroke: #ffc100;
}

.hover-selection-name {
  fill: #ffc100;
  font-size: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/HoverSelection.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".hover-selection-box {\n  fill: none;\n  stroke: #ffc100;\n  stroke-width: 1px;\n  pointer-events: none;\n}\n\n.hover-selection-box.thicc {\n  stroke-width: 2px;\n  fill: rgba(255, 255, 255, 0.1);\n}\n\n.data-bindings-hover .hover-selection-box {\n  stroke: #ffc100;\n}\n\n.hover-selection-name {\n  fill: #ffc100;\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
