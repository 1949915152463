// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  min-width: 300px;
}

@media (min-width: 764px) {
  .new-modal {
    width: 600px;
  }
}

.new-modal-footer {
  border-top: solid 1px #eee;
  padding: 15px 40px;
  display: flex;
}

.new-modal-footer > * {
  margin-left: 10px;
}

.new-modal-footer .btn.modal-footer-secondary {
  background-color: transparent;
  color: #9e9e9e;
}

.new-modal-footer .btn.modal-footer-secondary:hover,
.new-modal-footer .btn.modal-footer-secondary:focus,
.new-modal-footer .btn.modal-footer-secondary:active {
  background-color: rgb(238, 238, 238);
}

.new-modal-footer .btn.modal-footer-align-right {
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/styles/NewModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;;;EAGE,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".new-modal {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  padding: 0;\n  min-width: 300px;\n}\n\n@media (min-width: 764px) {\n  .new-modal {\n    width: 600px;\n  }\n}\n\n.new-modal-footer {\n  border-top: solid 1px #eee;\n  padding: 15px 40px;\n  display: flex;\n}\n\n.new-modal-footer > * {\n  margin-left: 10px;\n}\n\n.new-modal-footer .btn.modal-footer-secondary {\n  background-color: transparent;\n  color: #9e9e9e;\n}\n\n.new-modal-footer .btn.modal-footer-secondary:hover,\n.new-modal-footer .btn.modal-footer-secondary:focus,\n.new-modal-footer .btn.modal-footer-secondary:active {\n  background-color: rgb(238, 238, 238);\n}\n\n.new-modal-footer .btn.modal-footer-align-right {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
