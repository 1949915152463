// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-inspect-settings-panel {
  margin-bottom: 15px;
}

.library-inspect-settings-panel-inner {
  margin: 4px -16px 0;
  border-radius: 4px;
  overflow: hidden;
}

.library-inspect-settings-panel-frame {
  border: none;
  width: 100%;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/styles/SettingsPanel.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,cAAc;AAChB","sourcesContent":[".library-inspect-settings-panel {\n  margin-bottom: 15px;\n}\n\n.library-inspect-settings-panel-inner {\n  margin: 4px -16px 0;\n  border-radius: 4px;\n  overflow: hidden;\n}\n\n.library-inspect-settings-panel-frame {\n  border: none;\n  width: 100%;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
