import {Tree, TreeNode} from './components';
import SampleTree from './sample/SimpleTree';
import './Tree.scss';

export { Tree, TreeNode, SampleTree };


export { defaultTheme, minimalTheme } from './theme';

export default Tree;
