// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bounding-box {
  pointer-events: none;
}

.resize-click-target {
  fill: none;
  pointer-events: all;
}

.resize-handle {
  fill: #fff;
  stroke: #3e87f8;
  stroke-width: 1.5px;
}

.selection-box {
  fill: none;
  stroke: #3e87f8;
  stroke-width: 1px;
}

.rule-sizes {
  fill: #8a8a8a;
  font-size: 10px;
  letter-spacing: .5px;

}
.rule-sizes line{
  stroke: #8a8a8a;
  stroke-width: 1px;
}

.depth-line{
  marker-end: url("#depth-arrow");
  stroke: #8a8a8a;
}
`, "",{"version":3,"sources":["webpack://./src/styles/BoundingBoxes.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,oBAAoB;;AAEtB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;AACjB","sourcesContent":[".bounding-box {\n  pointer-events: none;\n}\n\n.resize-click-target {\n  fill: none;\n  pointer-events: all;\n}\n\n.resize-handle {\n  fill: #fff;\n  stroke: #3e87f8;\n  stroke-width: 1.5px;\n}\n\n.selection-box {\n  fill: none;\n  stroke: #3e87f8;\n  stroke-width: 1px;\n}\n\n.rule-sizes {\n  fill: #8a8a8a;\n  font-size: 10px;\n  letter-spacing: .5px;\n\n}\n.rule-sizes line{\n  stroke: #8a8a8a;\n  stroke-width: 1px;\n}\n\n.depth-line{\n  marker-end: url(\"#depth-arrow\");\n  stroke: #8a8a8a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
