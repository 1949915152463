import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getId, getObject, sort} from 'common/utils'

import {getBaseZoom, getTransform} from 'utils/zoom'
import {normalizeColor} from 'utils/colors'

import {setCanvasHover, setSelection} from '../../../ducks/editor/selection'
import {beginDrag} from '../../../ducks/editor/positioning'

import {getCurrentAppId, getPath, getPlatform, getSelectedParent, getYOffset,} from '../../../ducks/editor/objects'

import CanvasObject from './CanvasObject'
import {PathItem, Point as PathPoint, Rectangle as PathRectangle, Segment, Size} from "../../../utils/vector";
import PageShadow from "./PageShadow";
import {getActiveStack} from "../../../ducks/editor/stack";
import CanvasRuler from "./CanvasRuler";
import { getApp } from "ducks/apps/selectors";
import { SHAPE, GROUP } from "../../../common/constants";

class Screen extends Component {
    
    static contextTypes = {
        editable: PropTypes.bool,
    }

    constructor(props) {
        super(props);
    }

    handleMouseLeave = e => {
        let {editable} = this.context
        let {setCanvasHover} = this.props

        if (!editable) {
            return
        }

        setCanvasHover(null)
    }

    handleEnterTitle = () => {
        let {editable} = this.context

        if (!editable) {
            return
        }

        let {component, setCanvasHover} = this.props
        setCanvasHover(component.id)
    }

    handleClickTitle = e => {
        let {component, setSelection, beginDrag} = this.props
        let {editable} = this.context

        if (!editable) {
            return
        }

        setSelection(component.id, e.shiftKey)
        beginDrag([e.clientX, e.clientY])
    }

    getMask = () => {

        const { component, app } = this.props

        let baseZoom = getBaseZoom()

        if (app && app.outer_rule && Array.isArray(app.outer_rule)) {

            return app.outer_rule.map((obj, index) => {

                const path = PathItem.create(obj.points)

                path.setClosed(true)

                path.scale(96 * baseZoom.scale)

                path.adjust(0, 0)

                if (path.segments.length === 0) return {
                    mask: null,
                    offset: null
                }


                const cloned = path.clone()

                let offset = path.clone()

                let unit = window.devicePixelRatio === 2 ? 1 : 2

                offset = offset.offset(-20 * unit, {join: 'bevel'})

                const p = cloned.subtract(offset)

                p.setClosed(true)

                return {

                    mask: <path
                        key={`mask-${index}`}
                        fill="#fff"
                        strokeWidth={2}
                        stroke={'#898989'}
                        d={path.getPathData()}
                    />,

                    offset: <g>

                        <path

                            strokeWidth={2}
                            stroke={'#ffb1b1'}
                            fill={'#ffdbdb'}
                            d={path.getPathData()}
                        />

                        <path
                            stroke={'#ffb1b1'}
                            fill={'#fff'}
                            strokeWidth={2}
                            d={p.getPathData()}
                        />

                    </g>

                }

            })


        } else {


            const kappa = 4 * (Math.sqrt(2) - 1) / 3
            const {component: {width, height, x, y, borderRadius}} = this.props
            if (width === 0 || height === 0) return
            const rect = new PathRectangle(x, y, width, height);
            let r = new PathPoint(borderRadius ? [borderRadius, borderRadius] : [0, 0])

            const bl = rect.getBottomLeft(true),
                tl = rect.getTopLeft(true),
                tr = rect.getTopRight(true),
                br = rect.getBottomRight(true)
            ;

            let segments = [];
            if (!r || r.isZero()) {
                segments = [
                    new Segment(bl),
                    new Segment(tl),
                    new Segment(tr),
                    new Segment(br)
                ];
            } else {

                r = Size.min(new Size(borderRadius, borderRadius), rect.getSize(true).divide(2));
                const rx = r.width,
                    ry = r.height,
                    hx = rx * kappa,
                    hy = ry * kappa;
                segments = [
                    new Segment(bl.add(rx, 0), null, [-hx, 0]),
                    new Segment(bl.subtract(0, ry), [0, hy]),
                    new Segment(tl.add(0, ry), null, [0, -hy]),
                    new Segment(tl.add(rx, 0), [-hx, 0], null),
                    new Segment(tr.subtract(rx, 0), null, [hx, 0]),
                    new Segment(tr.add(0, ry), [0, -hy], null),
                    new Segment(br.subtract(0, ry), null, [0, hy]),
                    new Segment(br.subtract(rx, 0), [hx, 0])
                ];
            }

            const path = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
                return new Segment(point, handleIn, handleOut)
            }))

            path.setClosed(true)
            path.scale(baseZoom.scale)
            let unit = window.devicePixelRatio === 2 ? 1 : 2
            path.adjust(0, 0)


            const cloned = path.clone()
            let offset = path.clone()
            offset = offset.offset(-20 * unit, {join: 'bevel'})


            const p = cloned.exclude(offset)
            p.setClosed(true)

            return [{
                mask: <path
                    fill="#fff"
                    d={path.getPathData()}
                />,
                offset: <g>
                    <path

                        strokeWidth={2}
                        stroke={'#ffb1b1'}
                        fill={'#ffdbdb'}
                        d={path.getPathData()}
                    />
                    <path
                        stroke={'#ffb1b1'}
                        fill={'#fff'}
                        strokeWidth={2}
                        d={p.getPathData()}
                    />
                </g>
            }]
        }

    }

    getExtrudeBoxes = () => {
        
        const {component, app} = this.props

        let baseZoom = getBaseZoom()

        const depths = {}
        let fromBottom = app.depth

        const objects = Object.values(app.components) || []
        const sorted = sort(objects, obj => obj.order)

        sorted.forEach((comp) => {
            /* if (comp.id !== component.id) {
                 fromBottom += comp.depth
             }*/
            fromBottom -= comp.depth
            depths[comp.id] = fromBottom
        })

        const drawRect = (width, height, length, x, y, borderRadius, rounded, index) => {

            const kappa = 4 * (Math.sqrt(2) - 1) / 3

            const rect = new PathRectangle(x, y, width, length);
            let r = new PathPoint(borderRadius ? [borderRadius, borderRadius] : [0, 0])

            const bl = rect.getBottomLeft(true),
                tl = rect.getTopLeft(true),
                tr = rect.getTopRight(true),
                br = rect.getBottomRight(true)
            ;

            let segments = [];
            if (!r || r.isZero()) {
                segments = [
                    new Segment(bl),
                    new Segment(tl),
                    new Segment(tr),
                    new Segment(br)
                ];
            } else {

                r = Size.min(new Size(borderRadius, borderRadius), rect.getSize(true).divide(2));
                const rx = r.width,
                    ry = r.height,
                    hx = rx * kappa,
                    hy = ry * kappa;
                segments = [
                    new Segment(bl.add(rx, 0), null, [-hx, 0]),
                    new Segment(bl.subtract(0, ry), [0, hy]),
                    new Segment(tl.add(0, ry), null, [0, -hy]),
                    new Segment(tl.add(rx, 0), [-hx, 0], null),
                    new Segment(tr.subtract(rx, 0), null, [hx, 0]),
                    new Segment(tr.add(0, ry), [0, -hy], null),
                    new Segment(br.subtract(0, ry), null, [0, hy]),
                    new Segment(br.subtract(rx, 0), [hx, 0])
                ];
            }

            const path = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
                return new Segment(point, handleIn, handleOut)
            }))

            path.setClosed(true)
            path.scale(96 * baseZoom.scale)
            path.adjust(x * 96, y * 96)

            return (
                <path
                    key={`rect-path-${index}`}
                    fill="rgb(255, 0, 0, 0.5)"
                    d={ path.getPathData() }
                />
            )
        }

        const drawPolygon = (width, height, x, y, points, index) => {

            const path = PathItem.create(points)
            path.setClosed(true)
            path.scale(96 * baseZoom.scale)
            path.adjust(x * 96, y * 96)

            return (
                <path
                    key={`polygon-path-${index}`}
                    fill="rgb(255, 0, 0, 0.5)"
                    d={path.getPathData()}
                />
            )
        }

        if (app && app.in_case_shapes) {
            return app.in_case_shapes.map((obj, index) => {

                if (obj.data) {
                    return obj.data.map((o) => {
                        const {width, height, length, x, y, borderRadius, rounded, shape, points, depth} = o
                        if ((depths[component.id]) < ((obj.fromBottom) * 96)) {
                            if (shape === 'RECT') {

                                return drawRect(width, height, height, x, y, borderRadius, rounded, index)
                            }
                            if (shape === 'POLYGON') {

                                return drawPolygon(width, height, x, y, points, index)
                            }
                        }


                        return null
                    })
                } else {

                    const {width, height, length, xx, yy, borderRadius, rounded} = obj
                    return drawRect(width, height, length, xx, yy, borderRadius, rounded, index)

                }


            })


        }

        return null
    }

    getOverflows = () => {

        const {component, app} = this.props


        const arr = Object.values(app.components).sort(function (a, b) {
            return a.order - b.order;
        })

        function flattenLayer(objects, flatten) {

            flatten = flatten || {}

            if (!Array.isArray(objects)) {
                objects = [objects]
            }

            objects.forEach((item) => {

                if (item.type === GROUP) {
                    item.children?.forEach((obj) => {
                        obj = Object.assign({},  obj)
                        flattenLayer(obj, flatten)
                    })
                    return
                }

                if (item.compound && item.compound.length > 0) {
                    item.compound?.forEach((obj) => {
                        flattenLayer(obj, flatten)
                    })
                    return
                }

                if (item.points && item.points.length > 0) {
                    let p = PathItem.create(item.points)
                    const bounds = p.bounds

                    item = Object.assign({}, {
                        id: getId(),
                        isClosed: true,
                        //children: curr.children,
                        points: item.points,
                        x: bounds.x,
                        y: bounds.y,
                        width: bounds.width,
                        height: bounds.height,
                        depth: item.depth,
                        type: SHAPE
                    })
                }


                flatten[item.id] = item
            })


            return flatten
        }

        let overflow = {}
        let elements = []


        arr.forEach((component) => {

            const flatten = flattenLayer(component.objects)

            overflow[component.id] = elements
            elements = []

            overflow[component.id].forEach((obj) => {
                obj = Object.assign({},  obj)
                if (obj.depth - component.depth > 0) {
                    obj.depth = obj.depth - component.depth
                    elements.push(obj)
                }
            })

            Object.values(flatten).forEach((obj) => {
                obj = Object.assign({},  obj)

                if ( obj.depth - component.depth > 0) {
                    obj.depth = obj.depth - component.depth
                    elements.push(obj)
                }
            })


        })

        return overflow[component.id] || []
    }

    render() {

        let {
            app,
            component,
            zoom,
            zoomActive,
            branding,
            yOffset,
            platform,
            selectedParent,
            selectedParentScreenId,
            activeStack,
            depthOffset,
        } = this.props

        let objects = component.children || []

        let hideShadows = zoom.scale > 8

        let transform           = getTransform(zoom, true, component.x, component.y)
        let baseZoom            = getBaseZoom()
        let maskId              = `screen-mask-${component.id}`
        let gridId              = `screen-grid-${component.id}`
        let hightlightMaskId    = `screen-hightlight-mask-${component.id}`
        let styles              = {transform}

        const hightlightBleedSize = 6

        const backgroundColor = normalizeColor(
            component.background_color || '@background',
            branding
        )

        const mask          = this.getMask()
        const extrudeBoxes  = this.getExtrudeBoxes()
        const overflows     = this.getOverflows()



        //1452,1014

        return (

            <div>

                <PageShadow
                    zoom={zoom}
                    zoomActive={zoomActive}
                    component={component}
                    onEnterTitle={this.handleEnterTitle}
                    onLeaveTitle={this.handleMouseLeave}
                    onClickTitle={this.handleClickTitle}

                    yOffset={yOffset}
                    transparent={!!selectedParent}
                />
                {/*<CanvasRuler isVertical={true} width={24} value={Math.ceil(component.height )} height={window.innerHeight} zoom={zoom} />*/}
                <CanvasRuler x={component.x} y={component.y} width={component.width} value={Math.ceil(component.width)}
                             height={24} zoom={zoom}/>
                
                <svg className="canvas-objects" width={Math.ceil(component.width * baseZoom.scale)} height={Math.ceil(component.height * baseZoom.scale)} style={styles}>
                    
                    <defs>
                        <marker id="depth-arrow" markerWidth="12" markerHeight="12" orient="auto" refY="6"
                                fill="transparent"
                                refX="12">
                            <path fill="transparent" stroke="#8a8a8a" d="M6,0 L12,6 6,12" style={{strokeWidth: 0.5}}/>
                        </marker>
                    </defs>

                    <mask id={maskId}>
                        {mask && mask.map(({mask, offset}) => (
                            mask
                        ))}
                    </mask>

                    <use href={`#${maskId}`} overflow="visible" stroke="#000" strokeWidth="10"/>
                    

                    {/* ??? */}
                    {selectedParent && (
                        <mask id={hightlightMaskId}>
                            <rect
                                x={0}
                                y={0}
                                width={component.width * baseZoom.scale}
                                height={component.height * baseZoom.scale}
                                fill="#fff"
                            />
                            {selectedParentScreenId === component.id && (
                                <rect
                                    x={selectedParent.x - hightlightBleedSize}
                                    y={selectedParent.y - hightlightBleedSize}
                                    width={selectedParent.width + hightlightBleedSize * 2}
                                    height={selectedParent.height + hightlightBleedSize * 2}
                                    fill="#000"
                                />
                            )}
                        </mask>
                    )}



                    <g onMouseLeave={this.handleMouseLeave} mask={`url(#${maskId})`}>
                    
                        <rect
                            x={0}
                            y={0}
                            fill={backgroundColor}
                            width={component.width * baseZoom.scale}
                            height={component.height * baseZoom.scale}
                            pointerEvents="none"
                        />


                        <g id={'offset'}>
                            {mask && mask.map(({mask, offset}) => (
                                offset
                            ))}
                        </g>


                        {extrudeBoxes}

                        {/*{<Grid
                            key={gridId}
                            zoom={zoom}
                            zoomActive={zoomActive}
                            component={component}
                            baseZoom={baseZoom}
                        />}*/}

                        {objects.map(object => (
                            <CanvasObject
                                key={object.id}
                                object={object}
                                zoom={baseZoom}
                                transformZoom={zoom}
                                hideShadows={hideShadows}
                                branding={branding}
                            />
                        ))}

                        {overflows.map(object => (
                            <CanvasObject
                                key={object.id}
                                object={object}
                                zoom={baseZoom}
                                fake={true}
                                transformZoom={zoom}
                                hideShadows={hideShadows}
                                branding={branding}
                            />
                        ))}

                        {selectedParent && (
                            <rect
                                mask={`url(#${hightlightMaskId})`}
                                pointerEvents="none"
                                x={0}
                                y={0}
                                width={component.width * baseZoom.scale}
                                height={component.height * baseZoom.scale}
                                fill="#fff"
                                fillOpacity={0.9}
                            />
                        )}

                    </g>

                </svg>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const selectedParent = getSelectedParent(state)
    let selectedParentScreenId

    if (selectedParent) {

        const path = getPath(state, selectedParent.id)
        const {list} = state.editor.objects.present
        const selectedParentScreen = getObject(list, path.split('.')[0])
        selectedParentScreenId = selectedParentScreen && selectedParentScreen.id
    }

    const app = getApp(state, getCurrentAppId(state))

    return {
        app,
        selectedParentScreenId,
        selectedParent,
        yOffset: getYOffset(state),
        activeStack: getActiveStack(state),
        platform: getPlatform(state),
    }
}

export default connect(mapStateToProps, {
    setCanvasHover,
    setSelection,
    beginDrag,

})(Screen)
