import React, {Component} from 'react'
import {connect} from 'react-redux'


import 'styles/Auth.css'
import WizardForm from '../NewApp/WizardForm'
import Page from '../Shared/Page'
import BodyClass from '../Shared/BodyClass'

const templates = [
  {
    id: 'blank',
    name: 'Blank',
  },
]

class InitialApp extends Component {
  render() {

    let {  history } = this.props

    return (
      <Page
        modalProps={{ className: 'new-app-modal', grayOut: false }}
        modalContent={

          <WizardForm
            history = {history}
            onSubmit={this.handleSubmit}
            templates={templates}
          />
          
        }
      >
        <BodyClass className="auth-screen" />
      </Page>
    )
  }
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {

})(InitialApp)
