import React, {Component} from 'react'

import {Field, reduxForm} from 'redux-form'

import Modal from 'components/Shared/Modal'
import WrappedInput from 'components/Shared/Forms/WrappedInput'


import 'styles/CopyApp.css'


class InviteDraftForm extends Component {
    render() {
        let { onCancel, handleSubmit } = this.props

        return (
            <form onSubmit={handleSubmit}>
                <Modal.Header title="Поделиться" />
                <Modal.Content>
                    <Field
                        inline
                        small
                        autoComplete="off"
                        component={WrappedInput}
                        name="email"
                        label="Эл. адрес"
                        placeholder=""
                    />

                </Modal.Content>
                <Modal.Actions>
                    <Modal.Button type="button" text onClick={onCancel}>
                        Отмена
                    </Modal.Button>
                    <Modal.Button type="submit">Поделиться</Modal.Button>
                </Modal.Actions>
            </form>
        )
    }
}

export default reduxForm({ form: 'inviteDraft' })(InviteDraftForm)
