// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-placeholder-object rect, .image-placeholder-object line {
  stroke: #ccc;  
  stroke-width: 1px;
}

.image-placeholder-object rect {
  fill: #eee;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Image.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".image-placeholder-object rect, .image-placeholder-object line {\n  stroke: #ccc;  \n  stroke-width: 1px;\n}\n\n.image-placeholder-object rect {\n  fill: #eee;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
