// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inspect-generic-row {
  margin-top: 15px;
}

.inspect-generic-row-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.border-control-split > .inspect-generic-row > .inspect-generic-row-title {
  margin-left: 16px;
}

.border-control-split
  > .inspect-generic-row:last-child
  > .inspect-generic-row-title {
  margin-right: 16px;
}

.inspect-generic-row-body {
  margin-top: 6px;
}

.inspect-generic-row-body.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inspect-generic-row-title .toggle-button {
  margin: -4px 0;
}

/* Inputs, etc. */
.inspect-generic-row .text-control {
  height: 44px;
}

.inspect-generic-row .text-control input {
  height: 44px;
  font-size: 14px;
  padding-left: 16px;
  border-radius: 6px;
}

.inspect-generic-row .text-control .incrementer {
  width: 18px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/GenericRow.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;;EAGE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB;;AAEA,iBAAiB;AACjB;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,4BAA4B;EAC5B,+BAA+B;AACjC","sourcesContent":[".inspect-generic-row {\n  margin-top: 15px;\n}\n\n.inspect-generic-row-title {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.border-control-split > .inspect-generic-row > .inspect-generic-row-title {\n  margin-left: 16px;\n}\n\n.border-control-split\n  > .inspect-generic-row:last-child\n  > .inspect-generic-row-title {\n  margin-right: 16px;\n}\n\n.inspect-generic-row-body {\n  margin-top: 6px;\n}\n\n.inspect-generic-row-body.disabled {\n  opacity: 0.3;\n  pointer-events: none;\n}\n\n.inspect-generic-row-title .toggle-button {\n  margin: -4px 0;\n}\n\n/* Inputs, etc. */\n.inspect-generic-row .text-control {\n  height: 44px;\n}\n\n.inspect-generic-row .text-control input {\n  height: 44px;\n  font-size: 14px;\n  padding-left: 16px;\n  border-radius: 6px;\n}\n\n.inspect-generic-row .text-control .incrementer {\n  width: 18px;\n  border-top-right-radius: 6px;\n  border-bottom-right-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
