import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {getBoundingBox} from 'common/utils'
import {COMPONENT} from 'common/constants'

import {roundObject} from '../../../../utils/snapping'

import {
    getObjectPosition,
    getSelectedObjects,
    getYOffset,
    getZoom,
    resizeObject,
    selectObjects,
} from '../../../../ducks/editor/objects'

import {getXGrid, getYGrid, resetSnaps, setXSnap, setYSnap,} from '../../../../ducks/editor/snapping'

import {recalculateSnapGrid} from '../../../../ducks/editor/selection'

import {getEditingText} from '../../../../ducks/editor/textEditing'
import {getEditingShape} from '../../../../ducks/editor/shapeEditing'
import BoundingBox from './BoundingBox'
import 'styles/BoundingBoxes.css'

class BoundingBoxes extends Component {

  handleResizeEnd = () => {
    let { objects, resizeObject, recalculateSnapGrid } = this.props

    if (objects.length !== 1) {
      return
    }

    let object = roundObject(objects[0])

    recalculateSnapGrid()
    resizeObject(object.id, object)
  }

  handleChange = changes => {

    let { objects, resizeObject } = this.props

    if (objects.length !== 1) {
      return
    }
    
    /*    if (objects[0].types)
    this.path.setSegments(object.points.map(({point, handleIn, handleOut}) => {
      return new Segment(point, handleIn, handleOut)
    }))
    this.path.setClosed(object.isClosed)*/

    // console.log(changes)

    resizeObject(objects[0].id, changes, true)
  }

  render() {
    let {
      editing,
      objects,
      zoom,
      links,
      linkTargets,
      xGrid,
      yGrid,
      setXSnap,
      setYSnap,
      resetSnaps,
      yOffset,
    } = this.props

    let boundingBox = getBoundingBox(
      objects.map(o => ({
        ...o,
        ...o.absolutePosition,
      }))
    )

    if (boundingBox) {
      boundingBox.absolutePosition = boundingBox
    }

    if (editing || !boundingBox) {
      return null
    }

    objects = objects.map(obj => {
      if (obj.type === COMPONENT && yOffset !== 0) {
        return {
          ...obj,
          height: obj.height - yOffset,
          absolutePosition: {
            ...obj.absolutePosition,
            y: obj.absolutePosition.y + yOffset,
          },
        }
      }

      return obj
    })

    return (
      <g className="bounding-boxes">

        {objects.map(obj => (
          <BoundingBox
            resize={false}
            key={obj.id}
            object={obj}
            zoom={zoom}
            link={links[obj.id]}
            linkTargets={linkTargets}
          />
        ))}

        <BoundingBox
          link={objects.length === 1 ? links[objects[0].id] : null}
          resize={objects.length === 1}
          onChange={this.handleChange}
          onResizeEnd={this.handleResizeEnd}
          object={objects.length === 1 ? objects[0] : boundingBox}
          xGrid={xGrid}
          yGrid={yGrid}
          setXSnap={setXSnap}
          setYSnap={setYSnap}
          resetSnaps={resetSnaps}
          zoom={zoom}
        />

      </g>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  // Uncomment to enable arrows:
  // let selection = getSelection(state)
  // let links = getLinks(state, match.params.appId, selection)
  let links = {}

  let linkTargets = selectObjects(
    state,
    Object.keys(links).map(id => links[id].target)
  )

  let selectedObjects = getSelectedObjects(state)

  return {
    links,
    linkTargets,
    originalObjects: selectedObjects,
    objects: selectedObjects.map(obj => ({
      ...obj,
      absolutePosition: getObjectPosition(state, obj.id),
    })),
    editing: !!(getEditingText(state) || getEditingShape(state)),
    zoom: getZoom(state),
    xGrid: getXGrid(state),
    yGrid: getYGrid(state),
    yOffset: getYOffset(state),
  }
}

export default withRouter(
  connect(mapStateToProps, {
    resizeObject,
    recalculateSnapGrid,
    setXSnap,
    setYSnap,
    resetSnaps,
  })(BoundingBoxes)
)
