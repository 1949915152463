import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import classNames from 'classnames'
import {getActiveTab, setActiveTab} from '../../../ducks/editor/tabs'
import {getSelection} from '../../../ducks/editor/selection'
import Icon from '../../Shared/Icon'
import {ADD, BOXES, DATA, SCREENS} from '../../../utils/tabs'
import {setTool} from "../../../ducks/editor/tools";
import {ELLIPSE, RECTANGLE, SHAPE} from "../../../common/constants";
import 'styles/LeftNav.scss'
import {defaults} from "../../../utils/objects";
import { getApp } from "ducks/apps/selectors";
import {getCurrentAppId} from "../../../ducks/editor/objects";
import Scrollbar from "../../Shared/Scrollbar";
import {getCurrentUser, isAdmin, logout} from "../../../ducks/users";

const items = [

  /*  { type: BRANDING, icon: 'branding' },*/
  {type: ELLIPSE, icon: 'ellipse', name: 'Круг'},
  {type: RECTANGLE, icon: 'rect', name: 'Квадрат'},
  {type: SHAPE, icon: 'vector', name: 'Линия'},
  /*  { type: DATA, icon: 'data' },
    { type: SETTINGS, icon: 'settings' },
    { type: PUBLISH, icon: 'done-all' },
    { type: ANALYTICS, icon: 'analytics' },*/
]

const LeftNav = props => {

  const { history, match, selection } = props

  const getActiveTab = () => {
    let {activeTab} = props

    if (selection.length > 0) activeTab = null

    return activeTab
  }

  const handleLogout = () => {
    const {logout} = props
   logout()
    //window.location = '/'
  }

  const handleClick = tabName => {
    const {setActiveTab} = props
    let {appId} = match.params
    let activeTab = getActiveTab()

    if (!tabName) return null

    if (tabName !== DATA) history.replace(`/apps/${appId}/screens`)

    if (tabName === activeTab && selection.length === 0) {
      return setActiveTab(null)
    }

    return setActiveTab(tabName)
  }

  const handleAddObjectClick = (tabName, e) => {
    const {setTool} = props
    setTool(tabName, defaults[tabName], [e.clientX, e.clientY])
  }

  const handleOpenTraceClick = tabName => {
    const {app} = props

    let traceRoute = `/apps/${app.id}/trace`

    history.push(traceRoute)


  }

  const activeTab = getActiveTab()

  return (
    <div className="editor-left-nav">
      <div className="editor-left-nav__group editor-left-nav__shrink ">


          <Scrollbar>

          <LeftNavItem 
            onClick={handleClick} 
            type={BOXES} 
            icon={'boxes'} 
            key={BOXES} 
            active={activeTab === BOXES}
            name={'Кейсы'}
          />

          <AddButton 
            onClick={handleClick} 
            active={activeTab === ADD}
            name={'База оборудования'}
          />

          <LeftNavItem 
            onClick={handleClick} 
            type={SCREENS} 
            icon={'screens'} 
            key={SCREENS} 
            active={activeTab === SCREENS} 
            name={'Слои ложимента'}
          />

          {items.map(itm => (
            <LeftNavItem
              {...itm}
              key={itm.type}
              active={activeTab === itm.type}
              onClick={handleAddObjectClick}
            />
          ))}


          <LeftNavItem onClick={handleOpenTraceClick} type={"TRACE"} icon={'trace'} key={"TRACE"}/>

          </Scrollbar>

      </div>

      {/* <div className="editor-left-nav__group editor-left-nav__avatar">
        <div
            className={classNames('editor-left-nav-item')}

        >
          <span onClick={handleLogout}>
            <div className="left-nav-avatar">
              <div className="left-nav-avatar__placeholder">Вых</div>
            </div>
          </span>

        </div>
      </div>
       */}
      {/*<LeftNavItem onClick={handleClick} type={SETTINGS} icon={'settings'} key={SETTINGS} active={activeTab === SETTINGS}/>*/}
    </div>
  )
}

class LeftNavItem extends Component {

  handleClick = (e) => {

    let {type, onClick} = this.props

    onClick(type, e)
  }

  render() {

    let { icon, type, active, name } = this.props

    let tooltip = type
    if (type === 'data') tooltip = 'Database'
    if (icon === 'layers') icon = 'screens'

    return (
      <div
        className={classNames(
          'editor-left-nav-item',
          `editor-left-nav-item-${type}`,
          {active}
        )}
        onClick={this.handleClick}
      >
        <div className="editor-left-nav-item-sub">
          <Icon type={icon}/>
          <div className='editor-left-nav-item-sub-name'>{ name }</div>
        </div>
       {/* <div className="editor-left-nav-tooltip">{capitalize(tooltip)}</div>*/}
      </div>
    )
  }
}

class AddButton extends Component {
  handleClick = () => {
    let {onClick} = this.props

    onClick(ADD)
  }

  render() {

    let {active, name} = this.props

    return (
      <div
        className={classNames('editor-left-nav-add-button', {active})}
        onClick={this.handleClick}
      >
        <div className="editor-left-nav-add-button-sub">
          <span className={'icon'}></span>
          <div className='editor-left-nav-add-button-sub-name'>{ name }</div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, {appId}) => {

  const app = getApp(state, getCurrentAppId(state))

  return {
    app: app,

    activeTab: getActiveTab(state),
    selection: getSelection(state),
  }
}


export default withRouter(connect(mapStateToProps, {setActiveTab, setTool, logout})(LeftNav))
