import {combineReducers} from 'redux'
import undoable from 'redux-undo'

import objectsReducer, {
    ALIGN_OBJECTS,
    CHANGE_OBJECT_TYPE,
    CREATE_OBJECT,
    DELETE_OBJECT,
    GROUP_OBJECTS,
    POSITION_OBJECTS,
    REORDER_OBJECTS,
    RESIZE_OBJECT,
    SET_DATA,
    SET_PAGE_SIZE,
    UNGROUP_OBJECTS,
    UPDATE_OBJECT,
    UPDATE_OBJECTS,
} from './objects'

import {PASTE} from './clipboard'
import {BEGIN_DRAG, DRAG, END_DRAG} from './positioning'
import menus from './menus'
import layers from './layers'
import marquee from './marquee'

import tools from './tools'
import launchComponent from './launchComponent'
import tables from './tables'
import libraries from './libraries'
import modals from './modals'
import screenTemplates from './screenTemplates'
import stack from './stack'


const FILTER_TYPES = [
  SET_DATA,
  DRAG,
  BEGIN_DRAG,
  END_DRAG,
  POSITION_OBJECTS,
  ALIGN_OBJECTS,
  RESIZE_OBJECT,
  UPDATE_OBJECTS,
  UPDATE_OBJECT,
  CREATE_OBJECT,
  DELETE_OBJECT,
  REORDER_OBJECTS,
  GROUP_OBJECTS,
  UNGROUP_OBJECTS,
  SET_PAGE_SIZE,
  CHANGE_OBJECT_TYPE,
  PASTE,
]

let currentDragId = 0

const objects = undoable(objectsReducer, {

  filter: action => {
    if (action.type === RESIZE_OBJECT && action.skipSave) {
      return false
    }

    let result = FILTER_TYPES.indexOf(action.type) !== -1

    return result
  },

  groupBy: (action, state) => {
    if (action.type === POSITION_OBJECTS) {
      return `position-${action.ids.join('-')}`
    }

    if (action.type === BEGIN_DRAG) {
      currentDragId += 1

      return `dragging-${currentDragId}`
    }

    if (action.type === DRAG || action.type === END_DRAG) {
      return `dragging-${currentDragId}`
    }

    if (action.type === UPDATE_OBJECTS) {
      
      let firstObject = action.objects[0]

      if (firstObject) {
        let keys = Object.keys(action.objects[0])

        let ids = action.objects.map(obj => obj.id)

        return `update-${ids.join(',')}-${keys.join(',')}`
      }
    }

    if (action.type === UPDATE_OBJECT) {
      let { undoGroup } = action

      if (undoGroup) {
        return undoGroup
      }
    }

    return null
  },

  ignoreInitialState: true,
  syncFiltered: true,
  neverSkipReducer: true,
  limit: 200,
})

export default combineReducers({

  launchComponent,
  menus,
  layers,
  marquee,
  objects,
  tools,
  tables,
  libraries,
  modals,
  screenTemplates,
  stack,
})
