// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapped-entity-textarea {
  background: transparent;
  border: none;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.wrapped-entity-textarea .entity-textarea-block > span {
  -webkit-user-select: text;
          user-select: text;
}
`, "",{"version":3,"sources":["webpack://./src/styles/EntityTextarea.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":[".wrapped-entity-textarea {\n  background: transparent;\n  border: none;\n  padding-top: 15px !important;\n  padding-bottom: 15px !important;\n}\n\n.wrapped-entity-textarea .entity-textarea-block > span {\n  user-select: text;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
