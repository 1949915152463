// // ducks/index.js

export { default as editor } from './editor'
export { default as apps } from './apps'
export { default as users } from './users'
export { default as departments } from './departments'
export { default as accordions } from './accordions'
export { default as addons } from './addons'
export { default as appUsers } from './appUsers'
export { default as searchUsers } from './searchUsers'
export { default as notifications } from './notifications'
export { default as sockets } from './sockets'

export { default as settings } from './settings'
export { default as customActions } from './customActions'
export { default as components } from './components'
export { default as trace } from './trace'
export { default as admin } from './admin'

// отвечает за инициализацию рабочей области с произвольными размерами
export { default as draftInitCustomSizes } from 'components/draft_init/draft_init_custom_sizes/store'

// отвечает за инициализацию рабочей области с шаблонными размерами
export { default as draftInitTemplateSizes } from 'components/draft_init/draft_init_template_sizes/store'