// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-icon {
  width: 60px;
  height: 60px;
  border-radius: 13px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.app-icon-small {
  width: 30px;
  height: 30px;
  border-radius: 7px;
}

.app-icon-medium {
  width: 40px;
  height: 40px;
  border-radius: 9px;
}

.app-icon-dark-background {
  box-shadow: none;
}

.app-icon-empty {
  background-size: 60%;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/AppIcon.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,8CAA8C;AAChD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":[".app-icon {\n  width: 60px;\n  height: 60px;\n  border-radius: 13px;\n  background-color: #fff;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);\n}\n\n.app-icon-small {\n  width: 30px;\n  height: 30px;\n  border-radius: 7px;\n}\n\n.app-icon-medium {\n  width: 40px;\n  height: 40px;\n  border-radius: 9px;\n}\n\n.app-icon-dark-background {\n  box-shadow: none;\n}\n\n.app-icon-empty {\n  background-size: 60%;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
