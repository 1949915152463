// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-component-inner-wrapper {
  -webkit-font-smoothing: antialiased;
}

.library-component-error {
  display: block;
  background-color: rgb(255, 204, 204);
  border: 1px solid rgb(221, 0, 0);
  padding: 4px;
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/template/2d/Editor/Canvas/LibraryComponent/LibraryComponent.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;AACF;;AAEA;EACE,cAAA;EACA,oCAAA;EACA,gCAAA;EACA,YAAA;EAEA,YAAA;EACA,WAAA;AAAF","sourcesContent":[".library-component-inner-wrapper {\n  -webkit-font-smoothing: antialiased;\n}\n\n.library-component-error {\n  display: block;\n  background-color: rgb(255, 204, 204);\n  border: 1px solid rgb(221, 0, 0);\n  padding: 4px;\n\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
