import React, {Component} from 'react'
import {connect} from 'react-redux'
import {LABEL, RECTANGLE} from 'common/constants'

import {scaleRect, scaleValue, unScale} from '../../../utils/zoom'

import {
    ADD_COMPONENT,
    ADD_LIBRARY_COMPONENT,
    ADD_SHAPE_COMPONENT,
    DRAGGABLE,
    OVAL,
    UPLOAD,
    VECTOR,
} from '../../../utils/tools'

import {createRect} from '../../../utils/geometry'
import {getSnapValue} from '../../../utils/snapping'
import {getObjectAttributes, resetTool} from '../../../ducks/editor/tools'

import {getXGrid, getYGrid, resetSnaps, setXSnap, setYSnap,} from '../../../ducks/editor/snapping'

import {createObject, getCurrentAppId, getPlatform} from '../../../ducks/editor/objects'
import PathEditor from './PathEditor'
import CreateImage from './CreateImage'
import AddComponentInstance from './AddComponentInstance'
import AddLibraryComponent from './AddLibraryComponent'
import AddShapeComponent from './AddShapeComponent'
import AddDraggableComponent from './AddDraggableComponent'
import {createRectangle} from "../../../utils/shapes";
import { getApp } from "ducks/apps/selectors";

const ESC = 27

class CreateObject extends Component {
  state = {
    startPoint: null,
    currentPoint: null,
    shiftPressed: false,
    altPressed: false,
  }

  handleKeyDown = e => {
    let { resetTool } = this.props

    if (e.which === ESC) {
      resetTool()
    }
  }

  getPoint = e => {
    let { zoom, xGrid, yGrid, setXSnap, setYSnap } = this.props
    let point = unScale([e.clientX, e.clientY], zoom)

    if (!xGrid || !yGrid) {
      return point
    }

    let xSnapped = getSnapValue(xGrid, { x: point[0] }, zoom)
    let ySnapped = getSnapValue(yGrid, { y: point[1] }, zoom)

    setXSnap(xSnapped && xSnapped.x)
    setYSnap(ySnapped && ySnapped.y)

    return [xSnapped ? xSnapped.x : point[0], ySnapped ? ySnapped.y : point[1]]
  }

  handleMouseDown = e =>
    this.setState({
      startPoint: this.getPoint(e),
    })

  handleMouseMove = e => {

    this.setState({
      currentPoint: this.getPoint(e),
      shiftPressed: e.shiftKey,
      altPressed: e.altKey,
    })
  }
  handleMouseUp = e => {
    let { createObject, resetTool, objectAttributes, zoom } = this.props
    let { startPoint } = this.state

    if (!startPoint) {
      return
    }

    let rect = this.getRect()

    resetTool()

    if (rect) {

      if (objectAttributes.type === LABEL) {
        objectAttributes = {
          ...objectAttributes,
          autoWidth: false,
        }
      }

      if (objectAttributes.type === RECTANGLE) {
        let {app} = this.props

        objectAttributes = {
          ...createRectangle({
            ...objectAttributes,
            ...rect,
          }),
          parentId: app && app.launchComponentId
        }
      }

      if (rect.width > 0 || rect.height > 0) {
        createObject({
          ...objectAttributes,
          ...rect,
        })
      }

    } else {
      // For type, create auto-width text starting at current point
      if (objectAttributes.type === LABEL) {
        let [x, y] = startPoint
        x = Math.round(x)
        y = Math.round(y) - 8

        createObject({ ...objectAttributes, x, y })
      }
    }
  }


  getRect = () => {
    let { startPoint, currentPoint, shiftPressed, altPressed } = this.state

    if (
      !startPoint ||
      !currentPoint ||
      (startPoint[0] === currentPoint[0] && startPoint[1] === currentPoint[1])
    ) {
      return null
    }

    let diffX = currentPoint[0] - startPoint[0]
    let diffY = currentPoint[1] - startPoint[1]

    let xSign = diffX ? diffX / Math.abs(diffX) : 1
    let ySign = diffY ? diffY / Math.abs(diffY) : 1

    if (shiftPressed) {
      let dist = Math.max(Math.abs(diffX), Math.abs(diffY))

      currentPoint = [
        startPoint[0] + xSign * dist,
        startPoint[1] + ySign * dist,
      ]
    }

    if (altPressed) {
      startPoint = [
        startPoint[0] - (currentPoint[0] - startPoint[0]),
        startPoint[1] - (currentPoint[1] - startPoint[1]),
      ]
    }

    return createRect(startPoint, currentPoint, true)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    let { resetSnaps } = this.props

    document.removeEventListener('keydown', this.handleKeyDown)
    resetSnaps()
  }

  render() {
    let { objectAttributes, tool, zoom, platform } = this.props

    let rect = scaleRect(this.getRect(), zoom)

    let borderRadius = 0

    if (rect && objectAttributes.borderRadius) {
      borderRadius = Math.min(
        rect.width / 2,
        rect.height / 2,
        scaleValue(objectAttributes.borderRadius, zoom)
      )
    }

    if (tool === OVAL) {
      borderRadius = 100000
    }

    if (tool === VECTOR) {
      return <PathEditor zoom={zoom} />
    }

    if (tool === UPLOAD) {
      return <CreateImage zoom={zoom} />
    }

    if (tool === ADD_COMPONENT) {
      return <AddComponentInstance zoom={zoom} options={objectAttributes} />
    }

    if (tool === ADD_LIBRARY_COMPONENT) {
      if (platform !== 'web') {
        if (objectAttributes.width > 375) {
          objectAttributes.width = 375
        }
      }

      return <AddLibraryComponent zoom={zoom} options={objectAttributes} />
    }
    if (tool === ADD_SHAPE_COMPONENT) {
      if (platform !== 'web') {
        if (objectAttributes.width > 375) {
          objectAttributes.width = 375
        }
      }

      return <AddShapeComponent zoom={zoom} options={objectAttributes} />
    }

    if (tool === DRAGGABLE) {
      return <AddDraggableComponent zoom={zoom} options={objectAttributes} />
    }

    return (
      <g
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        pointerEvents="all"
      >
        <rect
          x={0}
          y={0}
          width={window.innerWidth}
          height={window.innerHeight}
          style={{ fill: 'none' }}
        />
        {rect && (
          <rect
            x={rect.x - 0.5}
            y={rect.y - 0.5}
            width={rect.width + 1}
            height={rect.height + 1}
            rx={borderRadius}
            ry={borderRadius}
            style={{ fill: 'none', stroke: '#808080', strokeWidth: 0.5 }}
          />
        )}
      </g>
    )
  }
}

const mapStateToProps = state => ({
  app: getApp(state, getCurrentAppId(state)),
  objectAttributes: getObjectAttributes(state),
  xGrid: getXGrid(state),
  yGrid: getYGrid(state),
  platform: getPlatform(state),
})

export default connect(mapStateToProps, {
  createObject,
  resetTool,
  setXSnap,
  setYSnap,
  resetSnaps,
})(CreateObject)
