import {Change, ChangeFlag, CollisionDetection, ItemSelection, Numerical, UID} from './core'
import Base from './base'
import {HitResult} from './hitresult'
import {Formatter} from './formatter'
import {Size} from './sizes'
import {Matrix} from './matrix'
import {LinkedPoint, Point} from './point'
import {LinkedRectangle, Rectangle} from './rectangle'
import {Line} from './line'
import {Curve} from './curve'
import {CurveLocation} from './curvelocation'
import {Segment} from './segment'
import {PaperOffset} from './offset'
import {PathFlattener} from './pathflattener'

export class Item extends Base {
  static extend(src) {

    return super.extend.apply(this, arguments);
  }

  constructor(props, point) {

    super(...arguments)

    this._visible = true
    this._name = null
    // All items apply their matrix by default.
    // Exceptions are Raster, SymbolItem, Clip and Shape.
    this._applyMatrix = true
    this._canApplyMatrix = true
    this._canScaleStroke = false
    this._pivot = null
    this._visible = true
    this._blendMode = 'normal'
    this._opacity = 1
    this._locked = false
    this._guide = false
    this._clipMask = false
    this._selection = 0
    // Controls whether bounds should appear selected when the item is selected.
    // This is only turned off for Group, Layer and PathItem, where it can be
    // selected separately by setting item.bounds.selected = true;
    this._selectBounds = true
    this._selectChildren = false
  }


  /**
   * Private helper for #initialize() that tries setting properties from the
   * passed props object, and apply the point translation to the internal
   * matrix.
   *
   * @param {Object} props the properties to be applied to the item
   * @param {Point} point the point by which to transform the internal matrix
   * @return {Boolean} {@true if the properties were successfully be applied,
     * or if none were provided}
   */
  _initialize(props, point) {
    // Define this Item's unique id. But allow the creation of internally
    // used paths with no ids.
    var hasProps = props && Base.isPlainObject(props),
      internal = hasProps && props.internal === true,
      matrix = this._matrix = new Matrix(),
      // Allow setting another project than the currently active one.
      project = hasProps && props.project || {},
      settings = {};
    this._id = internal ? null : UID.get();
    this._parent = this._index = null;
    this._depth = this._depth = 0;
    // Inherit the applyMatrix setting from settings.applyMatrix
    this._applyMatrix = this._canApplyMatrix && settings.applyMatrix;
    // Handle matrix before everything else, to avoid issues with
    // #addChild() calling _changed() and accessing _matrix already.
    if (point)
      matrix.translate(point);
    matrix._owner = this;

    // Do not add to the project if it's an internal path,  or if
    // props.insert  or settings.isnertItems is false.
    /*if (internal || hasProps && props.insert == false
      || !settings.insertItems && !(hasProps && props.insert === true)) {
      this._setProject(project);
    } else {
      (hasProps && props.parent || project)
        ._insertItem(undefined, this, true); // _created = true
    }*/


    // Filter out Item.NO_INSERT before _set(), for performance reasons.
    if (hasProps && props !== Item.NO_INSERT) {
      this.set(props, {
        // Filter out these properties as they were handled above:
        internal: true, insert: true, project: true, parent: true
      });
    }
    return hasProps;
  }

  _serialize(options, dictionary) {
    var props = {},
      that = this;

    function serialize(fields) {
      for (var key in fields) {
        // value is the default value, only serialize if the current
        // value is different from it.
        var value = that[key];
        // Style#leading is a special case, as its default value is
        // dependent on the fontSize. Handle this here separately.
        if (!Base.equals(value, key === 'leading'
          ? fields.fontSize * 1.2 : fields[key])) {
          props[key] = Base.serialize(value, options,
            // Do not use compact mode for data
            key !== 'data', dictionary);
        }
      }
    }

    // Serialize fields that this Item subclass defines first
    serialize(this._serializeFields);
    // Serialize style fields, but only if they differ from defaults.
    // Do not serialize styles on Groups and Layers, since they just unify
    // their children's own styles.
    /*    if (!(this instanceof Group))
          serialize(this._style._defaults);*/
    // There is no compact form for Item serialization, we always keep the
    // class.
    return [this._class, props];
  }

  /**
   * Private notifier that is called whenever a change occurs in this item or
   * its sub-elements, such as Segments, Curves, Styles, etc.
   *
   * @param {ChangeFlag} flags describes what exactly has changed
   */
  _changed(flags) {
    var symbol = this._symbol,
      cacheParent = this._parent || symbol,
      project = this._project;
    if (flags & /*#=*/ChangeFlag.GEOMETRY) {
      // Clear cached bounds, position and decomposed matrix whenever
      // geometry changes.
      this._bounds = this._position = this._decomposed = undefined;
    }
    if (flags & /*#=*/ChangeFlag.MATRIX) {
      this._globalMatrix = undefined;
    }
    if (cacheParent
      && (flags & /*#=*/(ChangeFlag.GEOMETRY | ChangeFlag.STROKE))) {
      // Clear cached bounds of all items that this item contributes to.
      // We call this on the parent, since the information is cached on
      // the parent, see getBounds().
      Item._clearBoundsCache(cacheParent);
    }
    if (flags & /*#=*/ChangeFlag.CHILDREN) {
      // Clear cached bounds of all items that this item contributes to.
      // Here we don't call this on the parent, since adding / removing a
      // child triggers this notification on the parent.
      Item._clearBoundsCache(this);
    }
    /*if (project)
      project._changed(flags, this);*/
    // If this item is a symbol's definition, notify it of the change too
    if (symbol)
      symbol._changed(flags);
  }


  getId() {
    return this._id;
  }


  get name() {
    return this._name;
  }

  set name(name) {
    // NOTE: Don't check if the name has changed and bail out if it has not,
    // because setName is used internally also to update internal structures
    // when an item is moved from one parent to another.

    // If the item already had a name, remove the reference to it from the
    // parent's children object:
    if (this._name)
      this._removeNamed();
    // See if the name is a simple number, which we cannot support due to
    // the named lookup on the children array.
    if (name === (+name) + '')
      throw new Error(
        'Names consisting only of numbers are not supported.');
    var owner = this.getParent();
    if (name && owner) {
      var children = owner._children,
        namedChildren = owner._namedChildren;
      (namedChildren[name] = namedChildren[name] || []).push(this);
      // Only set this item if there isn't one under the same name already
      if (!(name in children))
        children[name] = this;
    }
    this._name = name || undefined;
    this._changed(/*#=*/ChangeFlag.ATTRIBUTE);
  }


  get style() {
    return this._style;
  }

  set style(style) {
    // Don't access _style directly so Path#getStyle() can be overridden for
    // CompoundPaths.
    this.style().set(style);
  }


  getSelection() {
    return this._selection;
  }

  setSelection(selection) {
    if (selection !== this._selection) {
      this._selection = selection;
      /*var project = this._project;
      if (project) {
        project._updateSelection(this);
        this._changed(/!*#=*!/Change.ATTRIBUTE);
      }*/
    }
  }

  _changeSelection(flag, selected) {
    var selection = this._selection;
    this.setSelection(selected ? selection | flag : selection & ~flag);
  }


  isSelected() {
    if (this._selectChildren) {
      var children = this._children;
      for (var i = 0, l = children.length; i < l; i++)
        if (children[i].isSelected())
          return true;
    }
    return !!(this._selection & /*#=*/ItemSelection.ITEM);
  }

  setSelected(selected) {
    if (this._selectChildren) {
      var children = this._children;
      for (var i = 0, l = children.length; i < l; i++)
        children[i].setSelected(selected);
    }
    this._changeSelection(/*#=*/ItemSelection.ITEM, selected);
  }

  isFullySelected() {
    var children = this._children,
      selected = !!(this._selection & /*#=*/ItemSelection.ITEM);
    if (children && selected) {
      for (var i = 0, l = children.length; i < l; i++)
        if (!children[i].isFullySelected())
          return false;
      return true;
    }
    // If there are no children, this is the same as #selected
    return selected;
  }

  setFullySelected(selected) {
    var children = this._children;
    if (children) {
      for (var i = 0, l = children.length; i < l; i++)
        children[i].setFullySelected(selected);
    }
    this._changeSelection(/*#=*/ItemSelection.ITEM, selected);
  }

  get depth() {
    return this._depth;
  }

  set depth(data) {
    this._depth = data;
  }
  getData() {
    if (!this._data)
      this._data = {};
    return this._data;
  }

  setData(data) {
    this._data = data;
  }


  getPosition(_dontLink) {
    // Cache position value.
    // Pass true for _dontLink in getCenter(), so receive back a normal point
    var ctor = _dontLink ? Point : LinkedPoint;
    // Do not cache LinkedPoints directly, since we would not be able to
    // use them to calculate the difference in #setPosition, as when it is
    // modified, it would hold new values already and only then cause the
    // calling of #setPosition.
    var position = this._position ||
      (this._position = this._getPositionFromBounds());
    return new ctor(position.x, position.y, this, 'setPosition');
  }

  setPosition(point) {
    // Calculate the distance to the current position, by which to
    // translate the item. Pass true for _dontLink, as we do not need a
    // LinkedPoint to simply calculate this distance.

    this.translate(point/*Point.read(arguments)*/.subtract(this.getPosition(true)));
  }
  center(x, y) {
    this.translate(new Point(x , y ));
  }
  adjust(x, y) {
    this.translate(new Point(x + this.bounds.width / 2, y + this.bounds.height / 2).subtract(this.getPosition(true)));
  }
  getRotatePoint(x, y) {
    return new Point(x + this.bounds.width / 2 , y + this.bounds.height / 2 );
  }
  /**
   * Internal method used to calculate position either from pivot point or
   * bounds.
   * @param {Rectangle} bounds if provided, these bounds are used instead of
   *     calling getBounds()
   * @return {Point} the transformed pivot point or the center of the bounds
   * @private
   */
  _getPositionFromBounds(bounds) {
    // If an pivot point is provided, use it to determine position
    // based on the matrix. Otherwise use the center of the bounds.
    return this._pivot
      ? this._matrix._transformPoint(this._pivot)
      : (bounds || this.getBounds()).getCenter(true);
  }

  /**
   * The item's pivot point specified in the item coordinate system, defining
   * the point around which all transformations are hinging. This is also the
   * reference point for {@link #position}. By default, it is set to `null`,
   * meaning the {@link Rectangle#center} of the item's {@link #bounds}
   * rectangle is used as pivot.
   *
   * @bean
   * @type Point
   * @default null
   */
  getPivot() {
    var pivot = this._pivot;
    return pivot
      ? new LinkedPoint(pivot.x, pivot.y, this, 'setPivot')
      : null;
  }

  setPivot(point) {
    // Clone existing points since we're caching internally.
    this._pivot = Point.read(arguments, 0, {clone: true, readNull: true});
    // No need for _changed() since the only thing this affects is _position
    this._position = undefined;
  }

  getStrokeBounds(matrix) {

    return this.getBounds(matrix, {stroke: true});
  }

  getHandleBounds(matrix) {

    return this.getBounds(matrix, {handle: true});
  }

  getInternalBounds(matrix) {

    return this.getBounds(matrix, {internal: true});
  }

  get bounds() {
    var opts = {}

    if (!opts.stroke /*|| this.getStrokeScaling()*/)
      opts.cacheItem = this;

    var rect = this._getCachedBounds(false, opts).rect;

    return !arguments.length
      ? new LinkedRectangle(rect.x, rect.y, rect.width, rect.height,
        this, 'setBounds')
      : rect;
  }

  getBounds(matrix, options) {
    var hasMatrix = options || matrix instanceof Matrix,
      opts = Object.assign({}, hasMatrix ? options : matrix,
        {});
    // We can only cache the bounds if the path uses stroke-scaling, or if
    // no stroke is involved in the calculation of the bounds.
    // When strokeScaling is false, the bounds are affected by the zoom
    // level of the view, hence we can't cache.
    // TODO: Look more into handling of stroke-scaling, e.g. on groups with
    // some children that have strokeScaling, as well as SymbolItem with
    // SymbolDefinition that have strokeScaling!
    // TODO: Once that is resolved, we should be able to turn off
    // opts.stroke if a resolved item definition does not have a stroke,
    // allowing the code to share caches between #strokeBounds and #bounds.
    if (!opts.stroke /*|| this.getStrokeScaling()*/)
      opts.cacheItem = this;
    // If we're caching bounds, pass on this item as cacheItem, so
    // the children can setup _boundsCache structures for it.
    var rect = this._getCachedBounds(hasMatrix && matrix, opts).rect;
    // If we're returning '#bounds', create a LinkedRectangle that uses
    // the setBounds() setter to update the Item whenever the bounds are
    // changed:
    return !arguments.length
      ? new LinkedRectangle(rect.x, rect.y, rect.width, rect.height,
        this, 'setBounds')
      : rect;
  }

  setBounds(/* rect */) {
    var rect = Rectangle.read(arguments),
      bounds = this.getBounds(),
      _matrix = this._matrix,
      matrix = new Matrix(),
      center = rect.getCenter();
    // Read this from bottom to top:
    // Translate to new center:
    matrix.translate(center);
    // Scale to new Size, if size changes and avoid divisions by 0:
    if (rect.width != bounds.width || rect.height != bounds.height) {
      // If a previous transformation resulted in a non-invertible matrix,
      // Restore to the last revertible matrix stored in _backup, and get
      // the bounds again. That way, we can prevent collapsing to 0-size.
      if (!_matrix.isInvertible()) {
        _matrix.initialize(_matrix._backup
          || new Matrix().translate(_matrix.getTranslation()));
        bounds = this.getBounds();
      }
      matrix.scale(
        bounds.width !== 0 ? rect.width / bounds.width : 0,
        bounds.height !== 0 ? rect.height / bounds.height : 0);
    }
    // Translate to bounds center:
    center = bounds.getCenter();
    matrix.translate(-center.x, -center.y);
    // Now execute the transformation
    this.transform(matrix);
  }


  _getBounds(matrix, options) {
    // NOTE: We cannot cache these results here, since we do not get
    // _changed() notifications here for changing geometry in children.
    // But cacheName is used in sub-classes such as SymbolItem and Raster.
    var children = this._children;
    // TODO: What to return if nothing is defined, e.g. empty Groups?
    // Scriptographer behaves weirdly then too.
    if (!children || !children.length)
      return new Rectangle();
    // Call _updateBoundsCache() even when the group only holds empty /
    // invisible items), so future changes in these items will cause right
    // handling of _boundsCache.
    Item._updateBoundsCache(this, options.cacheItem);
    return Item._getBounds(children, matrix, options);
  }

  _getBoundsCacheKey(options, internal) {
    return [
      options.stroke ? 1 : 0,
      options.handle ? 1 : 0,
      internal ? 1 : 0
    ].join('');
  }

  /**
   * Private method that deals with the calling of _getBounds, recursive
   * matrix concatenation and handles all the complicated caching mechanisms.
   */
  _getCachedBounds(matrix, options, noInternal) {
    // See if we can cache these bounds. We only cache the bounds
    // transformed with the internally stored _matrix, (the default if no
    // matrix is passed).
    matrix = matrix && matrix._orNullIfIdentity();
    // Do not transform by the internal matrix for internal, untransformed
    // bounds.
    var internal = options.internal && !noInternal,
      cacheItem = options.cacheItem,
      _matrix = internal ? null : this._matrix._orNullIfIdentity(),
      // Create a key for caching, reflecting all bounds options.
      cacheKey = cacheItem && (!matrix || matrix.equals(_matrix))
        && this._getBoundsCacheKey(options, internal),
      bounds = this._bounds;
    // NOTE: This needs to happen before returning cached values, since even
    // then, _boundsCache needs to be kept up-to-date.
    Item._updateBoundsCache(this._parent || this._symbol, cacheItem);
    if (cacheKey && bounds && cacheKey in bounds) {
      var cached = bounds[cacheKey];
      return {
        rect: cached.rect.clone(),
        nonscaling: cached.nonscaling
      };
    }
    var res = this._getBounds(matrix || _matrix, options),
      // Support two versions of _getBounds(): One that directly returns a
      // Rectangle, and one that returns a bounds object with nonscaling.
      rect = res.rect || res,
      style = this._style,
      nonscaling = res.nonscaling/* || style.hasStroke()*/
      /* && !style.getStrokeScaling()*/;
    // If we can cache the result, update the _bounds cache structure
    // before returning
    if (cacheKey) {
      if (!bounds) {
        this._bounds = bounds = {};
      }
      var cached = bounds[cacheKey] = {
        rect: rect.clone(),
        nonscaling: nonscaling,
        // Mark as internal, so Item#transform() won't transform it
        internal: internal
      };
    }
    return {
      rect: rect,
      nonscaling: nonscaling
    };
  }

  /**
   * Returns to correct matrix to use to transform stroke related geometries
   * when calculating bounds: the item's matrix if {@link #strokeScaling} is
   * `true`, otherwise the parent's inverted view matrix. The returned matrix
   * is always shiftless, meaning its translation vector is reset to zero.
   */
  _getStrokeMatrix(matrix, options) {
    var parent = null/*this.getStrokeScaling() ? null
      : options && options.internal ? this
        : this._parent || this._symbol && this._symbol._item*/,
      mx = parent ? parent.getViewMatrix().invert() : matrix;
    return mx && mx._shiftless();
  }


  /**
   * Set up a boundsCache structure that keeps track of items that keep
   * cached bounds that depend on this item. We store this in the parent,
   * for multiple reasons:
   * The parent receives CHILDREN change notifications for when its
   * children are added or removed and can thus clear the cache, and we
   * save a lot of memory, e.g. when grouping 100 items and asking the
   * group for its bounds. If stored on the children, we would have 100
   * times the same structure.
   */
  static _updateBoundsCache(parent, item) {
    if (parent && item) {
      // Set-up the parent's boundsCache structure if it does not
      // exist yet and add the item to it.
      var id = item._id,
        ref = parent._boundsCache = parent._boundsCache || {
          // Use a hash-table for ids and an array for the list,
          // so we can keep track of items that were added already
          ids: {},
          list: []
        };
      if (!ref.ids[id]) {
        ref.list.push(item);
        ref.ids[id] = item;
      }
    }
  }

  /**
   * Clears cached bounds of all items that the children of this item are
   * contributing to. See _updateBoundsCache() for an explanation why this
   * information is stored on parents, not the children themselves.
   */
  static _clearBoundsCache(item) {
    // This is defined as a static method so Symbol can used it too.
    // Clear the position as well, since it's depending on bounds.
    var cache = item._boundsCache;
    if (cache) {
      // Erase cache before looping, to prevent circular recursion.
      item._bounds = item._position = item._boundsCache = undefined;
      for (var i = 0, list = cache.list, l = list.length; i < l; i++) {
        var other = list[i];
        if (other !== item) {
          other._bounds = other._position = undefined;
          // We need to recursively call _clearBoundsCache, as
          // when the cache for the other item's children is not
          // valid anymore, that propagates up the scene graph.
          if (other._boundsCache)
            Item._clearBoundsCache(other);
        }
      }
    }
  }

  /**
   * Gets the combined bounds of all specified items.
   */
  static _getBounds(items, matrix, options) {
    var x1 = Infinity,
      x2 = -x1,
      y1 = x1,
      y2 = x2,
      nonscaling = false;
    // NOTE: As soon as one child-item has non-scaling strokes, the full
    // bounds need to be considered non-scaling for caching purposes.
    options = options || {};
    for (var i = 0, l = items.length; i < l; i++) {
      var item = items[i];
      // Item is handled if it is visible and not recursively empty.
      // This avoid errors with nested empty groups (#1467).
      if (item._visible && !item.isEmpty(true)) {
        // Pass true for noInternal, since even when getting
        // internal bounds for this item, we need to apply the
        // matrices to its children.
        var bounds = item._getCachedBounds(
          matrix && matrix.appended(item._matrix), options, true),
          rect = bounds.rect;
        x1 = Math.min(rect.x, x1);
        y1 = Math.min(rect.y, y1);
        x2 = Math.max(rect.x + rect.width, x2);
        y2 = Math.max(rect.y + rect.height, y2);
        if (bounds.nonscaling)
          nonscaling = true;
      }
    }
    return {
      rect: isFinite(x1)
        ? new Rectangle(x1, y1, x2 - x1, y2 - y1)
        : new Rectangle(),
      nonscaling: nonscaling
    };
  }


  _decompose() {
    // Only decompose if the item isn't directly baking transformations into
    // its content.
    return this._applyMatrix
      ? null
      : this._decomposed || (this._decomposed = this._matrix.decompose());
  }

  /**
   * The current rotation angle of the item, as described by its
   * {@link #matrix}.
   * Please note that this only returns meaningful values for items with
   * {@link #applyMatrix} set to `false`, meaning they do not directly bake
   * transformations into their content.
   *
   * @bean
   * @type Number
   */
  getRotation() {
    var decomposed = this._decompose();
    // Return 0 if matrix wasn't decomposed, e.g. on items with #applyMatrix
    return decomposed ? decomposed.rotation : 0;
  }

  setRotation(rotation) {
    var current = this.getRotation();
    if (current != null && rotation != null) {
      // Preserve the cached _decomposed values over rotation, and only
      // update the rotation property on it.
      var decomposed = this._decomposed;
      this.rotate(rotation - current);
      if (decomposed) {
        decomposed.rotation = rotation;
        this._decomposed = decomposed;
      }
    }
  }

  /**
   * The current scale factor of the item, as described by its
   * {@link #matrix}.
   * Please note that this only returns meaningful values for items with
   * {@link #applyMatrix} set to `false`, meaning they do not directly bake
   * transformations into their content.
   *
   * @bean
   * @type Point
   */
  getScaling() {
    var decomposed = this._decompose(),
      s = decomposed && decomposed.scaling;
    // Return [1, 1] if matrix wasn't decomposed, e.g. with #applyMatrix.
    return new LinkedPoint(s ? s.x : 1, s ? s.y : 1, this, 'setScaling');
  }

  setScaling(/* scaling */) {
    var current = this.getScaling(),
      // Clone existing points since we're caching internally.
      scaling = Point.read(arguments, 0, {clone: true, readNull: true});
    if (current && scaling && !current.equals(scaling)) {
      // See #setRotation() for preservation of _decomposed.
      var rotation = this.getRotation(),
        decomposed = this._decomposed,
        matrix = new Matrix(),
        isZero = Numerical.isZero;
      // Create a matrix in which the scaling is applied in the non-
      // rotated state, so it is always applied before the rotation.
      // TODO: What about skewing? Do we need separately stored values for
      // these properties, and apply them separately from the matrix?
      if (isZero(current.x) || isZero(current.y)) {
        // If current scaling is destructive (at least one axis is 0),
        // create a new matrix that applies the desired rotation,
        // translation and scaling, without also preserving skewing.
        matrix.translate(decomposed.translation);
        if (rotation) {
          matrix.rotate(rotation);
        }
        matrix.scale(scaling.x, scaling.y);
        this._matrix.set(matrix);
      } else {
        var center = this.getPosition(true);
        matrix.translate(center);
        if (rotation)
          matrix.rotate(rotation);
        matrix.scale(scaling.x / current.x, scaling.y / current.y);
        if (rotation)
          matrix.rotate(-rotation);
        matrix.translate(center.negate());
        this.transform(matrix);
      }
      if (decomposed) {
        decomposed.scaling = scaling;
        this._decomposed = decomposed;
      }
    }
  }

  /**
   * The item's transformation matrix, defining position and dimensions in
   * relation to its parent item in which it is contained.
   *
   * @bean
   * @type Matrix
   */
  getMatrix() {
    return this._matrix;
  }

  setMatrix() {
    // Use Matrix#initialize to easily copy over values.
    // NOTE: calling initialize() also calls #_changed() for us, through its
    // call to #set() / #reset(), and this also handles _applyMatrix for us.
    var matrix = this._matrix;
    matrix.set.apply(matrix, arguments);
  }

  /**
   * The item's global transformation matrix in relation to the global project
   * coordinate space. Note that the view's transformations resulting from
   * zooming and panning are not factored in.
   *
   * @bean
   * @type Matrix
   */
  getGlobalMatrix(_dontClone) {
    var matrix = this._globalMatrix;
    if (matrix) {
      // If there's a cached global matrix for this item, check if all its
      // parents also have one. If it's missing in any of its parents, it
      // means the child's cached version isn't valid anymore.
      // For better performance, we also use the occasion of this loop to
      // clear cached version of items parents.
      var parent = this._parent;
      var parents = [];
      while (parent) {
        if (!parent._globalMatrix) {
          matrix = null;
          // Also clear global matrix of item's parents.
          for (var i = 0, l = parents.length; i < l; i++) {
            parents[i]._globalMatrix = null;
          }
          break;
        }
        parents.push(parent);
        parent = parent._parent;
      }
    }
    if (!matrix) {
      matrix = this._globalMatrix = this._matrix.clone();
      var parent = this._parent;
      if (parent)
        matrix.prepend(parent.getGlobalMatrix(true));
    }
    return _dontClone ? matrix : matrix.clone();
  }

  /**
   * The item's global matrix in relation to the view coordinate space. This
   * means that the view's transformations resulting from zooming and panning
   * are factored in.
   *
   * @bean
   * @type Matrix
   */
  getViewMatrix() {
    return this.getGlobalMatrix().prepend(this.getView()._matrix);
  }

  /**
   * Controls whether the transformations applied to the item (e.g. through
   * {@link #transform(matrix)}, {@link #rotate(angle)},
   * {@link #scale(scale)}, etc.) are stored in its {@link #matrix} property,
   * or whether they are directly applied to its contents or children (passed
   * on to the segments in {@link Path} items, the children of {@link Group}
   * items, etc.).
   *
   * @bean
   * @type Boolean
   * @default true
   */
  getApplyMatrix() {
    return this._applyMatrix;
  }

  setApplyMatrix(apply) {
    // Tell #transform() to apply the internal matrix if _applyMatrix
    // can be set to true.
    if (this._applyMatrix = this._canApplyMatrix && !!apply)
      this.transform(null, true);
  }


  /**
   * {@grouptitle Project Hierarchy}
   * The project that this item belongs to.
   *
   * @type Project
   * @bean
   */
  getProject() {
    return this._project;
  }

  _setProject(project, installEvents) {
    if (this._project !== project) {
      // Uninstall events before switching project, then install them
      // again.
      // NOTE: _installEvents handles all children too!
      if (this._project)
        this._installEvents(false);
      this._project = project;
      var children = this._children;
      for (var i = 0, l = children && children.length; i < l; i++)
        children[i]._setProject(project);
      // We need to call _installEvents(true) again, but merge it with
      // handling of installEvents argument below.
      installEvents = true;
    }
    if (installEvents)
      this._installEvents(true);
  }

  /**
   * The view that this item belongs to.
   * @type View
   * @bean
   */
  getView() {
    return this._project._view;
  }

  /**
   * Overrides Emitter#_installEvents to also call _installEvents on all
   * children.
   */
  _installEvents(install) {
    /* super._installEvents.call(this, install);*/
    var children = this._children;
    for (var i = 0, l = children && children.length; i < l; i++)
      children[i]._installEvents(install);
  }

  /**
   * The layer that this item is contained within.
   *
   * @type Layer
   * @bean
   */
  getLayer() {
    var parent = this;
    while (parent = parent._parent) {
      if (parent instanceof Layer)
        return parent;
    }
    return null;
  }


  getParent() {
    return this._parent;
  }
  get parent() {
    return this.getParent();
  }
  setParent(item) {
    return item.addChild(this);
  }
  set parent(item) {
    return this.setParent(item);
  }

  getChildren() {
    return this._children;
  }
  get children() {
    return this._children;
  }
  setChildren(items) {
    this.removeChildren();
    this.addChildren(items);
  }

  set children(items) {
    this.setChildren(items);
  }
  getFirstChild() {
    return this._children && this._children[0] || null;
  }


  getLastChild() {
    return this._children && this._children[this._children.length - 1]
      || null;
  }

  /**
   * The next item on the same level as this item.
   *
   * @type Item
   * @bean
   */
  getNextSibling() {
    var owner = this.getParent();
    return owner && owner._children[this._index + 1] || null;
  }

  /**
   * The previous item on the same level as this item.
   *
   * @type Item
   * @bean
   */
  getPreviousSibling() {
    var owner = this.getParent();
    return owner && owner._children[this._index - 1] || null;
  }

  /**
   * The index of this item within the list of its parent's children.
   *
   * @type Number
   * @bean
   */
  getIndex() {
    return this._index;
  }
  get index() {
    return this._index;
  }
  equals(item) {
    // NOTE: We do not compare name and selected state.
    // TODO: Consider not comparing locked and visible also?
    return item === this || item && this._class === item._class
      /*   && this._style.equals(item._style)*/
      && this._matrix.equals(item._matrix)
      && this._locked === item._locked
      && this._visible === item._visible
      && this._blendMode === item._blendMode
      && this._opacity === item._opacity
      && this._clipMask === item._clipMask
      && this._guide === item._guide
      && this._equals(item)
      || false;
  }

  /**
   * A private helper for #equals(), to be overridden in sub-classes. When it
   * is called, item is always defined, of the same class as `this` and has
   * equal general state attributes such as matrix, style, opacity, etc.
   */
  _equals(item) {
    return Base.equals(this._children, item._children);
  }

  /**
   * Clones the item within the same project and places the copy above the
   * item.
   *
   * @option [insert=true] specifies whether the copy should be
   *     inserted into the scene graph. When set to `true`, it is inserted
   *     above the original
   * @option [deep=true] specifies whether the item's children should also be
   *     cloned
   *
   * @param {Object} [options={ insert: true, deep: true }]
   *
   * @return {Item} the newly cloned item
   * @chainable
   *
   * @example {@paperscript}
   * // Cloning items:
   * var circle = new Path.Circle({
   *     center: [50, 50],
   *     radius: 10,
   *     fillColor: 'red'
   * });
   *
   * // Make 20 copies of the circle:
   * for (var i = 0; i < 20; i++) {
   *     var copy = circle.clone();
   *
   *     // Distribute the copies horizontally, so we can see them:
   *     copy.position.x += i * copy.bounds.width;
   * }
   */
  clone(options) {
    var copy = new this.constructor(Item.NO_INSERT),
      children = this._children,
      // Both `insert` and `deep` are true by default:
      insert = Base.pick(options ? options.insert : undefined,
        // Also support boolean parameter for insert, default: true.
        options === undefined || options === true),
      deep = Base.pick(options ? options.deep : undefined, true);
    // On items with children, for performance reasons due to the way that
    // styles are currently "flattened" into existing children, we need to
    // clone attributes first, then content.
    // For all other items, it's the other way around, since applying
    // attributes might have an impact on their content.
    if (children)
      copy.copyAttributes(this);
    // Only copy content if we don't have children or if we're ask to create
    // a deep clone, which is the default.
    if (!children || deep)
      copy.copyContent(this);
    if (!children)
      copy.copyAttributes(this);
    if (insert)
      copy.insertAbove(this);
    // Make sure we're not overriding the original name in the same parent
    var name = this._name,
      parent = this._parent;
    if (name && parent) {
      var children = parent._children,
        orig = name,
        i = 1;
      while (children[name])
        name = orig + ' ' + (i++);
      if (name !== orig)
        copy.name = (name);
    }
    return copy;
  }

  /**
   * Copies the content of the specified item over to this item.
   *
   * @param {Item} source the item to copy the content from
   */
  copyContent(source) {
    var children = source._children;
    // Clone all children and add them to the copy. tell #addChild we're
    // cloning, as needed by CompoundPath#insertChild().
    for (var i = 0, l = children && children.length; i < l; i++) {
      this.addChild(children[i].clone(false), true);
    }
  }

  /**
   * Copies all attributes of the specified item over to this item. This
   * includes its style, visibility, matrix, pivot, blend-mode, opacity,
   * selection state, data, name, etc.
   *
   * @param {Item} source the item to copy the attributes from
   * @param {Boolean} excludeMatrix whether to exclude the transformation
   * matrix when copying all attributes
   */
  copyAttributes(source, excludeMatrix) {
    // Copy over style
    //this.setStyle(source._style);
    // Only copy over these fields if they are actually defined in 'source',
    // meaning the default value has been overwritten (default is on
    // prototype).
    var keys = ['_locked', '_visible', '_blendMode', '_opacity',
      '_clipMask', '_guide'];
    for (var i = 0, l = keys.length; i < l; i++) {
      var key = keys[i];
      if (source.hasOwnProperty(key))
        this[key] = source[key];
    }
    // Use Matrix#initialize to easily copy over values.
    if (!excludeMatrix)
      this._matrix.initialize(source._matrix, true);
    // We can't just set _applyMatrix as many item types won't allow it,
    // e.g. creating a Shape in Path#toShape().
    // Using the setter instead takes care of it.
    // NOTE: This will also bake in the matrix that we just initialized,
    // in case #applyMatrix is true.
    this.setApplyMatrix(source._applyMatrix);
    this.setPivot(source._pivot);
    // Copy over the selection state, use setSelection so the item
    // is also added to Project#_selectionItems if it is selected.
    this.setSelection(source._selection);
    // Copy over data and name as well.
    var data = source._data,
      name = source._name;
    this._data = data ? Base.clone(data) : null;
    if (name)
      this.name = name;
  }


  rasterize(arg0, arg1) {
    var resolution,
      insert,
      raster;
    if (Base.isPlainObject(arg0)) {
      resolution = arg0.resolution;
      insert = arg0.insert;
      raster = arg0.raster;
    } else {
      resolution = arg0;
      insert = arg1;
    }
    if (!raster) {
      raster = new Raster(Item.NO_INSERT);
    }
    var bounds = this.getStrokeBounds(),
      scale = (resolution || this.getView().getResolution()) / 72,
      // Floor top-left corner and ceil bottom-right corner, to never
      // blur or cut pixels.
      topLeft = bounds.getTopLeft().floor(),
      bottomRight = bounds.getBottomRight().ceil(),
      boundsSize = new Size(bottomRight.subtract(topLeft)),
      rasterSize = boundsSize.multiply(scale);
    // Pass `true` for clear, so reused rasters don't draw over old pixels.
    raster.setSize(rasterSize, true);

    if (!rasterSize.isZero()) {
      var ctx = raster.getContext(true),
        matrix = new Matrix().scale(scale).translate(topLeft.negate());
      ctx.save();
      matrix.applyToContext(ctx);
      // See Project#draw() for an explanation of new Base()
      this.draw(ctx, new Base({matrices: [matrix]}));
      ctx.restore();
    }
    raster._matrix.initialize(
      new Matrix()
        .translate(topLeft.add(boundsSize.divide(2)))
        // Take resolution into account and scale back to original size.
        .scale(1 / scale)
    );
    if (insert === undefined || insert) {
      raster.insertAbove(this);
    }
    return raster;
  }


  contains(point) {
    // See CompoundPath#_contains() for the reason for !!
    var matrix = this._matrix;
    return (
      matrix.isInvertible() &&
      !!this._contains(matrix._inverseTransform(point/*Point.read(arguments)*/))
    );
  }

  _contains(point) {
    var children = this._children;
    if (children) {
      for (var i = children.length - 1; i >= 0; i--) {
        if (children[i].contains(point))
          return true;
      }
      return false;
    }
    // We only implement it here for items with rectangular content,
    // for anything else we need to override #contains()
    return point.isInside(this.getInternalBounds());
  }

  // DOCS:
  // TEST:
  /**
   * @param {Rectangle} rect the rectangle to check against
   * @return {Boolean}
   */
  isInside(/* rect */) {
    return Rectangle.read(arguments).contains(this.getBounds());
  }

  // Internal helper function, used at the moment for intersects check only.
  // TODO: Move #getIntersections() to Item, make it handle all type of items
  // through _asPathItem(), and support Group items as well, taking nested
  // matrices into account properly!
  _asPathItem() {
    // Creates a temporary rectangular path item with this item's bounds.
    return new Path.Rectangle({
      rectangle: this.getInternalBounds(),
      matrix: this._matrix,
      insert: false,
    });
  }

  // DOCS:
  // TEST:
  /**
   * @param {Item} item the item to check against
   * @return {Boolean}
   */
  intersects(item, _matrix) {
    if (!(item instanceof Item))
      return false;
    // Tell getIntersections() to return as soon as some intersections are
    // found, because all we care for here is there are some or none:
    return this._asPathItem().getIntersections(item._asPathItem(), null,
      _matrix, true).length > 0;
  }

  hitTest(point, options) {
    /* var args = arguments;*/
    return this._hitTest(
      point,
      HitResult.getOptions(point, options));
  }

  hitTestAll(/* point, options */) {
    var args = arguments,
      point = Point.read(args),
      options = HitResult.getOptions(args),
      all = [];
    this._hitTest(point, new Base({all: all}, options));
    return all;
  }

  _hitTestChildren(point, options, viewMatrix, _exclude) {
    // NOTE: _exclude is only used in Group#_hitTestChildren()
    // to exclude #clipItem
    var children = this._children;
    if (children) {
      // Loop backwards, so items that get drawn last are tested first.
      for (var i = children.length - 1; i >= 0; i--) {
        var child = children[i];
        var res = child !== _exclude && child._hitTest(point, options,
          viewMatrix);
        // Only return the found result if we're not asked to collect
        // all matches through hitTestAll()
        if (res && !options.all)
          return res;
      }
    }
    return null;
  }


  _hitTest(point, options, parentViewMatrix) {
    if (this._locked || !this._visible || this._guide && !options.guides
      || this.isEmpty()) {
      return null;
    }

    // Check if the point is withing roughBounds + tolerance, but only if
    // this item does not have children, since we'd have to travel up the
    // chain already to determine the rough bounds.
    var matrix = this._matrix,
      // Keep the accumulated matrices up to this item in options, so we
      // can keep calculating the correct _tolerancePadding values.
      viewMatrix = new Matrix().appended(matrix),
      // Calculate the transformed padding as 2D size that describes the
      // transformed tolerance circle / ellipse. Make sure it's never 0
      // since we're using it for division (see checkBounds()).
      tolerance = Math.max(options.tolerance, /*#=*/Numerical.EPSILON),
      // Hit-tests are performed in the item's local coordinate space.
      // To calculate the correct 2D padding for tolerance, we therefore
      // need to apply the inverted item matrix.
      tolerancePadding = options._tolerancePadding = new Size(
        Path._getStrokePadding(tolerance,
          matrix._shiftless().invert()));
    // Transform point to local coordinates.
    point = matrix._inverseTransform(point);
    // If the matrix is non-reversible, point will now be `null`:
    if (!point || !this._children &&
      !this.getBounds({internal: true, stroke: true, handle: true})
        .expand(tolerancePadding.multiply(2))._containsPoint(point)) {
      return null;
    }
    /*!(options.guides && !this._guide
          || options.selected && !this.isSelected()
          // Support legacy Item#type property to match hyphenated
          // class-names.
          || options.type && options.type !== Base.hyphenate(this._class)
          || options.class && !(this instanceof options.class))*/
    // See if we should check self (own content), by filtering for type,
    // guides and selected items if that's required.
    var checkSelf = true,
      match = options.match,
      that = this,
      bounds,
      res;

    function filter(hit) {
      if (hit && match && !match(hit))
        hit = null;
      // If we're collecting all matches, add it to options.all
      if (hit && options.all)
        options.all.push(hit);
      return hit;

    }

    function checkPoint(type, part) {
      var pt = part ? bounds['get' + part]() : that.getPosition();
      // Since there are transformations, we cannot simply use a numerical
      // tolerance value. Instead, we divide by a padding size, see above.
      if (point.subtract(pt).divide(tolerancePadding).length <= 1) {
        return new HitResult(type, that, {
          name: part ? Base.hyphenate(part) : type,
          point: pt
        });
      }
    }

    var checkPosition = options.position,
      checkCenter = options.center,
      checkBounds = options.bounds;
    // Ignore top level layers by checking for _parent:
    if (checkSelf && this._parent
      && (checkPosition || checkCenter || checkBounds)) {
      if (checkCenter || checkBounds) {
        // Get the internal, untransformed bounds, as we check against
        // transformed points.
        bounds = this.getInternalBounds();
      }
      res = checkPosition && checkPoint('position') ||
        checkCenter && checkPoint('center', 'Center');
      if (!res && checkBounds) {
        // TODO: Move these into a static property on Rectangle?
        var points = [
          'TopLeft', 'TopRight', 'BottomLeft', 'BottomRight',
          'LeftCenter', 'TopCenter', 'RightCenter', 'BottomCenter'
        ];
        for (var i = 0; i < 8 && !res; i++) {
          res = checkPoint('bounds', points[i]);
        }
      }
      res = filter(res);
    }

    if (!res) {
      res = this._hitTestChildren(point, options, viewMatrix)
        // NOTE: We don't call match on _hitTestChildren() because
        // it is already called internally.
        || checkSelf
        && filter(this._hitTestSelf(point, options, viewMatrix,
          // If the item has a non-scaling stroke, we need to
          // apply the inverted viewMatrix to stroke dimensions.
          /*this.getStrokeScaling() ? null
            : viewMatrix._shiftless().invert()*/null))
        || null;
    }
    // Transform the point back to the outer coordinate system.
    if (res && res.point) {
      res.point = matrix.transform(res.point);
    }
    return res;
  }

  _hitTestSelf(point, options) {
    // The default implementation honly handles 'fill' through #_contains()
    if (options.fill && this.hasFill() && this._contains(point))
      return new HitResult('fill', this);
  }

  matches(name, compare) {
    // matchObject() is used to match against objects in a nested manner.
    // This is useful for matching against Item#data.
    function matchObject(obj1, obj2) {
      for (var i in obj1) {
        if (obj1.hasOwnProperty(i)) {
          var val1 = obj1[i],
            val2 = obj2[i];
          if (Base.isPlainObject(val1) && Base.isPlainObject(val2)) {
            if (!matchObject(val1, val2))
              return false;
          } else if (!Base.equals(val1, val2)) {
            return false;
          }
        }
      }
      return true;
    }

    var type = typeof name;
    if (type === 'object') {
      // `name` is the match object, not a string
      for (var key in name) {
        if (name.hasOwnProperty(key) && !this.matches(key, name[key]))
          return false;
      }
      return true;
    } else if (type === 'function') {
      return name(this);
    } else if (name === 'match') {
      return compare(this);
    } else {
      var value = /^(empty|editable)$/.test(name)
        // Handle boolean test functions separately, by calling them
        // to get the value.
        ? this['is' + Base.capitalize(name)]()
        // Support legacy Item#type property to match hyphenated
        // class-names.
        // TODO: Remove after December 2016.
        : name === 'type'
          ? Base.hyphenate(this._class)
          : this[name];
      if (name === 'class') {
        if (typeof compare === 'function')
          return this instanceof compare;
        // Compare further with the _class property value instead.
        value = this._class;
      }
      if (typeof compare === 'function') {
        return !!compare(value);
      } else if (compare) {
        if (compare.test) { // RegExp-ish
          return compare.test(value);
        } else if (Base.isPlainObject(compare)) {
          return matchObject(compare, value);
        }
      }
      return Base.equals(value, compare);
    }
  }


  getItems(options) {
    return Item._getItems(this, options, this._matrix);
  }


  getItem(options) {
    return Item._getItems(this, options, this._matrix, null, true)[0]
      || null;
  }


  // NOTE: We pass children instead of item as first argument so the
  // method can be used for Project#layers as well in Project.
  static _getItems(item, options, matrix, param, firstOnly) {
    if (!param) {
      // Set up a couple of "side-car" values for the recursive calls
      // of _getItems below, mainly related to the handling of
      // inside / overlapping:
      var obj = typeof options === 'object' && options,
        overlapping = obj && obj.overlapping,
        inside = obj && obj.inside,
        // If overlapping is set, we also perform the inside check:
        bounds = overlapping || inside,
        rect = bounds && Rectangle.read([bounds]);
      param = {
        items: [], // The list to contain the results.
        recursive: obj && obj.recursive !== false,
        inside: !!inside,
        overlapping: !!overlapping,
        rect: rect,
        path: overlapping && new Path.Rectangle({
          rectangle: rect,
          insert: false
        })
      };
      if (obj) {
        // Create a copy of the options object that doesn't contain
        // these special properties:
        options = Base.filter({}, options, {
          recursive: true, inside: true, overlapping: true
        });
      }
    }
    var children = item._children,
      items = param.items,
      rect = param.rect;
    matrix = rect && (matrix || new Matrix());
    for (var i = 0, l = children && children.length; i < l; i++) {
      var child = children[i],
        childMatrix = matrix && matrix.appended(child._matrix),
        add = true;
      if (rect) {
        var bounds = child.getBounds(childMatrix);
        // Regardless of the setting of inside / overlapping, if the
        // bounds don't even intersect, we can skip this child.
        if (!rect.intersects(bounds))
          continue;
        if (!(rect.contains(bounds)
          // First check the bounds, if the rect is fully
          // contained, we are always overlapping, and don't
          // need to perform further checks, otherwise perform
          // a proper #intersects() check:
          || param.overlapping && (bounds.contains(rect)
            || param.path.intersects(child, childMatrix))))
          add = false;
      }
      if (add && child.matches(options)) {
        items.push(child);
        if (firstOnly)
          break;
      }
      if (param.recursive !== false) {
        _getItems(child, options, childMatrix, param, firstOnly);
      }
      if (firstOnly && items.length > 0)
        break;
    }
    return items;
  }

  addChild(item) {
    return this.insertChild(undefined, item);
  }

  /**
   * Inserts the specified item as a child of this item at the specified index
   * in its {@link #children} list. You can use this function for groups,
   * compound paths and layers.
   *
   * @param {Number} index the index at which to insert the item
   * @param {Item} item the item to be inserted as a child
   * @return {Item} the inserted item, or `null` if inserting was not possible
   */
  insertChild(index, item) {
    var res = item ? this.insertChildren(index, [item]) : null;
    return res && res[0];
  }

  /**
   * Adds the specified items as children of this item at the end of the its
   * children list. You can use this function for groups, compound paths and
   * layers.
   *
   * @param {Item[]} items the items to be added as children
   * @return {Item[]} the added items, or `null` if adding was not possible
   */
  addChildren(items) {
    return this.insertChildren(this._children.length, items);
  }

  /**
   * Inserts the specified items as children of this item at the specified
   * index in its {@link #children} list. You can use this function for
   * groups, compound paths and layers.
   *
   * @param {Number} index
   * @param {Item[]} items the items to be appended as children
   * @return {Item[]} the inserted items, or `null` if inserted was not
   *     possible
   */
  insertChildren(index, items) {
    var children = this._children;
    if (children && items && items.length > 0) {
      // We need to clone items because it may be an Item#children array.
      // Also, we're removing elements if they don't match _type.
      // Use Base.slice() because items can be an arguments object.
      items = Base.slice(items);
      // Remove the items from their parents first, since they might be
      // inserted into their own parents, affecting indices.
      // Use the loop also to filter invalid items.
      var inserted = {};
      for (var i = items.length - 1; i >= 0; i--) {
        var item = items[i],
          id = item && item._id;
        // If an item was inserted already, it must be included multiple
        // times in the items array. Only insert once.
        if (!item || inserted[id]) {
          items.splice(i, 1);
        } else {
          // Notify parent of change. Don't notify item itself yet,
          // as we're doing so when adding it to the new owner below.
          item._remove(false, true);
          inserted[id] = true;
        }
      }
      Base.splice(children, items, index, 0);
      /*   var project = this._project,
           // See #_remove() for an explanation of this:
           notifySelf = project._changes;*/
      for (var i = 0, l = items.length; i < l; i++) {
        var item = items[i],
          name = item._name;
        item._parent = this;
        //item._setProject(project, true);
        // Set the name again to make sure all name lookup structures
        // are kept in sync.
        if (name)
          item.name=(name);

        item._changed(Change.INSERTION);
      }
      this._changed(/*#=*/Change.CHILDREN);
    } else {
      items = null;
    }
    return items;
  }

  // Internal alias, so both Project and Item can be used in #copyTo(), and
  // through getParent() in the various Item#insert*() methods.


  /**
   * Private helper method used by {@link #insertAbove(item)} and
   * {@link #insertBelow(item)}, to insert this item in relation to a
   * specified other item.
   *
   * @param {Item} item the item in relation to which which it should be
   *     inserted
   * @param {Number} offset the offset at which the item should be inserted
   * @return {Item} the inserted item, or `null` if inserting was not possible
   */
  _insertAt(item, offset) {
    var owner = item && item.getParent(),
      // Only insert if the item is not the same as `this`, and if it
      // actually has an owner into which we can insert.
      res = item !== this && owner ? this : null;
    if (res) {
      // Notify parent of change. Don't notify item itself yet,
      // as we're doing so when adding it to the new owner below.
      res._remove(false, true);
      owner.insertChild(item._index + offset, res);
    }
    return res;
  }

  /**
   * Inserts this item above the specified item.
   *
   * @param {Item} item the item above which it should be inserted
   * @return {Item} the inserted item, or `null` if inserting was not possible
   */
  insertAbove(item) {
    return this._insertAt(item, 1);
  }

  /**
   * Inserts this item below the specified item.
   *
   * @param {Item} item the item below which it should be inserted
   * @return {Item} the inserted item, or `null` if inserting was not possible
   */
  insertBelow(item) {
    return this._insertAt(item, 0);
  }

  /**
   * Sends this item to the back of all other items within the same parent.
   */
  sendToBack() {
    var owner = this.getParent();
    return owner ? owner.insertChild(0, this) : null;
  }

  /**
   * Brings this item to the front of all other items within the same parent.
   */
  bringToFront() {
    var owner = this.getParent();
    return owner ? owner.insertChild(undefined, this) : null;
  }


  /**
   * Inserts the specified item as a child of this item by appending it to
   * the list of children and moving it below all other children. You can
   * use this function for groups, compound paths and layers.
   *
   * @param {Item} item the item to be appended as a child
   * @deprecated use {@link #insertChild(index, item)} instead.
   */
  appendBottom(item) {
    return this.insertChild(0, item);
  }


  addTo(owner) {
    return owner.insertChild(undefined, this);
  }

  /**
   * Clones the item and adds it to the specified owner, which can be either
   * a {@link Item} or a {@link Project}.
   *
   * @param {Project|Layer|Group|CompoundPath} owner the item or project to
   * copy the item to
   * @return {Item} the new copy of the item, if it was successfully added
   * @chainable
   */
  copyTo(owner) {
    return this.clone(false).addTo(owner);
  }

  /**
   * If this is a group, layer or compound-path with only one child-item,
   * the child-item is moved outside and the parent is erased. Otherwise, the
   * item itself is returned unmodified.
   *
   * @return {Item} the reduced item
   */
  reduce(options) {
    var children = this._children;
    if (children && children.length === 1) {
      var child = children[0].reduce(options);
      // Make sure the reduced item has the same parent as the original.
      if (this._parent) {
        child.insertAbove(this);
        this.remove();
      } else {
        child.remove();
      }
      return child;
    }
    return this;
  }

  /**
   * Removes the item from its parent's named children list.
   */
  _removeNamed() {
    var owner = this.getParent();
    if (owner) {
      var children = owner._children,
        namedChildren = owner._namedChildren,
        name = this._name,
        namedArray = namedChildren[name],
        index = namedArray ? namedArray.indexOf(this) : -1;
      if (index !== -1) {
        // Remove the named reference
        if (children[name] == this)
          delete children[name];
        // Remove this entry
        namedArray.splice(index, 1);
        // If there are any items left in the named array, set the first
        // of them to be children[this.name]
        if (namedArray.length) {
          children[name] = namedArray[0];
        } else {
          // Otherwise delete the empty array
          delete namedChildren[name];
        }
      }
    }
  }

  /**
   * Removes the item from its parent's children list.
   */
  _remove(notifySelf, notifyParent) {
    var owner = this.getParent(),
      project = this._project,
      index = this._index;
    /*    if (this._style)
          this._style._dispose();*/
    if (owner) {
      // Handle named children separately from index:
      if (this._name)
        this._removeNamed();
      // Handle index separately from owner: There are situations where
      // the item is already removed from its list through Base.splice()
      // and index set to undefined, but the owner is still set,
      // e.g. in #removeChildren():
      if (index != null) {
        // Only required for layers but not enough to merit an override.
        /*  if (project._activeLayer === this)
            project._activeLayer = this.getNextSibling()
              || this.getPreviousSibling();*/
        Base.splice(owner._children, null, index, 1);
      }
      this._installEvents(false);
      // Notify self of the insertion change. We only need this
      // notification if we're tracking changes for now.
      /*  if (notifySelf && project._changes)
          this._changed(/!*#=*!/Change.INSERTION);*/
      // Notify owner of changed children (this can be the project too).
      if (notifyParent)
        owner._changed(/*#=*/Change.CHILDREN, this);
      this._parent = null;
      return true;
    }
    return false;
  }

  /**
   * Removes the item and all its children from the project. The item is not
   * destroyed and can be inserted again after removal.
   *
   * @return {Boolean} {@true if the item was removed}
   */
  remove() {
    // Notify self and parent of change:
    return this._remove(true, true);
  }


  replaceWith(item) {
    var ok = item && item.insertBelow(this);
    if (ok)
      this.remove();
    return ok;
  }


  removeChildren(start, end) {
    if (!this._children)
      return null;
    start = start || 0;
    end = Base.pick(end, this._children.length);
    // Use Base.splice(), which adjusts #_index for the items above, and
    // deletes it for the removed items. Calling #_remove() afterwards is
    // fine, since it only calls Base.splice() if #_index is set.
    var removed = Base.splice(this._children, null, start, end - start);
    for (var i = removed.length - 1; i >= 0; i--) {
      // Don't notify parent each time, notify it separately after.
      removed[i]._remove(true, false);
    }
    if (removed.length > 0)
      this._changed(/*#=*/Change.CHILDREN);
    return removed;
  }


  /**
   * Reverses the order of the item's children
   */
  reverseChildren() {
    if (this._children) {
      this._children.reverse();
      // Adjust indices
      for (var i = 0, l = this._children.length; i < l; i++)
        this._children[i]._index = i;
      this._changed(/*#=*/Change.CHILDREN);
    }
  }

  /**
   * {@grouptitle Tests}
   * Specifies whether the item has any content or not. The meaning of what
   * content is differs from type to type. For example, a {@link Group} with
   * no children, a {@link TextItem} with no text content and a {@link Path}
   * with no segments all are considered empty.
   *
   * @param {Boolean} [recursively=false] whether an item with children should be
   * considered empty if all its descendants are empty
   * @return {Boolean}
   */
  isEmpty(recursively) {
    var children = this._children;
    var numChildren = children ? children.length : 0;
    if (recursively) {
      // In recursive check, item is empty if all its children are empty.
      for (var i = 0; i < numChildren; i++) {
        if (!children[i].isEmpty(recursively)) {
          return false;
        }
      }
      return true;
    }
    return !numChildren;
  }

  /**
   * Checks whether the item is editable.
   *
   * @return {Boolean} {@true when neither the item, nor its parents are
     * locked or hidden}
   * @ignore
   */
  // TODO: Item#isEditable is currently ignored in the documentation, as
  // locking an item currently has no effect
  isEditable() {
    var item = this;
    while (item) {
      if (!item._visible || item._locked)
        return false;
      item = item._parent;
    }
    return true;
  }

  /**
   * Checks whether the item is valid, i.e. it hasn't been removed.
   *
   * @return {Boolean} {@true if the item is valid}
   */

  // TODO: isValid / checkValid

  /**
   * {@grouptitle Style Tests}
   *
   * Checks whether the item has a fill.
   *
   * @return {Boolean} {@true if the item has a fill}
   */
  hasFill() {
    return this.style().hasFill();
  }

  /**
   * Checks whether the item has a stroke.
   *
   * @return {Boolean} {@true if the item has a stroke}
   */
  hasStroke() {
    return this.style().hasStroke();
  }

  /**
   * Checks whether the item has a shadow.
   *
   * @return {Boolean} {@true if the item has a shadow}
   */
  hasShadow() {
    return this.style().hasShadow();
  }

  /**
   * Returns -1 if 'this' is above 'item', 1 if below, 0 if their order is not
   * defined in such a way, e.g. if one is a descendant of the other.
   */
  _getOrder(item) {
    // Private method that produces a list of ancestors, starting with the
    // root and ending with the actual element as the last entry.
    function getList(item) {
      var list = [];
      do {
        list.unshift(item);
      } while (item = item._parent);
      return list;
    }

    var list1 = getList(this),
      list2 = getList(item);
    for (var i = 0, l = Math.min(list1.length, list2.length); i < l; i++) {
      if (list1[i] != list2[i]) {
        // Found the position in the parents list where the two start
        // to differ. Look at who's above who.
        return list1[i]._index < list2[i]._index ? 1 : -1;
      }
    }
    return 0;
  }

  /**
   * {@grouptitle Hierarchy Tests}
   *
   * Checks if the item contains any children items.
   *
   * @return {Boolean} {@true it has one or more children}
   */
  hasChildren() {
    return this._children && this._children.length > 0;
  }

  /**
   * Checks whether the item and all its parents are inserted into scene graph
   * or not.
   *
   * @return {Boolean} {@true if the item is inserted into the scene graph}
   */
  isInserted() {
    return this._parent ? this._parent.isInserted() : false;
  }

  /**
   * Checks if this item is above the specified item in the stacking order
   * of the project.
   *
   * @param {Item} item the item to check against
   * @return {Boolean} {@true if it is above the specified item}
   */
  isAbove(item) {
    return this._getOrder(item) === -1;
  }

  /**
   * Checks if the item is below the specified item in the stacking order of
   * the project.
   *
   * @param {Item} item the item to check against
   * @return {Boolean} {@true if it is below the specified item}
   */
  isBelow(item) {
    return this._getOrder(item) === 1;
  }

  /**
   * Checks whether the specified item is the parent of the item.
   *
   * @param {Item} item the item to check against
   * @return {Boolean} {@true if it is the parent of the item}
   */
  isParent(item) {
    return this._parent === item;
  }

  /**
   * Checks whether the specified item is a child of the item.
   *
   * @param {Item} item the item to check against
   * @return {Boolean} {@true it is a child of the item}
   */
  isChild(item) {
    return item && item._parent === this;
  }

  /**
   * Checks if the item is contained within the specified item.
   *
   * @param {Item} item the item to check against
   * @return {Boolean} {@true if it is inside the specified item}
   */
  isDescendant(item) {
    var parent = this;
    while (parent = parent._parent) {
      if (parent === item)
        return true;
    }
    return false;
  }

  /**
   * Checks if the item is an ancestor of the specified item.
   *
   * @param {Item} item the item to check against
   * @return {Boolean} {@true if the item is an ancestor of the specified
     * item}
   */
  isAncestor(item) {
    return item ? item.isDescendant(this) : false;
  }

  /**
   * Checks if the item is an a sibling of the specified item.
   *
   * @param {Item} item the item to check against
   * @return {Boolean} {@true if the item is aa sibling of the specified item}
   */
  isSibling(item) {
    return this._parent === item._parent;
  }

  /**
   * Checks whether the item is grouped with the specified item.
   *
   * @param {Item} item
   * @return {Boolean} {@true if the items are grouped together}
   */
  isGroupedWith(item) {
    var parent = this._parent;
    while (parent) {
      // Find group parents. Check for parent._parent, since don't want
      // top level layers, because they also inherit from Group
      if (parent._parent
        && /^(Group|Layer|CompoundPath)$/.test(parent._class)
        && item.isDescendant(parent))
        return true;
      // Keep walking up otherwise
      parent = parent._parent;
    }
    return false;
  }

  rotate( value, center ) {



    center = center ? new Point(center) : null;
    let m = new Matrix();
    m = m.rotate(value, center || this.getPosition(true))
    return this.transform(m);

    /*return this.transform(new Matrix().rotate(value,
      center || this.getPosition(true)));*/
  }

  scale( value, center ) {
    var args = arguments,
      value = new Point(value),
      center = center ? new Point(center) : null;

    let m = new Matrix();

    m = m.scale(value, center || this.getPosition(true))
    return this.transform(m);
  }

  shear( value, center) {
    var args = arguments,
      value = new Point(value),
      center = center ? new Point(center) : null;
    return this.transform(new Matrix().shear(value,
      center || this.getPosition(true)));
  }

  skew(/* value, center */) {
    var args = arguments,
      value = (Point).read(args),
      center = Point.read(args, 0, {readNull: true});
    const m = new Matrix().skew(value, center || this.getPosition(true))
    return this.transform(m);
  }

  /**
   * {@grouptitle Transform Functions}
   *
   * Translates (moves) the item by the given offset views.
   *
   * @param {Point} delta the offset to translate the item by
   */
  translate(/* delta */) {
    var mx = new Matrix();
    return this.transform(mx.translate.apply(mx, arguments));
  }


  // TODO: Implement flags:
  // @param {String[]} flags array of any of the following: 'objects',
  //        'children', 'fill-gradients', 'fill-patterns', 'stroke-patterns',
  //        'lines'. Default: ['objects', 'children']
  transform(matrix, _applyRecursively, _setApplyMatrix) {
    var _matrix = this._matrix,
      transformMatrix = matrix && !matrix.isIdentity(),
      // If no matrix is provided, or the matrix is the identity, we might
      // still have some work to do: _setApplyMatrix or _applyRecursively.
      applyMatrix = (
        _setApplyMatrix && this._canApplyMatrix ||
        this._applyMatrix && (
          // Don't apply _matrix if the result of concatenating with
          // matrix would be identity.
          transformMatrix || !_matrix.isIdentity() ||
          // Even if it's an identity matrix, we may still need to
          // recursively apply the matrix to children.
          _applyRecursively && this._children
        )
      );
    // Bail out if there is nothing to do.
    if (!transformMatrix && !applyMatrix)
      return this;
    // Simply prepend the internal matrix with the passed one:
    if (transformMatrix) {
      // Keep a backup of the last valid state before the matrix becomes
      // non-invertible. This is then used again in setBounds to restore.
      if (!matrix.isInvertible() && _matrix.isInvertible())
        _matrix._backup = _matrix.getValues();
      // Pass `true` for _dontNotify, as we're handling this after.
      _matrix.prepend(matrix, true);
      // When a new matrix was applied, we also need to transform gradient
      // color points. These always need transforming, regardless of
      // #applyMatrix, as they are defined in the parent's coordinate
      // system.
      // TODO: Introduce options to control whether fills should be
      // transformed or not.
      var style = this._style
      // Pass true for _dontMerge so we don't recursively transform
      // styles on groups' children.
      /*fillColor = style.getFillColor(true),
      strokeColor = style.getStrokeColor(true);
    if (fillColor)
      fillColor.transform(matrix);
    if (strokeColor)
      strokeColor.transform(matrix);*/
    }
    // Call #_transformContent() now, if we need to directly apply the
    // internal _matrix transformations to the item's content.
    // Application is not possible on Raster, PointText, SymbolItem, since
    // the matrix is where the actual transformation state is stored.

    if (applyMatrix && (applyMatrix = this._transformContent(
      _matrix, _applyRecursively, _setApplyMatrix))) {
      // Pivot is provided in the parent's coordinate system, so transform
      // it along too.
      var pivot = this._pivot;
      if (pivot)
        _matrix._transformPoint(pivot, pivot, true);
      // Reset the internal matrix to the identity transformation if
      // it was possible to apply it, but do not notify owner of change.
      _matrix.reset(true);
      // Set the internal _applyMatrix flag to true if we're told to
      // do so
      if (_setApplyMatrix && this._canApplyMatrix)
        this._applyMatrix = true;
    }
    // Calling _changed will clear _bounds and _position, but depending
    // on matrix we can calculate and set them again, so preserve them.
    var bounds = this._bounds,
      position = this._position;
    if (transformMatrix || applyMatrix) {
      this._changed(/*#=*/Change.MATRIX);
    }
    // Detect matrices that contain only translations and scaling
    // and transform the cached _bounds and _position without having to
    // fully recalculate each time.
    var decomp = transformMatrix && bounds && matrix.decompose();
    if (decomp && decomp.skewing.isZero() && decomp.rotation % 90 === 0) {
      // Transform the old bound by looping through all the cached
      // bounds in _bounds and transform each.
      for (var key in bounds) {
        var cache = bounds[key];
        // If any item involved in the determination of these bounds has
        // non-scaling strokes, delete the cache now as it can't be
        // preserved through the transformation.
        if (cache.nonscaling) {
          delete bounds[key];
        } else if (applyMatrix || !cache.internal) {
          // If these are internal bounds, only transform them if this
          // item applied its matrix.
          var rect = cache.rect;
          matrix._transformBounds(rect, rect);
        }
      }
      this._bounds = bounds;
      // If we have cached bounds, try to determine _position as its
      // center. Use _boundsOptions do get the cached default bounds.
      var cached = bounds[this._getBoundsCacheKey(
        this._boundsOptions || {})];
      if (cached) {
        // use this method to handle pivot case (see #1503)
        this._position = this._getPositionFromBounds(cached.rect);
      }
    } else if (transformMatrix && position && this._pivot) {
      // If the item has a pivot defined, it means that the default
      // position defined as the center of the bounds won't shift with
      // arbitrary transformations and we can therefore update _position:
      this._position = matrix._transformPoint(position, position);
    }
    // Allow chaining here, since transform() is related to Matrix functions
    return this;
  }

  _transformContent(matrix, applyRecursively, setApplyMatrix) {
    var children = this._children;
    if (children) {
      for (var i = 0, l = children.length; i < l; i++) {
        children[i].transform(matrix, applyRecursively, setApplyMatrix);
      }
      return true;
    }
  }


  globalToLocal(point) {
    return this.getGlobalMatrix(true)._inverseTransform(
      point/*Point.read(arguments)*/);
  }


  localToGlobal(point) {
    return this.getGlobalMatrix(true)._transformPoint(
      point/*Point.read(arguments)*/);
  }

  /**
   * Converts the specified point from the parent's coordinate space to
   * item's own local coordinate space.
   *
   * @param {Point} point the point to be transformed
   * @return {Point} the transformed point as a new instance
   */
  parentToLocal(point) {
    return this._matrix._inverseTransform(point/*Point.read(arguments)*/);
  }

  /**
   * Converts the specified point from the item's own local coordinate space
   * to the parent's coordinate space.
   *
   * @param {Point} point the point to be transformed
   * @return {Point} the transformed point as a new instance
   */
  localToParent(point) {
    return this._matrix._transformPoint(point/*Point.read(arguments)*/);
  }


  fitBounds(rectangle, fill) {
    // TODO: Think about passing options with various ways of defining
    // fitting. Compare with InDesign fitting to see possible options.
    rectangle = Rectangle.read(arguments);
    var bounds = this.getBounds(),
      itemRatio = bounds.height / bounds.width,
      rectRatio = rectangle.height / rectangle.width,
      scale = (fill ? itemRatio > rectRatio : itemRatio < rectRatio)
        ? rectangle.width / bounds.width
        : rectangle.height / bounds.height,
      newBounds = new Rectangle(new Point(),
        new Size(bounds.width * scale, bounds.height * scale));
    newBounds.setCenter(rectangle.getCenter());
    this.setBounds(newBounds);
  }

  _setStyles(ctx, param, viewMatrix) {
    // We can access internal properties since we're only using this on
    // items without children, where styles would be merged.
    var style = this._style,
      matrix = this._matrix;
    /*    if (style.hasFill()) {
          ctx.fillStyle = style.getFillColor().toCanvasStyle(ctx, matrix);
        }
        if (style.hasStroke()) {
          ctx.strokeStyle = style.getStrokeColor().toCanvasStyle(ctx, matrix);
          ctx.lineWidth = style.getStrokeWidth();
          var strokeJoin = style.getStrokeJoin(),
            strokeCap = style.getStrokeCap(),
            miterLimit = style.getMiterLimit();
          if (strokeJoin)
            ctx.lineJoin = strokeJoin;
          if (strokeCap)
            ctx.lineCap = strokeCap;
          if (miterLimit)
            ctx.miterLimit = miterLimit;
          if (paper.support.nativeDash) {
            var dashArray = style.getDashArray(),
              dashOffset = style.getDashOffset();
            if (dashArray && dashArray.length) {
              if ('setLineDash' in ctx) {
                ctx.setLineDash(dashArray);
                ctx.lineDashOffset = dashOffset;
              } else {
                ctx.mozDash = dashArray;
                ctx.mozDashOffset = dashOffset;
              }
            }
          }
        }
        if (style.hasShadow()) {
          // In Canvas, shadows unfortunately ignore all transformations
          // completely. As almost no browser supports ctx.currentTransform,
          // we need to calculate our own here, and then use it to transform
          // the shadow-blur and offset accordingly.
          var pixelRatio = param.pixelRatio || 1,
            mx = viewMatrix._shiftless().prepend(
              new Matrix().scale(pixelRatio, pixelRatio)),
            // Transform the blur value as a vector and use its new length:
            blur = mx.transform(new Point(style.getShadowBlur(), 0)),
            offset = mx.transform(this.getShadowOffset());
          ctx.shadowColor = style.getShadowColor().toCanvasStyle(ctx);
          ctx.shadowBlur = blur.getLength();
          ctx.shadowOffsetX = offset.x;
          ctx.shadowOffsetY = offset.y;
        }*/
  }


  /**
   * Checks the _updateVersion of the item to see if it got drawn in the draw
   * loop. If the version is out of sync, the item is either not in the scene
   * graph anymore or is invisible.
   */
  _isUpdated(updateVersion) {
    var parent = this._parent;
    // For compound-paths, use the _updateVersion of the parent, because the
    // shape gets drawn at once at might get cached (e.g. Path2D soon).
    if (parent instanceof CompoundPath)
      return parent._isUpdated(updateVersion);
    // In case a parent is visible but isn't drawn (e.g. opacity == 0), the
    // _updateVersion of all its children will not be updated, but the
    // children should still be considered updated, and selections should be
    // drawn for them. Excluded are only items with _visible == false:
    var updated = this._updateVersion === updateVersion;
    if (!updated && parent && parent._visible
      && parent._isUpdated(updateVersion)) {
      this._updateVersion = updateVersion;
      updated = true;
    }
    return updated;
  }


}

export class PathItem extends Item {


  constructor() {
    super(...arguments)

    // Set up lookup tables for each operator, to decide if a given segment
    // is to be considered a part of the solution, or to be discarded, based
    // on its winding contribution, as calculated by propagateWinding().
    // Boolean operators return true if a segment with the given winding
    // contribution contributes to the final result or not. They are applied
    // to for each segment after the paths are split at crossings.
    this.operators = {
      unite: {'1': true, '2': true},
      intersect: {'2': true},
      subtract: {'1': true},
      // exclude only needs -1 to support reorientPaths() when there are
      // no crossings. The actual boolean code uses unsigned winding.
      exclude: {'1': true, '-1': true}
    }
    // Do nothing.
  }


  static create(arg) {
    let data,
      segments,
      compound;


    if (Base.isPlainObject(arg)) {
      segments = arg.segments;
      data = arg.pathData;
    } else if (Array.isArray(arg)) {
      segments = arg;
    } else if (typeof arg === 'string') {
      data = arg;
    }
    if (segments) {
      const first = segments[0];
      compound = first && (Array.isArray(first[0]) ||  typeof first[0] === 'object' && first[0].point ||  typeof first === 'object' && first.points && Array.isArray(first.points));
    } else if (data) {
      // If there are multiple moveTo commands or a closePath command
      // followed by other commands, we have a CompoundPath.
      compound = (data.match(/m/gi) || []).length > 1
        || /z\s*\S+/i.test(data);
    }

    const ctor = compound ? CompoundPath : Path;
    return new ctor(arg);
  }


  _asPathItem() {
    // See Item#_asPathItem()
    return this;
  }


  isClockwise() {
    return this.getArea() >= 0;
  }

  setClockwise(clockwise) {
    // Only revers the path if its clockwise orientation is not the same
    // as what it is now demanded to be.
    // On-the-fly conversion to boolean:
    if (this.isClockwise() != (clockwise = !!clockwise))
      this.reverse();
  }


  setPathData(data) {
    // NOTE: #getPathData() is defined in CompoundPath / Path
    // This is a very compact SVG Path Data parser that works both for Path
    // and CompoundPath.

    // First split the path data into parts of command-coordinates pairs
    // Commands are any of these characters: mzlhvcsqta
    const parts = data && data.match(/[mlhvcsqtaz][^mlhvcsqtaz]*/ig);
    let coords,
      relative = false,
      previous,
      control,
      current = new Point(),
      start = new Point();

    function getCoord(index, coord) {
      let val = +coords[index];
      if (relative)
        val += current[coord];
      return val;
    }

    function getPoint(index) {
      return new Point(
        getCoord(index, 'x'),
        getCoord(index + 1, 'y')
      );
    }

    // First clear the previous content
    this.removeChildren();

    let i = 0;
    const l = parts && parts.length;
    for (; i < l; i++) {
      const part = parts[i],
        command = part[0],
        lower = command.toLowerCase();
      // Match all coordinate values
      coords = part.match(/[+-]?(?:\d*\.\d+|\d+\.?)(?:[eE][+-]?\d+)?/g);
      const length = coords && coords.length;
      relative = command === lower;
      // Fix issues with z in the middle of SVG path data, not followed by
      // a m command, see #413:
      if (previous === 'z' && !/[mz]/.test(lower))
        this.moveTo(current);
      switch (lower) {
        case 'm':
        case 'l':
          let move = lower === 'm';
          for (var j = 0; j < length; j += 2) {
            this[move ? 'moveTo' : 'lineTo'](current = getPoint(j));
            if (move) {
              start = current;
              move = false;
            }
          }
          control = current;
          break;
        case 'h':
        case 'v':
          var coord = lower === 'h' ? 'x' : 'y';
          current = current.clone(); // Clone as we're going to modify it.
          for (var j = 0; j < length; j++) {
            current[coord] = getCoord(j, coord);
            this.lineTo(current);
          }
          control = current;
          break;
        case 'c':
          for (var j = 0; j < length; j += 6) {
            this.cubicCurveTo(
              getPoint(j),
              control = getPoint(j + 2),
              current = getPoint(j + 4));
          }
          break;
        case 's':
          // Smooth cubicCurveTo
          for (var j = 0; j < length; j += 4) {
            this.cubicCurveTo(
              /[cs]/.test(previous)
                ? current.multiply(2).subtract(control)
                : current,
              control = getPoint(j),
              current = getPoint(j + 2));
            previous = lower;
          }
          break;
        case 'q':
          for (var j = 0; j < length; j += 4) {
            this.quadraticCurveTo(
              control = getPoint(j),
              current = getPoint(j + 2));
          }
          break;
        case 't':
          // Smooth quadraticCurveTo
          for (var j = 0; j < length; j += 2) {
            this.quadraticCurveTo(
              control = (/[qt]/.test(previous)
                ? current.multiply(2).subtract(control)
                : current),
              current = getPoint(j));
            previous = lower;
          }
          break;
        case 'a':
          for (var j = 0; j < length; j += 7) {
            this.arcTo(current = getPoint(j + 5),
              new Size(+coords[j], +coords[j + 1]),
              +coords[j + 2], +coords[j + 4], +coords[j + 3]);
          }
          break;
        case 'z':
          // Merge first and last segment with Numerical.EPSILON tolerance
          // to address imprecisions in relative SVG data.
          this.closePath(/*#=*/Numerical.EPSILON);
          // Correctly handle relative m commands, see #1101:
          current = start;
          break;
      }
      previous = lower;
    }
  }

  _canComposite() {
    // A path with only a fill or a stroke can be directly blended, but if
    // it has both, it needs to be drawn into a separate canvas first.
    return !(this.hasFill() && this.hasStroke());
  }

  _contains(point) {
    // NOTE: point is reverse transformed by _matrix, so we don't need to
    // apply the matrix here.
    /*#*/
    /*if (__options.nativeContains || !__options.booleanOperations) {
      /!*!// To compare with native canvas approach:
      const ctx = CanvasProvider.getContext(1, 1);
      // Use dontFinish to tell _draw to only produce geometries for hit-test.
      this._draw(ctx, new Base({dontFinish: true}));
      const res = ctx.isPointInPath(point.x, point.y, this.getFillRule());
      CanvasProvider.release(ctx);
      return res;*!/
      /!*#*!/
    } else*/
    { // !__options.nativeContains && __options.booleanOperations
      // Check the transformed point against the untransformed (internal)
      // handle bounds, which is the fastest rough bounding box to calculate
      // for a quick check before calculating the actual winding.
      const winding = point.isInside(
        this.getBounds({internal: true, handle: true}))
        ? this._getWinding(point)
        : {};
      // See #1116#issuecomment-243794824 for an explanation of the
      // winding.onPath check here.
      return winding.onPath || !!(winding.winding);
      /*#*/
    } // !__options.nativeContains && __options.booleanOperations
  }

  getIntersections(path, include, _matrix, _returnFirst) {
    // NOTE: For self-intersection, path is null. This means you can also
    // just call path.getIntersections() without an argument to get self
    // intersections.
    // NOTE: The hidden argument _matrix is used internally to override the
    // passed path's transformation matrix.
    const self = this === path || !path, // self-intersections?
      matrix1 = this._matrix._orNullIfIdentity(),
      matrix2 = self ? matrix1
        : (_matrix || path._matrix)._orNullIfIdentity();
    // First check the bounds of the two paths. If they don't intersect,
    // we don't need to iterate through their curves.
    return self || this.getBounds(matrix1).intersects(
      path.getBounds(matrix2), /*#=*/Numerical.EPSILON)
      ? Curve.getIntersections(
        this.getCurves(), !self && path.getCurves(), include,
        matrix1, matrix2, _returnFirst)
      : [];
  }

  /**
   * Returns all crossings between two {@link PathItem} items as an array of
   * {@link CurveLocation} objects. {@link CompoundPath} items are also
   * supported. Crossings are intersections where the paths actually are
   * crossing each other, as opposed to simply touching.
   *
   * @param {PathItem} path the other item to find the crossings with
   * @return {CurveLocation[]} the locations of all crossings between the
   *     paths
   * @see #getIntersections(path)
   */
  getCrossings(path) {
    return this.getIntersections(path, function (inter) {
      return inter.isCrossing();
    });
  }

  /**
   * Returns the nearest location on the path item to the specified point.
   *
   * @param {Point} point the point for which we search the nearest location
   * @return {CurveLocation} the location on the path that's the closest to
   * the specified point
   */
  getNearestLocation(point) {
    const /*point = Point.read(arguments),*/
      curves = this.getCurves();
    let minDist = Infinity,
      minLoc = null;
    let i = 0;
    const l = curves.length;
    for (; i < l; i++) {
      const loc = curves[i].getNearestLocation(point);
      if (loc._distance < minDist) {
        minDist = loc._distance;
        minLoc = loc;
      }
    }
    return minLoc;
  }

  getNearestPoint(/* point */) {
    const loc = this.getNearestLocation.apply(this, arguments);
    return loc ? loc.getPoint() : loc;
  }


  interpolate(from, to, factor) {
    const isPath = !this._children,
      name = isPath ? '_segments' : '_children',
      itemsFrom = from[name],
      itemsTo = to[name],
      items = this[name];
    if (!itemsFrom || !itemsTo || itemsFrom.length !== itemsTo.length) {
      throw new Error('Invalid operands in interpolate() call: ' +
        from + ', ' + to);
    }
    const current = items.length,
      length = itemsTo.length;
    if (current < length) {
      const ctor = isPath ? Segment : Path;
      for (var i = current; i < length; i++) {
        this.add(new ctor());
      }
    } else if (current > length) {
      this[isPath ? 'removeSegments' : 'removeChildren'](length, current);
    }
    for (var i = 0; i < length; i++) {
      items[i].interpolate(itemsFrom[i], itemsTo[i], factor);
    }
    if (isPath) {
      this.setClosed(from._closed);
      this._changed(/*#=*/Change.GEOMETRY);
    }
  }


  compare(path) {
    let ok = false;
    if (path) {
      const paths1 = this._children || [this],
        paths2 = path._children ? path._children.slice() : [path],
        length1 = paths1.length,
        length2 = paths2.length,
        matched = [];
      let count = 0;
      ok = true;
      const boundsOverlaps = CollisionDetection.findItemBoundsCollisions(paths1, paths2, Numerical.GEOMETRIC_EPSILON);
      for (let i1 = length1 - 1; i1 >= 0 && ok; i1--) {
        const path1 = paths1[i1];
        ok = false;
        const pathBoundsOverlaps = boundsOverlaps[i1];
        if (pathBoundsOverlaps) {
          for (let i2 = pathBoundsOverlaps.length - 1; i2 >= 0 && !ok; i2--) {
            if (path1.compare(paths2[pathBoundsOverlaps[i2]])) {
              if (!matched[pathBoundsOverlaps[i2]]) {
                matched[pathBoundsOverlaps[i2]] = true;
                count++;
              }
              ok = true;
            }
          }
        }
      }
      // Each path in path2 needs to be matched at least once.
      ok = ok && count === length2;
    }
    return ok;
  }

  getPaths(path) {
    return path._children || [path];
  }

  /*
   * Creates a clone of the path that we can modify freely, with its matrix
   * applied to its geometry. Calls #reduce() to simplify compound paths and
   * remove empty curves, #resolveCrossings() to resolve self-intersection
   * make sure all paths have correct winding direction.
   */
  preparePath(path, resolve) {
    let res = path
      .clone(false)
      .reduce({simplify: true})
      .transform(null, true, true);
    if (resolve) {
      // For correct results, close open paths with straight lines:
      const paths = this.getPaths(res);
      let i = 0;
      const l = paths.length;
      for (; i < l; i++) {
        var path = paths[i];
        if (!path._closed && !path.isEmpty()) {
          // Close with epsilon tolerance, to avoid tiny straight
          // that would cause issues with intersection detection.
          path.closePath(/*#=*/Numerical.EPSILON);
          path.getFirstSegment().setHandleIn(0, 0);
          path.getLastSegment().setHandleOut(0, 0);
        }
      }
      res = res
        .resolveCrossings()
        .reorient(true/*res.getFillRule() === 'nonzero'*/, true);
    }
    return res;
  }

  createResult(paths, simplify, path1, path2, options) {
    let result = new CompoundPath(Item.NO_INSERT);
    result.addChildren(paths, true);
    // See if the item can be reduced to just a simple Path.
    result = result.reduce({simplify: simplify});
    if (!(options && options.insert == false)) {
      // Insert the resulting path above whichever of the two paths appear
      // further up in the stack.
      result.insertAbove(path2 && path1.isSibling(path2)
      && path1.getIndex() < path2.getIndex() ? path2 : path1);
    }
    // Copy over the input path attributes, excluding matrix and we're done.
    result.copyAttributes(path1, true);
    return result;
  }

  static filterIntersection(inter) {
    // TODO: Change isCrossing() to also handle overlaps (hasOverlap())
    // that are actually involved in a crossing! For this we need proper
    // overlap range detection / merging first... But as we call
    // #resolveCrossings() first in boolean operations, removing all
    // self-touching areas in paths, this works for the known use cases.
    // The ideal implementation would deal with it in a way outlined in:
    // https://github.com/paperjs/paper.js/issues/874#issuecomment-168332391
    return inter.hasOverlap() || inter.isCrossing();
  }

  traceBoolean(path1, path2, operation, options) {
    // Only support subtract and intersect operations when computing stroke
    // based boolean operations (options.split = true).

    if (options && (options.trace == false || options.stroke) &&
      /^(subtract|intersect)$/.test(operation))
      return this.splitBoolean(path1, path2, operation);
    // We do not modify the operands themselves, but create copies instead,
    // fas produced by the calls to preparePath().
    // NOTE: The result paths might not belong to the same type i.e.
    // subtract(A:Path, B:Path):CompoundPath etc.
    const _path1 = this.preparePath(path1, true),
      _path2 = path2 && path1 !== path2 && this.preparePath(path2, true),
      // Retrieve the operator lookup table for winding numbers.
      operator = this.operators[operation];
    // Add a simple boolean property to check for a given operation,
    // e.g. `if (operator.unite)`
    operator[operation] = true;
    // Give both paths the same orientation except for subtraction
    // and exclusion, where we need them at opposite orientation.
    if (_path2 && (operator.subtract || operator.exclude)
      ^ (_path2.isClockwise() ^ _path1.isClockwise()))
      _path2.reverse();
    // Split curves at crossings on both paths. Note that for self-
    // intersection, path2 is null and getIntersections() handles it.
    const crossings = this.divideLocations(CurveLocation.expand(
      _path1.getIntersections(_path2, PathItem.filterIntersection))),
      paths1 = this.getPaths(_path1),
      paths2 = _path2 && this.getPaths(_path2),
      segments = [],
      curves = [];
    let paths;

    function collectPaths(paths) {
      let i = 0;
      const l = paths.length;
      for (; i < l; i++) {
        const path = paths[i];
        Base.push(segments, path._segments);
        Base.push(curves, path.getCurves());
        // See if all encountered segments in a path are overlaps, to
        // be able to separately handle fully overlapping paths.
        path._overlapsOnly = true;
      }
    }

    function getCurves(indices) {
      const list = [];
      let i = 0;
      const l = indices && indices.length;
      for (; i < l; i++) {
        list.push(curves[indices[i]]);
      }
      return list;
    }

    if (crossings.length) {
      // Collect all segments and curves of both involved operands.
      collectPaths(paths1);
      if (paths2)
        collectPaths(paths2);

      const curvesValues = new Array(curves.length);
      for (var i = 0, l = curves.length; i < l; i++) {
        curvesValues[i] = curves[i].getValues();
      }
      const curveCollisions = CollisionDetection.findCurveBoundsCollisions(
        curvesValues, curvesValues, 0, true);
      const curveCollisionsMap = {};
      for (var i = 0; i < curves.length; i++) {
        const curve = curves[i],
          id = curve._path._id,
          map = curveCollisionsMap[id] = curveCollisionsMap[id] || {};
        map[curve.getIndex()] = {
          hor: getCurves(curveCollisions[i].hor),
          ver: getCurves(curveCollisions[i].ver)
        };
      }

      // Propagate the winding contribution. Winding contribution of
      // curves does not change between two crossings.
      // First, propagate winding contributions for curve chains starting
      // in all crossings:
      for (var i = 0, l = crossings.length; i < l; i++) {
        this.propagateWinding(crossings[i]._segment, _path1, _path2,
          curveCollisionsMap, operator);
      }
      for (var i = 0, l = segments.length; i < l; i++) {
        const segment = segments[i],
          inter = segment._intersection;
        if (!segment._winding) {
          this.propagateWinding(segment, _path1, _path2,
            curveCollisionsMap, operator);
        }
        // See if all encountered segments in a path are overlaps.
        if (!(inter && inter._overlap))
          segment._path._overlapsOnly = false;
      }
      paths = this.tracePaths(segments, operator);
    } else {
      // When there are no crossings, the result can be determined through
      // a much faster call to reorientPaths():
      paths = this.reorientPaths(
        // Make sure reorientPaths() never works on original
        // _children arrays by calling paths1.slice()
        paths2 ? paths1.concat(paths2) : paths1.slice(),
        function (w) {
          return !!operator[w];
        });
    }
    return this.createResult(paths, true, path1, path2, options);
  }

  splitBoolean(path1, path2, operation) {
    const _path1 = this.preparePath(path1),
      _path2 = this.preparePath(path2),
      crossings = _path1.getIntersections(_path2, this.filterIntersection),
      subtract = operation === 'subtract',
      divide = operation === 'divide',
      added = {},
      paths = [];

    function addPath(path) {
      // Simple see if the point halfway across the open path is inside
      // path2, and include / exclude the path based on the operator.
      if (!added[path._id] && (divide ||
        _path2.contains(path.getPointAt(path.getLength() / 2))
        ^ subtract)) {
        paths.unshift(path);
        return added[path._id] = true;
      }
    }

    // Now loop backwards through all crossings, split the path and check
    // the new path that was split off for inclusion.
    for (let i = crossings.length - 1; i >= 0; i--) {
      var path = crossings[i].split();
      if (path) {
        // See if we can add the path, and if so, clear the first handle
        // at the split, because it might have been a curve.
        if (addPath(path))
          path.getFirstSegment().setHandleIn(0, 0);
        // Clear the other side of the split too, which is always the
        // end of the remaining _path1.
        _path1.getLastSegment().setHandleOut(0, 0);
      }
    }
    // At the end, add what's left from our path after all the splitting.
    addPath(_path1);
    return createResult(paths, false, path1, path2);
  }

  /*
   * Creates linked lists between intersections through their _next and _prev
   * properties.
   *
   * @private
   */
  linkIntersections(from, to) {
    // Only create the link if it's not already in the existing chain, to
    // avoid endless recursions. First walk to the beginning of the chain,
    // and abort if we find `to`.
    let prev = from;
    while (prev) {
      if (prev === to)
        return;
      prev = prev._previous;
    }
    // Now walk to the end of the existing chain to find an empty spot, but
    // stop if we find `to`, to avoid adding it again.
    while (from._next && from._next !== to)
      from = from._next;
    // If we're reached the end of the list, we can add it.
    if (!from._next) {
      // Go back to beginning of the other chain, and link the two up.
      while (to._previous)
        to = to._previous;
      from._next = to;
      to._previous = from;
    }
  }

  clearCurveHandles(curves) {
    // Clear segment handles if they were part of a curve with no handles.
    for (let i = curves.length - 1; i >= 0; i--)
      curves[i].clearHandles();
  }

  /**
   * Reorients the specified paths.
   *
   * @param {Item[]} paths the paths of which the orientation needs to be
   *     reoriented
   * @param {Function} isInside determines if the inside of a path is filled.
   *     For non-zero fill rule this function would be implemented as follows:
   *
   *     function isInside(w) {
   *       return w != 0;
   *     }
   * @param {Boolean} [clockwise] if provided, the orientation of the root
   *     paths will be set to the orientation specified by `clockwise`,
   *     otherwise the orientation of the largest root child is used.
   * @return {Item[]} the reoriented paths
   */
  reorientPaths(paths, isInside, clockwise) {
    const length = paths && paths.length;
    if (length) {
      const lookup = Base.each(paths, function (path, i) {
          // Build a lookup table with information for each path's
          // original index and winding contribution.
          this[path._id] = {
            container: null,
            winding: path.isClockwise() ? 1 : -1,
            index: i
          };
        }, {}),
        // Now sort the paths by their areas, from large to small.
        sorted = paths.slice().sort(function (a, b) {
          return Math.abs(b.getArea()) - Math.abs(a.getArea());
        }),
        // Get reference to the first, largest path and insert it
        // already.
        first = sorted[0];
      // create lookup containing potentially overlapping path bounds
      const collisions = CollisionDetection.findItemBoundsCollisions(sorted,
        null, Numerical.GEOMETRIC_EPSILON);
      if (clockwise == null)
        clockwise = first.isClockwise();
      // Now determine the winding for each path, from large to small.
      for (var i = 0; i < length; i++) {
        const path1 = sorted[i],
          entry1 = lookup[path1._id];
        let containerWinding = 0;
        const indices = collisions[i];
        if (indices) {
          let point = null; // interior point, only get it if required.
          for (let j = indices.length - 1; j >= 0; j--) {
            if (indices[j] < i) {
              point = point || path1.getInteriorPoint();
              const path2 = sorted[indices[j]];
              // As we run through the paths from largest to
              // smallest, for any current path, all potentially
              // containing paths have already been processed and
              // their orientation fixed. To achieve correct
              // orientation of contained paths based on winding,
              // find one containing path with different
              // "insideness" and set opposite orientation.
              if (path2.contains(point)) {
                const entry2 = lookup[path2._id];
                containerWinding = entry2.winding;
                entry1.winding += containerWinding;
                entry1.container = entry2.exclude
                  ? entry2.container : path2;
                break;
              }
            }
          }
        }
        // Only keep paths if the "insideness" changes when crossing the
        // path, e.g. the inside of the path is filled and the outside
        // is not, or vice versa.
        if (isInside(entry1.winding) === isInside(containerWinding)) {
          entry1.exclude = true;
          // No need to delete excluded entries. Setting to null is
          // enough, as #setChildren() can handle arrays with gaps.
          paths[entry1.index] = null;
        } else {
          // If the containing path is not excluded, we're done
          // searching for the orientation defining path.
          const container = entry1.container;
          path1.setClockwise(
            container ? !container.isClockwise() : clockwise);
        }
      }
    }
    return paths;
  }


  /**
   * Divides the path-items at the given locations.
   *
   * @param {CurveLocation[]} locations an array of the locations to split the
   *     path-item at.
   * @param {Function} [include] a function that determines if dividing should
   *     happen at a given location.
   * @return {CurveLocation[]} the locations at which the involved path-items
   *     were divided
   * @private
   */
  divideLocations(locations, include, clearLater) {
    const results = include && [],
      tMin = /*#=*/Numerical.CURVETIME_EPSILON,
      tMax = 1 - tMin;
    let clearHandles = false;
    const clearCurves = clearLater || [],
      clearLookup = clearLater && {};
    let renormalizeLocs,
      prevCurve,
      prevTime;

    // When dealing with overlaps and crossings, divideLocations() is called
    // twice. If curve handles of curves that originally didn't have handles
    // are cleared after the first call , we loose  curve-time consistency
    // and CurveLocation#_time values become invalid.
    // In those situations, clearLater is passed as a container for all
    // curves of which the handles need to be cleared in the end.
    // Create a lookup table that allows us to quickly determine if a given
    // curve was resulting from an original curve without handles.
    function getId(curve) {
      return curve._path._id + '.' + curve._segment1._index;
    }

    for (var i = (clearLater && clearLater.length) - 1; i >= 0; i--) {
      var curve = clearLater[i];
      if (curve._path)
        clearLookup[getId(curve)] = true;
    }

    // Loop backwards through all sorted locations, from right to left, so
    // we can assume a predefined sequence for curve-time renormalization.
    for (var i = locations.length - 1; i >= 0; i--) {
      var loc = locations[i],
        // Retrieve curve-time before calling include(), because it may
        // be changed to the scaled value after splitting previously.
        // See CurveLocation#getCurve(), #resolveCrossings()
        time = loc._time,
        origTime = time,
        exclude = include && !include(loc),
        // Retrieve curve after calling include(), because it may cause
        // a change in the cached location values, see above.
        curve = loc._curve,
        segment;
      if (curve) {
        if (curve !== prevCurve) {
          // This is a new curve, update clearHandles setting.
          clearHandles = !curve.hasHandles()
            || clearLookup && clearLookup[getId(curve)];
          // Keep track of locations for later curve-time
          // renormalization within the curve.
          renormalizeLocs = [];
          prevTime = null;
          prevCurve = curve;
        } else if (prevTime >= tMin) {
          // Rescale curve-time when we are splitting the same curve
          // multiple times, if splitting was done previously.
          time /= prevTime;
        }
      }
      if (exclude) {
        // Store excluded locations for later renormalization, in case
        // the same curve is divided to their left.
        if (renormalizeLocs)
          renormalizeLocs.push(loc);
        continue;
      } else if (include) {
        results.unshift(loc);
      }
      prevTime = origTime;
      if (time < tMin) {
        segment = curve._segment1;
      } else if (time > tMax) {
        segment = curve._segment2;
      } else {
        // Split the curve at time, passing true for _setHandles to
        // always set the handles on the sub-curves even if the original
        // curve had no handles.
        const newCurve = curve.divideAtTime(time, true);
        // Keep track of curves without handles, so they can be cleared
        // again at the end.
        if (clearHandles)
          clearCurves.push(curve, newCurve);
        segment = newCurve._segment1;
        // Handle locations that need their curve-time renormalized
        // within the same curve after dividing at this location.
        for (let j = renormalizeLocs.length - 1; j >= 0; j--) {
          const l = renormalizeLocs[j];
          l._time = (l._time - time) / (1 - time);
        }
      }
      loc._setSegment(segment);
      // Create links from the new segment to the intersection on the
      // other curve, as well as from there back. If there are multiple
      // intersections on the same segment, we create linked lists between
      // the intersections through linkIntersections(), linking both ways.
      const inter = segment._intersection,
        dest = loc._intersection;
      if (inter) {
        this.linkIntersections(inter, dest);
        // Each time we add a new link to the linked list, we need to
        // add links from all the other entries to the new entry.
        let other = inter;
        while (other) {
          this.linkIntersections(other._intersection, inter);
          other = other._next;
        }
      } else {
        segment._intersection = dest;
      }
    }
    // Clear curve handles right away if we're not storing them for later.
    if (!clearLater)
      this.clearCurveHandles(clearCurves);
    return results || locations;
  }

  /**
   * Returns the winding contribution number of the given point in respect
   * to the shapes described by the passed curves.
   *
   * See #1073#issuecomment-226942348 and #1073#issuecomment-226946965 for a
   * detailed description of the approach developed by @iconexperience to
   * precisely determine the winding contribution in all known edge cases.
   *
   * @param {Point} point the location for which to determine the winding
   *     contribution
   * @param {Curve[]} curves The curves that describe the shape against which
   *     to check, as returned by {@link Path#curves} or
   *     {@link CompoundPath#curves}.
   * @param {Boolean} [dir=false] the direction in which to determine the
   *     winding contribution, `false`: in x-direction, `true`: in y-direction
   * @param {Boolean} [closed=false] determines how areas should be closed
   *     when a curve is part of an open path, `false`: area is closed with a
   *     straight line, `true`: area is closed taking the handles of the first
   *     and last segment into account
   * @param {Boolean} [dontFlip=false] controls whether the algorithm is
   *     allowed to flip direction if it is deemed to produce better results
   * @return {Object} an object containing the calculated winding number, as
   *     well as an indication whether the point was situated on the contour
   * @private
   */
  static getWinding(point, curves, dir, closed, dontFlip) {
    // `curves` can either be an array of curves, or an object containing of
    // the form `{ hor: [], ver: [] }` (see `curveCollisionsMap`), with each
    // key / value pair holding only those curves that can be crossed by a
    // horizontal / vertical line through the point to be checked.
    const curvesList = Array.isArray(curves)
      ? curves
      : curves[dir ? 'hor' : 'ver'];
    // Determine the index of the abscissa and ordinate values in the curve
    // values arrays, based on the direction:
    const ia = dir ? 1 : 0, // the abscissa index
      io = ia ^ 1, // the ordinate index
      pv = [point.x, point.y],
      pa = pv[ia], // the point's abscissa
      po = pv[io], // the point's ordinate
      // Use separate epsilons for winding contribution code.
      windingEpsilon = 1e-9,
      qualityEpsilon = 1e-6,
      paL = pa - windingEpsilon,
      paR = pa + windingEpsilon;
    let windingL = 0,
      windingR = 0,
      pathWindingL = 0,
      pathWindingR = 0,
      onPath = false,
      onAnyPath = false,
      quality = 1;
    const roots = [];
    let vPrev,
      vClose;

    function addWinding(v) {
      const o0 = v[io + 0],
        o3 = v[io + 6];
      if (po < Math.min(o0, o3) || po > Math.max(o0, o3)) {
        // If the curve is outside the ordinates' range, no intersection
        // with the ray is possible.
        return;
      }
      const a0 = v[ia + 0],
        a1 = v[ia + 2],
        a2 = v[ia + 4],
        a3 = v[ia + 6];
      if (o0 === o3) {
        // A horizontal curve is not necessarily between two non-
        // horizontal curves. We have to take cases like these into
        // account:
        //          +-----+
        //     +----+     |
        //          +-----+
        if (a0 < paR && a3 > paL || a3 < paR && a0 > paL) {
          onPath = true;
        }
        // If curve does not change in ordinate direction, windings will
        // be added by adjacent curves.
        // Bail out without updating vPrev at the end of the call.
        return;
      }
      // Determine the curve-time value corresponding to the point.
      const t = po === o0 ? 0
        : po === o3 ? 1
          // If the abscissa is outside the curve, we can use any
          // value except 0 (requires special handling). Use 1, as it
          // does not require additional calculations for the point.
          : paL > Math.max(a0, a1, a2, a3) || paR < Math.min(a0, a1, a2, a3)
            ? 1
            : Curve.solveCubic(v, io, po, roots, 0, 1) > 0
              ? roots[0]
              : 1,
        a = t === 0 ? a0
          : t === 1 ? a3
            : Curve.getPoint(v, t)[dir ? 'y' : 'x'],
        winding = o0 > o3 ? 1 : -1,
        windingPrev = vPrev[io] > vPrev[io + 6] ? 1 : -1,
        a3Prev = vPrev[ia + 6];
      if (po !== o0) {
        // Standard case, curve is not crossed at its starting point.
        if (a < paL) {
          pathWindingL += winding;
        } else if (a > paR) {
          pathWindingR += winding;
        } else {
          onPath = true;
        }
        // Determine the quality of the winding calculation. Reduce the
        // quality with every crossing of the ray very close to the
        // path. This means that if the point is on or near multiple
        // curves, the quality becomes less than 0.5.
        if (a > pa - qualityEpsilon && a < pa + qualityEpsilon)
          quality /= 2;
      } else {
        // Curve is crossed at starting point.
        if (winding !== windingPrev) {
          // Winding changes from previous curve, cancel its winding.
          if (a0 < paL) {
            pathWindingL += winding;
          } else if (a0 > paR) {
            pathWindingR += winding;
          }
        } else if (a0 != a3Prev) {
          // Handle a horizontal curve between the current and
          // previous non-horizontal curve. See
          // #1261#issuecomment-282726147 for a detailed explanation:
          if (a3Prev < paR && a > paR) {
            // Right winding was not added before, so add it now.
            pathWindingR += winding;
            onPath = true;
          } else if (a3Prev > paL && a < paL) {
            // Left winding was not added before, so add it now.
            pathWindingL += winding;
            onPath = true;
          }
        }
        quality /= 4;
      }
      vPrev = v;
      // If we're on the curve, look at the tangent to decide whether to
      // flip direction to better determine a reliable winding number:
      // If the tangent is parallel to the direction, call getWinding()
      // again with flipped direction and return that result instead.
      return !dontFlip && a > paL && a < paR
        && Curve.getTangent(v, t)[dir ? 'x' : 'y'] === 0
        && PathItem.getWinding(point, curves, !dir, closed, true);
    }

    function handleCurve(v) {
      // Get the ordinates:
      const o0 = v[io + 0],
        o1 = v[io + 2],
        o2 = v[io + 4],
        o3 = v[io + 6];
      // Only handle curves that can cross the point's ordinate.
      if (po <= Math.max(o0, o1, o2, o3) && po >= Math.min(o0, o1, o2, o3)) {
        // Get the abscissas:
        const a0 = v[ia + 0],
          a1 = v[ia + 2],
          a2 = v[ia + 4],
          a3 = v[ia + 6],
          // Get monotone curves. If the curve is outside the point's
          // abscissa, it can be treated as a monotone curve:
          monoCurves = paL > Math.max(a0, a1, a2, a3) ||
          paR < Math.min(a0, a1, a2, a3)
            ? [v] : Curve.getMonoCurves(v, dir);
        let res;
        let i = 0;
        const l = monoCurves.length;
        for (; i < l; i++) {
          // Calling addWinding() my lead to direction flipping, in
          // which case we already have the result and can return it.
          if (res = addWinding(monoCurves[i]))
            return res;
        }
      }
    }

    for (var i = 0, l = curvesList.length; i < l; i++) {
      var curve = curvesList[i],
        path = curve._path,
        v = curve.getValues(),
        res;
      if (!i || curvesList[i - 1]._path !== path) {
        // We're on a new (sub-)path, so we need to determine values of
        // the last non-horizontal curve on this path.
        vPrev = null;
        // If the path is not closed, connect the first and last segment
        // based on the value of `closed`:
        // - `false`: Connect with a straight curve, just like how
        //   filling open paths works.
        // - `true`: Connect with a curve that takes the segment handles
        //   into account, just like how closed paths behave.
        if (!path._closed) {
          vClose = Curve.getValues(
            path.getLastCurve().getSegment2(),
            curve.getSegment1(),
            null, !closed);
          // This closing curve is a potential candidate for the last
          // non-horizontal curve.
          if (vClose[io] !== vClose[io + 6]) {
            vPrev = vClose;
          }
        }

        if (!vPrev) {
          // Walk backwards through list of the path's curves until we
          // find one that is not horizontal.
          // Fall-back to the first curve's values if none is found:
          vPrev = v;
          let prev = path.getLastCurve();
          while (prev && prev !== curve) {
            const v2 = prev.getValues();
            if (v2[io] !== v2[io + 6]) {
              vPrev = v2;
              break;
            }
            prev = prev.getPrevious();
          }
        }
      }

      // Calling handleCurve() my lead to direction flipping, in which
      // case we already have the result and can return it.
      if (res = handleCurve(v))
        return res;

      if (i + 1 === l || curvesList[i + 1]._path !== path) {
        // We're at the last curve of the current (sub-)path. If a
        // closing curve was calculated at the beginning of it, handle
        // it now to treat the path as closed:
        if (vClose && (res = handleCurve(vClose)))
          return res;
        if (onPath && !pathWindingL && !pathWindingR) {
          // If the point is on the path and the windings canceled
          // each other, we treat the point as if it was inside the
          // path. A point inside a path has a winding of [+1,-1]
          // for clockwise and [-1,+1] for counter-clockwise paths.
          // If the ray is cast in y direction (dir == true), the
          // windings always have opposite sign.
          pathWindingL = pathWindingR = path.isClockwise(closed) ^ dir
            ? 1 : -1;
        }
        windingL += pathWindingL;
        windingR += pathWindingR;
        pathWindingL = pathWindingR = 0;
        if (onPath) {
          onAnyPath = true;
          onPath = false;
        }
        vClose = null;
      }
    }
    // Use the unsigned winding contributions when determining which areas
    // are part of the boolean result.
    windingL = Math.abs(windingL);
    windingR = Math.abs(windingR);
    // Return the calculated winding contributions along with a quality
    // value indicating how reliable the value really is.
    return {
      winding: Math.max(windingL, windingR),
      windingL: windingL,
      windingR: windingR,
      quality: quality,
      onPath: onAnyPath
    };
  }

  propagateWinding(segment, path1, path2, curveCollisionsMap,
                   operator) {
    // Here we try to determine the most likely winding number contribution
    // for the curve-chain starting with this segment. Once we have enough
    // confidence in the winding contribution, we can propagate it until the
    // next intersection or end of a curve chain.
    var chain = [],
      start = segment,
      totalLength = 0,
      winding;
    do {
      var curve = segment.getCurve();
      // We can encounter paths with only one segment, which would not
      // have a curve.
      if (curve) {
        var length = curve.getLength();
        chain.push({segment: segment, curve: curve, length: length});
        totalLength += length;
      }
      segment = segment.getNext();
    } while (segment && !segment._intersection && segment !== start);
    // Determine winding at three points in the chain. If a winding with
    // sufficient quality is found, use it. Otherwise use the winding with
    // the best quality.
    var offsets = [0.5, 0.25, 0.75],
      winding = {winding: 0, quality: -1},
      // Don't go too close to segments, to avoid special winding cases:
      tMin = 1e-3,
      tMax = 1 - tMin;
    for (let i = 0; i < offsets.length && winding.quality < 0.5; i++) {
      var length = totalLength * offsets[i];
      for (var j = 0, l = chain.length; j < l; j++) {
        const entry = chain[j],
          curveLength = entry.length;
        if (length <= curveLength) {
          var curve = entry.curve,
            path = curve._path,
            parent = path._parent,
            operand = parent instanceof CompoundPath ? parent : path,
            t = Numerical.clamp(curve.getTimeAt(length), tMin, tMax),
            pt = curve.getPointAtTime(t),
            // Determine the direction in which to check the winding
            // from the point (horizontal or vertical), based on the
            // curve's direction at that point. If tangent is less
            // than 45°, cast the ray vertically, else horizontally.
            dir = Math.abs(curve.getTangentAtTime(t).y) < Math.SQRT1_2;
          // While subtracting, we need to omit this curve if it is
          // contributing to the second operand and is outside the
          // first operand.
          let wind = null;
          if (operator.subtract && path2) {
            // Calculate path winding at point depending on operand.
            const otherPath = operand === path1 ? path2 : path1,
              pathWinding = otherPath._getWinding(pt, dir, true);
            // Check if curve should be omitted.
            if (operand === path1 && pathWinding.winding ||
              operand === path2 && !pathWinding.winding) {
              // Check if quality is not good enough...
              if (pathWinding.quality < 1) {
                // ...and if so, skip this point...
                continue;
              } else {
                // ...otherwise, omit this curve.
                wind = {winding: 0, quality: 1};
              }
            }
          }
          wind = wind || PathItem.getWinding(
            pt, curveCollisionsMap[path._id][curve.getIndex()],
            dir, true);
          if (wind.quality > winding.quality)
            winding = wind;
          break;
        }
        length -= curveLength;
      }
    }
    // Now assign the winding to the entire curve chain.
    for (var j = chain.length - 1; j >= 0; j--) {
      chain[j].segment._winding = winding;
    }
  }

  /**
   * Private method to trace closed paths from a list of segments, according
   * to a the their winding number contribution and a custom operator.
   *
   * @param {Segment[]} segments array of segments to trace closed paths
   * @param {Function} operator the operator lookup table that receives as key
   *     the winding number contribution of a curve and returns a boolean
   *     value indicating whether the curve should be included in result
   * @return {Path[]} the traced closed paths
   */
  tracePaths(segments, operator) {
    const paths = [];
    let starts;

    function isValid(seg) {
      let winding;
      return !!(seg && !seg._visited && (!operator
        || operator[(winding = seg._winding || {}).winding]
        // Unite operations need special handling of segments
        // with a winding contribution of two (part of both
        // areas), which are only valid if they are part of the
        // result's contour, not contained inside another area.
        && !(operator.unite && winding.winding === 2
          // No contour if both windings are non-zero.
          && winding.windingL && winding.windingR)));
    }

    function isStart(seg) {
      if (seg) {
        let i = 0;
        const l = starts.length;
        for (; i < l; i++) {
          if (seg === starts[i])
            return true;
        }
      }
      return false;
    }

    function visitPath(path) {
      const segments = path._segments;
      let i = 0;
      const l = segments.length;
      for (; i < l; i++) {
        segments[i]._visited = true;
      }
    }

    // If there are multiple possible intersections, find the ones that's
    // either connecting back to start or are not visited yet, and will be
    // part of the boolean result:
    function getCrossingSegments(segment, collectStarts) {
      let inter = segment._intersection;
      const start = inter,
        crossings = [];
      if (collectStarts)
        starts = [segment];

      function collect(inter, end) {
        while (inter && inter !== end) {
          const other = inter._segment,
            path = other && other._path;
          if (path) {
            const next = other.getNext() || path.getFirstSegment(),
              nextInter = next._intersection;
            // See if this segment and the next are not visited yet,
            // or are bringing us back to the start, and are both
            // valid, meaning they're part of the boolean result.
            if (other !== segment && (isStart(other)
              || isStart(next)
              || next && (isValid(other) && (isValid(next)
                // If next segment isn't valid, its intersection
                // to which we may switch may be, so check that.
                || nextInter && isValid(nextInter._segment))))
            ) {
              crossings.push(other);
            }
            if (collectStarts)
              starts.push(other);
          }
          inter = inter._next;
        }
      }

      if (inter) {
        collect(inter);
        // Find the beginning of the linked intersections and loop all
        // the way back to start, to collect all valid intersections.
        while (inter && inter._previous)
          inter = inter._previous;
        collect(inter, start);
      }
      return crossings;
    }

    // Sort segments to give non-ambiguous segments the preference as
    // starting points when tracing: prefer segments with no intersections
    // over intersections, and process intersections with overlaps last:
    segments.sort(function (seg1, seg2) {
      const inter1 = seg1._intersection,
        inter2 = seg2._intersection,
        over1 = !!(inter1 && inter1._overlap),
        over2 = !!(inter2 && inter2._overlap),
        path1 = seg1._path,
        path2 = seg2._path;
      // Use bitwise-or to sort cases where only one segment is an overlap
      // or intersection separately, and fall back on natural order within
      // the path.
      return over1 ^ over2
        ? over1 ? 1 : -1
        // NOTE: inter1 & 2 are objects, convert to boolean first
        // as otherwise toString() is called on them.
        : !inter1 ^ !inter2
          ? inter1 ? 1 : -1
          // All other segments, also when comparing two overlaps
          // or two intersections, are sorted by their order.
          // Sort by path id to group segments on the same path.
          : path1 !== path2
            ? path1._id - path2._id
            : seg1._index - seg2._index;
    });

    for (var i = 0, l = segments.length; i < l; i++) {
      var seg = segments[i],
        valid = isValid(seg),
        path = null,
        finished = false,
        closed = true,
        branches = [],
        branch,
        visited,
        handleIn;
      // If all encountered segments in a path are overlaps, we may have
      // two fully overlapping paths that need special handling.
      if (valid && seg._path._overlapsOnly) {
        // TODO: Don't we also need to check for multiple overlaps?
        var path1 = seg._path,
          path2 = seg._intersection._segment._path;
        if (path1.compare(path2)) {
          // Only add the path to the result if it has an area.
          if (path1.getArea())
            paths.push(path1.clone(false));
          // Now mark all involved segments as visited.
          visitPath(path1);
          visitPath(path2);
          valid = false;
        }
      }
      // Do not start with invalid segments (segments that were already
      // visited, or that are not going to be part of the result).
      while (valid) {
        // For each segment we encounter, see if there are multiple
        // crossings, and if so, pick the best one:
        var first = !path,
          crossings = getCrossingSegments(seg, first),
          // Get the other segment of the first found crossing.
          other = crossings.shift(),
          finished = !first && (isStart(seg) || isStart(other)),
          cross = !finished && other;
        if (first) {
          path = new Path(Item.NO_INSERT);
          // Clear branch to start a new one with each new path.
          branch = null;
        }
        if (finished) {
          // If we end up on the first or last segment of an operand,
          // copy over its closed state, to support mixed open/closed
          // scenarios as described in #1036
          if (seg.isFirst() || seg.isLast())
            closed = seg._path._closed;
          seg._visited = true;
          break;
        }
        if (cross && branch) {
          // If we're about to cross, start a new branch and add the
          // current one to the list of branches.
          branches.push(branch);
          branch = null;
        }
        if (!branch) {
          // Add the branch's root segment as the last segment to try,
          // to see if we get to a solution without crossing.
          if (cross)
            crossings.push(seg);
          branch = {
            start: path._segments.length,
            crossings: crossings,
            visited: visited = [],
            handleIn: handleIn
          };
        }
        if (cross)
          seg = other;
        // If an invalid segment is encountered, go back to the last
        // crossing and try other possible crossings, as well as not
        // crossing at the branch's root.
        if (!isValid(seg)) {
          // Remove the already added segments, and mark them as not
          // visited so they become available again as options.
          path.removeSegments(branch.start);
          let j = 0;
          const k = visited.length;
          for (; j < k; j++) {
            visited[j]._visited = false;
          }
          visited.length = 0;
          // Go back to the branch's root segment where the crossing
          // happened, and try other crossings. Note that this also
          // tests the root segment without crossing as it is added to
          // the list of crossings when the branch is created above.
          do {
            seg = branch && branch.crossings.shift();
            if (!seg || !seg._path) {
              seg = null;
              // If there are no segments left, try previous
              // branches until we find one that works.
              branch = branches.pop();
              if (branch) {
                visited = branch.visited;
                handleIn = branch.handleIn;
              }
            }
          } while (branch && !isValid(seg));
          if (!seg)
            break;
        }
        // Add the segment to the path, and mark it as visited.
        // But first we need to look ahead. If we encounter the end of
        // an open path, we need to treat it the same way as the fill of
        // an open path would: Connecting the last and first segment
        // with a straight line, ignoring the handles.
        var next = seg.getNext();
        path.add(new Segment(seg._point, handleIn,
          next && seg._handleOut));
        seg._visited = true;
        visited.push(seg);
        // If this is the end of an open path, go back to its first
        // segment but ignore its handleIn (see above for handleOut).
        seg = next || seg._path.getFirstSegment();
        handleIn = next && next._handleIn;
      }
      if (finished) {
        if (closed) {
          // Carry over the last handleIn to the first segment.
          path.getFirstSegment().setHandleIn(handleIn);
          path.setClosed(closed);
        }
        // Only add finished paths that cover an area to the result.
        if (path.getArea() !== 0) {
          paths.push(path);
        }
      }
    }
    return paths;
  }

  _getWinding(point, dir, closed) {
    return PathItem.getWinding(point, this.getCurves(), dir, closed);
  }

  unite(path, options) {
    return this.traceBoolean(this, path, 'unite', options);
  }


  intersect(path, options) {
    return this.traceBoolean(this, path, 'intersect', options);
  }


  subtract(path, options) {
    return this.traceBoolean(this, path, 'subtract', options);
  }


  exclude(path, options) {
    return this.traceBoolean(this, path, 'exclude', options);
  }


  divide(path, options) {
    return options && (options.trace == false || options.stroke)
      ? this.splitBoolean(this, path, 'divide')
      : this.createResult([
        this.subtract(path, options),
        this.intersect(path, options)
      ], true, this, path, options);
  }


  resolveCrossings() {
    const children = this._children;
    let // Support both path and compound-path items
      paths = children || [this];

    function hasOverlap(seg, path) {
      const inter = seg && seg._intersection;
      return inter && inter._overlap && inter._path === path;
    }

    // First collect all overlaps and crossings while taking note of the
    // existence of both.
    let hasOverlaps = false,
      hasCrossings = false,
      intersections = this.getIntersections(null, function (inter) {
        return inter.hasOverlap() && (hasOverlaps = true) ||
          inter.isCrossing() && (hasCrossings = true);
      });
    const // We only need to keep track of curves that need clearing
      // outside of divideLocations() if two calls are necessary.
      clearCurves = hasOverlaps && hasCrossings && [];
    intersections = CurveLocation.expand(intersections);
    if (hasOverlaps) {
      // First divide in all overlaps, and then remove the inside of
      // the resulting overlap ranges.
      const overlaps = this.divideLocations(intersections, function (inter) {
        return inter.hasOverlap();
      }, clearCurves);
      for (let i = overlaps.length - 1; i >= 0; i--) {
        var overlap = overlaps[i],
          path = overlap._path,
          seg = overlap._segment,
          prev = seg.getPrevious(),
          next = seg.getNext();
        if (hasOverlap(prev, path) && hasOverlap(next, path)) {
          seg.remove();
          prev._handleOut._set(0, 0);
          next._handleIn._set(0, 0);
          // If the curve that is left has no length, remove it
          // altogether. Check for paths with only one segment
          // before removal, since `prev.getCurve() == null`.
          if (prev !== seg && !prev.getCurve().hasLength()) {
            // Transfer handleIn when removing segment:
            next._handleIn.set(prev._handleIn);
            prev.remove();
          }
        }
      }
    }
    if (hasCrossings) {
      // Divide any remaining intersections that are still part of
      // valid paths after the removal of overlaps.
      this.divideLocations(intersections, hasOverlaps && function (inter) {
        // Check both involved curves to see if they're still valid,
        // meaning they are still part of their paths.
        const curve1 = inter.getCurve(),
          seg1 = inter.getSegment(),
          // Do not call getCurve() and getSegment() on the other
          // intersection yet, as it too is in the intersections
          // array and will be divided later. But check if its
          // current curve is valid, as required by some rare edge
          // cases, related to intersections on the same curve.
          other = inter._intersection,
          curve2 = other._curve,
          seg2 = other._segment;
        if (curve1 && curve2 && curve1._path && curve2._path)
          return true;
        // Remove all intersections that were involved in the
        // handling of overlaps, to not confuse tracePaths().
        if (seg1)
          seg1._intersection = null;
        if (seg2)
          seg2._intersection = null;
      }, clearCurves);
      if (clearCurves)
        this.clearCurveHandles(clearCurves);
      // Finally resolve self-intersections through tracePaths()
      paths = this.tracePaths(Base.each(paths, function (path) {
        Base.push(this, path._segments);
      }, []));
    }
    // Determine how to return the paths: First try to recycle the
    // current path / compound-path, if the amount of paths does not
    // require a conversion.
    const length = paths.length;
    let item;
    if (length > 1 && children) {
      if (paths !== children)
        this.setChildren(paths);
      item = this;
    } else if (length === 1 && !children) {
      if (paths[0] !== this)
        this.setSegments(paths[0].removeSegments());
      item = this;
    }
    // Otherwise create a new compound-path and see if we can reduce it,
    // and attempt to replace this item with it.
    if (!item) {
      item = new CompoundPath(Item.NO_INSERT);
      item.addChildren(paths);
      item = item.reduce();
      item.copyAttributes(this);
      this.replaceWith(item);
    }
    return item;
  }


  reorient(nonZero, clockwise) {
    const children = this._children;
    if (children && children.length) {
      this.setChildren(this.reorientPaths(this.removeChildren(),
        function (w) {
          // Handle both even-odd and non-zero rule.
          return !!(nonZero ? w : w & 1);
        },
        clockwise));
    } else if (clockwise !== undefined) {
      this.setClockwise(clockwise);
    }
    return this;
  }

  /**
   * Returns a point that is guaranteed to be inside the path.
   *
   * @bean
   * @type Point
   */
  getInteriorPoint() {
    const bounds = this.getBounds(),
      point = bounds.getCenter(true);
    if (!this.contains(point)) {
      // Since there is no guarantee that a poly-bezier path contains
      // the center of its bounding rectangle, we shoot a ray in x
      // direction and select a point between the first consecutive
      // intersections of the ray on the left.
      const curves = this.getCurves(),
        y = point.y,
        intercepts = [],
        roots = [];
      // Process all y-monotone curves that intersect the ray at y:
      let i = 0;
      const l = curves.length;
      for (; i < l; i++) {
        const v = curves[i].getValues(),
          o0 = v[1],
          o1 = v[3],
          o2 = v[5],
          o3 = v[7];
        if (y >= Math.min(o0, o1, o2, o3) && y <= Math.max(o0, o1, o2, o3)) {
          const monoCurves = Curve.getMonoCurves(v);
          let j = 0;
          const m = monoCurves.length;
          for (; j < m; j++) {
            const mv = monoCurves[j],
              mo0 = mv[1],
              mo3 = mv[7];
            // Only handle curves that are not horizontal and
            // that can cross the point's ordinate.
            if ((mo0 !== mo3) &&
              (y >= mo0 && y <= mo3 || y >= mo3 && y <= mo0)) {
              const x = y === mo0 ? mv[0]
                : y === mo3 ? mv[6]
                  : Curve.solveCubic(mv, 1, y, roots, 0, 1)
                  === 1
                    ? Curve.getPoint(mv, roots[0]).x
                    : (mv[0] + mv[6]) / 2;
              intercepts.push(x);
            }
          }
        }
      }
      if (intercepts.length > 1) {
        intercepts.sort(function (a, b) {
          return a - b;
        });
        point.x = (intercepts[0] + intercepts[1]) / 2;
      }
    }
    return point;
  }
  offset (offset, options) {
    return PaperOffset.offsetStroke(this, offset, options);
    //return PaperOffset.offset(this, offset, options);
  };
  offsetStroke (offset, options) {
    return PaperOffset.offsetStroke(this, offset, options);
  }
}

export class Path extends PathItem {

  constructor(arg) {

    super(...arguments)
    // Do nothing.
  }

  initialize(arg) {

    this._closed = true;
    this._segments = [];
    // Increased on every change of segments, so CurveLocation knows when to
    // update its internally cached values.
    this._version = 0;
    // arg can either be an object literal containing properties to be set
    // on the path, a list of segments to be set, or the first of multiple
    // arguments describing separate segments.
    // If it is an array, it can also be a description of a point, so
    // check its first entry for object as well.
    // But first see if segments are directly passed at all. If not, try
    // _set(arg).
    var args = arguments,
      segments = Array.isArray(arg)
        ? typeof arg[0] === 'object'
          ? arg
          : args
        // See if it behaves like a segment or a point, but filter out
        // rectangles, as accepted by some Path.Constructor constructors.
        : arg && (arg.size === undefined && (arg.x !== undefined
          || arg.point !== undefined))
          ? args
          : null;
    // Always call setSegments() to initialize a few related variables.
    if (segments && segments.length > 0) {
      // This sets _curves and _segmentSelection too!
      this.setSegments(segments);
    } else {
      this._curves = undefined; // For hidden class optimization
      this._segmentSelection = 0;
      if (!segments && typeof arg === 'string') {
        this.setPathData(arg);
        // Erase for _initialize() call below.
        arg = null;
      }
    }
    // Only pass on arg as props if it wasn't consumed for segments already.
    this._initialize(!segments && arg);
  }

  _equals(item) {
    return this._closed === item._closed
      && Base.equals(this._segments, item._segments);
  }

  copyContent(source) {
    this.setSegments(source._segments);
    this._closed = source._closed;
  }

  _changed(flags) {

    super._changed(flags);

    if (flags & /*#=*/ChangeFlag.GEOMETRY) {
      this._length = this._area = undefined;
      if (flags & /*#=*/ChangeFlag.SEGMENTS) {
        this._version++; // See CurveLocation
      } else if (this._curves) {
        // Only notify all curves if we're not told that only segments
        // have changed and took already care of notifications.
        for (var i = 0, l = this._curves.length; i < l; i++)
          this._curves[i]._changed();
      }
    } else if (flags & /*#=*/ChangeFlag.STROKE) {
      // TODO: We could preserve the purely geometric bounds that are not
      // affected by stroke: _bounds.bounds and _bounds.handleBounds
      this._bounds = undefined;
    }
  }

  style() {
    // If this path is part of a compound-path, return the parent's style.
    var parent = this._parent;
    return (parent instanceof CompoundPath ? parent : this)._style;
  }

  /**
   * The segments contained within the path.
   *
   * @bean
   * @type Segment[]
   */
  get segments() {
    return this._segments;
  }

  getSegments() {
    return this._segments;
  }

  setSegments(segments) {
    var fullySelected = this.isFullySelected(),
      length = segments && segments.length;
    this._segments.length = 0;
    this._segmentSelection = 0;
    // Calculate new curves next time we call getCurves()
    this._curves = undefined;
    if (length) {
      // See if the last segment is a boolean describing the path's closed
      // state. This is part of the shorter segment array notation that
      // can also be nested to create compound-paths out of one array.
      var last = segments[length - 1];
      if (typeof last === 'boolean') {
        this.setClosed(last);
        length--;
      }
      this._add(Segment.readList(segments, 0, {}, length));
    }
    // Preserve fullySelected state.
    // TODO: Do we still need this?
    if (fullySelected)
      this.setFullySelected(true);
  }
  set segments(segments) {
    this.setSegments(segments)
  }
  /**
   * The first Segment contained within the path.
   *
   * @bean
   * @type Segment
   */
  getFirstSegment() {
    return this._segments[0];
  }

  /**
   * The last Segment contained within the path.
   *
   * @bean
   * @type Segment
   */
  getLastSegment() {
    return this._segments[this._segments.length - 1];
  }

  draw(segment, index) {
    return this._drawNext(segment, index);
  }


  /**
   * The curves contained within the path.
   *
   * @bean
   * @type Curve[]
   */
  getCurves() {
    var curves = this._curves,
      segments = this._segments;
    if (!curves) {
      var length = this._countCurves();
      curves = this._curves = new Array(length);
      for (var i = 0; i < length; i++)
        curves[i] = new Curve(this, segments[i],
          // Use first segment for segment2 of closing curve
          segments[i + 1] || segments[0]);
    }
    return curves;
  }
  get curves() {
    return this.getCurves()
  }
  /**
   * The first Curve contained within the path.
   *
   * @bean
   * @type Curve
   */
  getFirstCurve() {
    return this.getCurves()[0];
  }

  /**
   * The last Curve contained within the path.
   *
   * @bean
   * @type Curve
   */
  getLastCurve() {
    var curves = this.getCurves();
    return curves[curves.length - 1];
  }

  /**
   * Specifies whether the path is closed. If it is closed, Paper.js connects
   * the first and last segments.
   *
   * @bean
   * @type Boolean
   *
   * @example {@paperscript}
   * var myPath = new Path();
   * myPath.strokeColor = 'black';
   * myPath.add(new Point(50, 75));
   * myPath.add(new Point(100, 25));
   * myPath.add(new Point(150, 75));
   *
   * // Close the path:
   * myPath.closed = true;
   */
  isClosed() {
    return this._closed;
  }

  setClosed(closed) {
    // On-the-fly conversion to boolean:
    if (this._closed != (closed = !!closed)) {
      this._closed = closed;
      // Update _curves length
      if (this._curves) {
        var length = this._curves.length = this._countCurves();
        // If we were closing this path, we need to add a new curve now
        if (closed)
          this._curves[length - 1] = new Curve(this,
            this._segments[length - 1], this._segments[0]);
      }
      // Use SEGMENTS notification instead of GEOMETRY since curves are
      // up-to-date and don't need notification.
      this._changed(/*#=*/Change.SEGMENTS);
    }
  }


  getPathData(_matrix, _precision) {
    
    // NOTE: #setPathData() is defined in PathItem.
    var segments = this._segments,
      length = segments.length,
      f = new Formatter(_precision),
      coords = new Array(6),
      first = true,
      curX, curY,
      prevX, prevY,
      inX, inY,
      outX, outY,
      parts = [];

    function addSegment(segment, skipLine) {

      segment._transformCoordinates(_matrix, coords);

      curX = coords[0];
      curY = coords[1];
      
      if (first) {
        parts.push('M' + f.pair(curX, curY));
        first = false;
      } else {
        inX = coords[2];
        inY = coords[3];
        if (inX === curX && inY === curY
          && outX === prevX && outY === prevY) {
          // l = relative lineto:
          if (!skipLine) {
            var dx = curX - prevX,
              dy = curY - prevY;
            parts.push(
              dx === 0 ? 'v' + f.number(dy)
                : dy === 0 ? 'h' + f.number(dx)
                : 'l' + f.pair(dx, dy));
          }
        } else {
          // c = relative curveto:
          parts.push('c' + f.pair(outX - prevX, outY - prevY)
            + ' ' + f.pair(inX - prevX, inY - prevY)
            + ' ' + f.pair(curX - prevX, curY - prevY));
        }
      }
      prevX = curX;
      prevY = curY;
      outX = coords[4];
      outY = coords[5];
    }

    if (!length)
      return '';

    for (var i = 0; i < length; i++)
      addSegment(segments[i]);
    // Close path by drawing first segment again
    if (this._closed && length > 0) {
      addSegment(segments[0], true);
      parts.push('z');
    }
    return parts.join('');
  }

  // TODO: Consider adding getSubPath(a, b), returning a part of the current
  // path, with the added benefit that b can be < a, and closed looping is
  // taken into account.

  isEmpty() {
    return !this._segments.length;
  }

  _transformContent(matrix) {
    var segments = this._segments,
      coords = new Array(6);
    for (var i = 0, l = segments.length; i < l; i++)
      segments[i]._transformCoordinates(matrix, coords, true);
    return true;
  }

  /**
   * Private method that adds segments to the segment list. It assumes that
   * the passed object is an array of segments already and does not perform
   * any checks. If a curves list was requested, it will be kept in sync with
   * the segments list automatically.
   */
  _add(segs, index) {
    // Local short-cuts:

    var segments = this._segments,
      curves = this._curves,
      amount = segs.length,
      append = index == null,
      index = append ? segments.length : index;
    // Scan through segments to add first, convert if necessary and set
    // _path and _index references on them.
    for (var i = 0; i < amount; i++) {
      var segment = segs[i];
      // If the segments belong to another path already, clone them before
      // adding:
      if (segment._path)
        segment = segs[i] = segment.clone();
      segment._path = this;
      segment._index = index + i;
      // If parts of this segment are selected, adjust the internal
      // _segmentSelection now
      if (segment._selection)
        this._updateSelection(segment, 0, segment._selection);
    }
    if (append) {
      // Append them all at the end.
      Base.push(segments, segs);
    } else {
      // Insert somewhere else
      segments.splice.apply(segments, [index, 0].concat(segs));
      // Adjust the indices of the segments above.
      for (var i = index + amount, l = segments.length; i < l; i++)
        segments[i]._index = i;
    }
    // Keep the curves list in sync all the time in case it was requested
    // already.
    if (curves) {
      var total = this._countCurves(),
        // If we're adding a new segment to the end of an open path,
        // we need to step one index down to get its curve.
        start = index > 0 && index + amount - 1 === total ? index - 1
          : index,
        insert = start,
        end = Math.min(start + amount, total);
      if (segs._curves) {
        // Reuse removed curves.
        curves.splice.apply(curves, [start, 0].concat(segs._curves));
        insert += segs._curves.length;
      }
      // Insert new curves, but do not initialize their segments yet,
      // since #_adjustCurves() handles all that for us.
      for (var i = insert; i < end; i++)
        curves.splice(i, 0, new Curve(this, null, null));
      // Adjust segments for the curves before and after the removed ones
      this._adjustCurves(start, end);
    }
    // Use SEGMENTS notification instead of GEOMETRY since curves are kept
    // up-to-date by _adjustCurves() and don't need notification.
    this._changed(/*#=*/Change.SEGMENTS);
    return segs;
  }

  _drawNext(seg, index) {
    // Local short-cuts:


    var segments = this._segments,
      curves = this._curves,
      append = index == null || index > segments.length - 1 ,
      index = append ? segments.length : index;
    // Scan through segments to add first, convert if necessary and set
    // _path and _index references on them.

    var segment = seg;
    // If the segments belong to another path already, clone them before
    // adding:
    if (segment._path)
      segment = seg = segment.clone();
    segment._path = this;
    segment._index = index + i;
    // If parts of this segment are selected, adjust the internal
    // _segmentSelection now
    if (segment._selection)
      this._updateSelection(segment, 0, segment._selection);

    if (append) {
      // Append them all at the end.
      Base.push(segments, [seg]);
    } else {
      // Insert somewhere else
      segments[index]
        segments[index]._index = index;
    }
    // Keep the curves list in sync all the time in case it was requested
    // already.
    if (curves) {
      var total = this._countCurves(),
        // If we're adding a new segment to the end of an open path,
        // we need to step one index down to get its curve.
        start = index > 0 && index + amount - 1 === total ? index - 1
          : index,
        insert = start,
        end = Math.min(start + amount, total);
      if (seg._curves) {
        // Reuse removed curves.
        curves.splice.apply(curves, [start, 0].concat(segs._curves));
        insert += seg._curves.length;
      }
      // Insert new curves, but do not initialize their segments yet,
      // since #_adjustCurves() handles all that for us.
      for (var i = insert; i < end; i++)
        curves.splice(i, 0, new Curve(this, null, null));
      // Adjust segments for the curves before and after the removed ones
      this._adjustCurves(start, end);
    }
    // Use SEGMENTS notification instead of GEOMETRY since curves are kept
    // up-to-date by _adjustCurves() and don't need notification.
    this._changed(/*#=*/Change.SEGMENTS);
    return seg;
  }
  /**
   * Adjusts segments of curves before and after inserted / removed segments.
   */
  _adjustCurves(start, end) {
    var segments = this._segments,
      curves = this._curves,
      curve;
    for (var i = start; i < end; i++) {
      curve = curves[i];
      curve._path = this;
      curve._segment1 = segments[i];
      curve._segment2 = segments[i + 1] || segments[0];
      curve._changed();
    }
    // If it's the first segment, correct the last segment of closed
    // paths too:
    if (curve = curves[this._closed && !start ? segments.length - 1
      : start - 1]) {
      curve._segment2 = segments[start] || segments[0];
      curve._changed();
    }
    // Fix the segment after the modified range, if it exists
    if (curve = curves[end]) {
      curve._segment1 = segments[end];
      curve._changed();
    }
  }

  /**
   * Returns the amount of curves this path item is supposed to have, based
   * on its amount of #segments and #closed state.
   */
  _countCurves() {
    var length = this._segments.length;
    // Reduce length by one if it's an open path:
    return !this._closed && length > 0 ? length - 1 : length;
  }

  add(segment1 /*, segment2, ... */) {
    var args = arguments;
    return args.length > 1 && typeof segment1 !== 'number'
      // addSegments
      ? this._add(Segment.readList(args))
      // addSegment
      : this._add([Segment.read(args)])[0];
  }

  insert(index, segment1 /*, segment2, ... */) {
    var args = arguments;
    return args.length > 2 && typeof segment1 !== 'number'
      // insertSegments
      ? this._add(Segment.readList(args, 1), index)
      // insertSegment
      : this._add([Segment.read(args, 1)], index)[0];
  }

  addSegment(segment) {
    return this._add([segment])[0];
  }

  insertSegment(index /*, segment */) {
    return this._add([Segment.read(arguments, 1)], index)[0];
  }

  addSegments(segments) {
    return this._add(Segment.readList(segments));
  }


  insertSegments(index, segments) {
    return this._add(Segment.readList(segments), index);
  }


  removeSegment(index) {
    return this.removeSegments(index, index + 1)[0] || null;
  }


  removeSegments(start, end, _includeCurves) {
    start = start || 0;
    end = Base.pick(end, this._segments.length);
    var segments = this._segments,
      curves = this._curves,
      count = segments.length, // segment count before removal
      removed = segments.splice(start, end - start),
      amount = removed.length;
    if (!amount)
      return removed;
    // Update selection state accordingly
    for (var i = 0; i < amount; i++) {
      var segment = removed[i];
      if (segment._selection)
        this._updateSelection(segment, segment._selection, 0);
      // Clear the indices and path references of the removed segments
      segment._index = segment._path = null;
    }
    // Adjust the indices of the segments above.
    for (var i = start, l = segments.length; i < l; i++)
      segments[i]._index = i;
    // Keep curves in sync
    if (curves) {
      // If we're removing the last segment, remove the last curve (the
      // one to the left of the segment, not to the right, as normally).
      // Also take into account closed paths, which have one curve more
      // than segments.
      var index = start > 0 && end === count + (this._closed ? 1 : 0)
        ? start - 1
        : start,
        curves = curves.splice(index, amount);
      // Unlink the removed curves from the path.
      for (var i = curves.length - 1; i >= 0; i--)
        curves[i]._path = null;
      // Return the removed curves as well, if we're asked to include
      // them, but exclude the first curve, since that's shared with the
      // previous segment and does not connect the returned segments.
      if (_includeCurves)
        removed._curves = curves.slice(1);
      // Adjust segments for the curves before and after the removed ones
      this._adjustCurves(index, index);
    }
    // Use SEGMENTS notification instead of GEOMETRY since curves are kept
    // up-to-date by _adjustCurves() and don't need notification.
    this._changed(/*#=*/Change.SEGMENTS);
    return removed;
  }


  /**
   * Checks if any of the curves in the path have curve handles set.
   *
   * @return {Boolean} {@true if the path has curve handles set}
   * @see Segment#hasHandles()
   * @see Curve#hasHandles()
   */
  hasHandles() {
    var segments = this._segments;
    for (var i = 0, l = segments.length; i < l; i++) {
      if (segments[i].hasHandles())
        return true;
    }
    return false;
  }

  /**
   * Clears the path's handles by setting their coordinates to zero,
   * turning the path into a polygon (or a polyline if it isn't closed).
   */
  clearHandles() {
    var segments = this._segments;
    for (var i = 0, l = segments.length; i < l; i++)
      segments[i].clearHandles();
  }

  /**
   * The approximate length of the path.
   *
   * @bean
   * @type Number
   */
  getLength() {
    if (this._length == null) {
      var curves = this.getCurves(),
        length = 0;
      for (var i = 0, l = curves.length; i < l; i++)
        length += curves[i].getLength();
      this._length = length;
    }
    return this._length;
  }

  /**
   * The area that the path's geometry is covering. Self-intersecting paths
   * can contain sub-areas that cancel each other out.
   *
   * @bean
   * @type Number
   */
  getArea() {

    var area = this._area;
    if (area == null) {
      var segments = this._segments,
        closed = this._closed;
      area = 0;
      for (var i = 0, l = segments.length; i < l; i++) {
        var last = i + 1 === l;
        area += Curve.getArea(Curve.getValues(
          segments[i], segments[last ? 0 : i + 1],
          // If this is the last curve and the last is not closed,
          // connect with a straight curve and ignore the handles.
          null, last && !closed));
      }
      this._area = area;
    }
    return area;
  }

  get area() {
    return this.getArea();
  }

  isFullySelected() {
    var length = this._segments.length;
    return this.isSelected() && length > 0 && this._segmentSelection
      === length * /*#=*/SegmentSelection.ALL;
  }

  setFullySelected(selected) {
    // No need to call _selectSegments() when selected is false, since
    // #setSelected() does that for us
    if (selected)
      this._selectSegments(true);
    this.setSelected(selected);
  }

  setSelection(selection) {
    // Deselect all segments when path is marked as not selected
    if (!(selection & /*#=*/ItemSelection.ITEM))
      this._selectSegments(false);
    super.setSelection(selection);
  }

  _selectSegments(selected) {
    var segments = this._segments,
      length = segments.length,
      selection = selected ? /*#=*/SegmentSelection.ALL : 0;
    this._segmentSelection = selection * length;
    for (var i = 0; i < length; i++)
      segments[i]._selection = selection;
  }

  _updateSelection(segment, oldSelection, newSelection) {
    segment._selection = newSelection;
    var selection = this._segmentSelection += newSelection - oldSelection;
    // Set this path as selected in case we have selected segments. Do not
    // unselect if we're down to 0, as the path itself can still remain
    // selected even when empty.
    if (selection > 0)
      this.setSelected(true);
  }

  /**
   * Divides the path on the curve at the given offset or location into two
   * curves, by inserting a new segment at the given location.
   *
   * @param {Number|CurveLocation} location the offset or location on the
   *     path at which to divide the existing curve by inserting a new segment
   * @return {Segment} the newly inserted segment if the location is valid,
   *     `null` otherwise
   * @see Curve#divideAt(location)
   */
  divideAt(location) {
    var loc = this.getLocationAt(location),
      curve;
    return loc && (curve = loc.getCurve().divideAt(loc.getCurveOffset()))
      ? curve._segment1
      : null;
  }

  splitAt(location) {
    // NOTE: getLocationAt() handles both offset and location:
    var loc = this.getLocationAt(location),
      index = loc && loc.index,
      time = loc && loc.time,
      tMin = /*#=*/Numerical.CURVETIME_EPSILON,
      tMax = 1 - tMin;
    if (time > tMax) {
      // time == 1 is the same location as time == 0 and index++
      index++;
      time = 0;
    }
    var curves = this.getCurves();
    if (index >= 0 && index < curves.length) {
      // Only divide curves if we're not on an existing segment already.
      if (time >= tMin) {
        // Divide the curve with the index at the given curve-time.
        // Increase because dividing adds more segments to the path.
        curves[index++].divideAtTime(time);
      }
      // Create the new path with the segments to the right of given
      // curve-time, which are removed from the current path. Pass true
      // for includeCurves, since we want to preserve and move them to
      // the new path through _add(), allowing us to have CurveLocation
      // keep the connection to the new path through moved curves.
      var segs = this.removeSegments(index, this._segments.length, true),
        path;
      if (this._closed) {
        // If the path is closed, open it and move the segments round,
        // otherwise create two paths.
        this.setClosed(false);
        // Just have path point to this. The moving around of segments
        // will happen below.
        path = this;
      } else {
        path = new Path(Item.NO_INSERT);
        path.insertAbove(this);
        path.copyAttributes(this);
      }
      path._add(segs, 0);
      // Add dividing segment again. In case of a closed path, that's the
      // beginning segment again at the end, since we opened it.
      this.addSegment(segs[0]);
      return path;
    }
    return null;
  }


  split(index, time) {
    var curve,
      location = time === undefined ? index
        : (curve = this.getCurves()[index])
        && curve.getLocationAtTime(time);
    return location != null ? this.splitAt(location) : null;
  }


  join(path, tolerance) {
    var epsilon = tolerance || 0;
    if (path && path !== this) {
      var segments = path._segments,
        last1 = this.getLastSegment(),
        last2 = path.getLastSegment();
      if (!last2) // an empty path?
        return this;
      if (last1 && last1._point.isClose(last2._point, epsilon))
        path.reverse();
      var first2 = path.getFirstSegment();
      if (last1 && last1._point.isClose(first2._point, epsilon)) {
        last1.setHandleOut(first2._handleOut);
        this._add(segments.slice(1));
      } else {
        var first1 = this.getFirstSegment();
        if (first1 && first1._point.isClose(first2._point, epsilon))
          path.reverse();
        last2 = path.getLastSegment();
        if (first1 && first1._point.isClose(last2._point, epsilon)) {
          first1.setHandleIn(last2._handleIn);
          // Prepend all segments from path except the last one.
          this._add(segments.slice(0, segments.length - 1), 0);
        } else {
          this._add(segments.slice());
        }
      }
      if (path._closed)
        this._add([segments[0]]);
      path.remove();
    }
    // If the first and last segment touch, close the resulting path and
    // merge the end segments. Also do this if no path argument was provided
    // in which cases the path is joined with itself only if its ends touch.
    var first = this.getFirstSegment(),
      last = this.getLastSegment();
    if (first !== last && first._point.isClose(last._point, epsilon)) {
      first.setHandleIn(last._handleIn);
      last.remove();
      this.setClosed(true);
    }
    return this;
  }


  reduce(options) {
    var curves = this.getCurves(),
      // TODO: Find a better name, to not confuse with PathItem#simplify()
      simplify = options && options.simplify,
      // When not simplifying, only remove curves if their lengths are
      // absolutely 0.
      tolerance = simplify ? /*#=*/Numerical.GEOMETRIC_EPSILON : 0;
    for (var i = curves.length - 1; i >= 0; i--) {
      var curve = curves[i];
      // When simplifying, compare curves with isCollinear() will remove
      // any collinear neighboring curves regardless of their orientation.
      // This serves as a reliable way to remove linear overlaps but only
      // as long as the lines are truly overlapping.
      if (!curve.hasHandles() && (!curve.hasLength(tolerance)
        || simplify && curve.isCollinear(curve.getNext())))
        curve.remove();
    }
    return this;
  }


  reverse() {
    this._segments.reverse();
    // Reverse the handles:
    for (var i = 0, l = this._segments.length; i < l; i++) {
      var segment = this._segments[i];
      var handleIn = segment._handleIn;
      segment._handleIn = segment._handleOut;
      segment._handleOut = handleIn;
      segment._index = i;
    }
    // Clear curves since it all has changed.
    this._curves = null;
    this._changed(/*#=*/Change.GEOMETRY);
  }  // NOTE: Documentation is in PathItem#flatten()
  flatten(flatness) {
    // Use PathFlattener to subdivide the curves into parts that are flat
    // enough, as specified by `flatness` / Curve.isFlatEnough():
    var flattener = new PathFlattener(this, flatness || 0.25, 256, true),
      parts = flattener.parts,
      length = parts.length,
      segments = [];
    for (var i = 0; i < length; i++) {
      segments.push(new Segment(parts[i].curve.slice(0, 2)));
    }
    if (!this._closed && length > 0) {
      // Explicitly add the end point of the last curve on open paths.
      segments.push(new Segment(parts[length - 1].curve.slice(6)));
    }
    this.setSegments(segments);
  }  // NOTE: Documentation is in PathItem#simplify()
  simplify(tolerance) {
    var segments = new PathFitter(this).fit(tolerance || 2.5);
    if (segments)
      this.setSegments(segments);
    return !!segments;
  }  // NOTE: Documentation is in PathItem#smooth()
  smooth(options) {
    var that = this,
      opts = options || {},
      type = opts.type || 'asymmetric',
      segments = this._segments,
      length = segments.length,
      closed = this._closed;

    // Helper method to pick the right from / to indices.
    // Supports numbers and segment objects.
    // For numbers, the `to` index is exclusive, while for segments and
    // curves, it is inclusive, handled by the `offset` parameter.
    function getIndex(value, _default) {
      // Support both Segment and Curve through #index getter.
      var index = value && value.index;
      if (index != null) {
        // Make sure the segment / curve is not from a wrong path.
        var path = value.path;
        if (path && path !== that)
          throw new Error(value._class + ' ' + index + ' of ' + path
            + ' is not part of ' + that);
        // Add offset of 1 to curves to reach their end segment.
        if (_default && value instanceof Curve)
          index++;
      } else {
        index = typeof value === 'number' ? value : _default;
      }
      // Handle negative values based on whether a path is open or not:
      // Ranges on closed paths are allowed to wrapped around the
      // beginning/end (e.g. start near the end, end near the beginning),
      // while ranges on open paths stay within the path's open range.
      return Math.min(index < 0 && closed
        ? index % length
        : index < 0 ? index + length : index, length - 1);
    }

    var loop = closed && opts.from === undefined && opts.to === undefined,
      from = getIndex(opts.from, 0),
      to = getIndex(opts.to, length - 1);

    if (from > to) {
      if (closed) {
        from -= length;
      } else {
        var tmp = from;
        from = to;
        to = tmp;
      }
    }
    if (/^(?:asymmetric|continuous)$/.test(type)) {
      // Continuous smoothing approach based on work by Lubos Brieda,
      // Particle In Cell Consulting LLC, but further simplified by
      // addressing handle symmetry across segments, and the possibility
      // to process x and y coordinates simultaneously. Also added
      // handling of closed paths.
      // https://www.particleincell.com/2012/bezier-splines/
      //
      // We use different parameters for the two supported smooth methods
      // that use this algorithm: continuous and asymmetric. asymmetric
      // was the only approach available in v0.9.25 & below.
      var asymmetric = type === 'asymmetric',
        min = Math.min,
        amount = to - from + 1,
        n = amount - 1,
        // Overlap by up to 4 points on closed paths since a current
        // segment is affected by its 4 neighbors on both sides (?).
        padding = loop ? min(amount, 4) : 1,
        paddingLeft = padding,
        paddingRight = padding,
        knots = [];
      if (!closed) {
        // If the path is open and a range is defined, try using a
        // padding of 1 on either side.
        paddingLeft = min(1, from);
        paddingRight = min(1, length - to - 1);
      }
      // Set up the knots array now, taking the paddings into account.
      n += paddingLeft + paddingRight;
      if (n <= 1)
        return;
      for (var i = 0, j = from - paddingLeft; i <= n; i++, j++) {
        knots[i] = segments[(j < 0 ? j + length : j) % length]._point;
      }

      // In the algorithm we treat these 3 cases:
      // - left most segment (L)
      // - internal segments (I)
      // - right most segment (R)
      //
      // In both the continuous and asymmetric method, c takes these
      // values and can hence be removed from the loop starting in n - 2:
      // c = 1 (L), 1 (I), 0 (R)
      //
      // continuous:
      // a = 0 (L), 1 (I), 2 (R)
      // b = 2 (L), 4 (I), 7 (R)
      // u = 1 (L), 4 (I), 8 (R)
      // v = 2 (L), 2 (I), 1 (R)
      //
      // asymmetric:
      // a = 0 (L), 1 (I), 1 (R)
      // b = 2 (L), 4 (I), 2 (R)
      // u = 1 (L), 4 (I), 3 (R)
      // v = 2 (L), 2 (I), 0 (R)

      // (L): u = 1, v = 2
      var x = knots[0]._x + 2 * knots[1]._x,
        y = knots[0]._y + 2 * knots[1]._y,
        f = 2,
        n_1 = n - 1,
        rx = [x],
        ry = [y],
        rf = [f],
        px = [],
        py = [];
      // Solve with the Thomas algorithm
      for (var i = 1; i < n; i++) {
        var internal = i < n_1,
          //  internal--(I)  asymmetric--(R) (R)--continuous
          a = internal ? 1 : asymmetric ? 1 : 2,
          b = internal ? 4 : asymmetric ? 2 : 7,
          u = internal ? 4 : asymmetric ? 3 : 8,
          v = internal ? 2 : asymmetric ? 0 : 1,
          m = a / f;
        f = rf[i] = b - m;
        x = rx[i] = u * knots[i]._x + v * knots[i + 1]._x - m * x;
        y = ry[i] = u * knots[i]._y + v * knots[i + 1]._y - m * y;
      }

      px[n_1] = rx[n_1] / rf[n_1];
      py[n_1] = ry[n_1] / rf[n_1];
      for (var i = n - 2; i >= 0; i--) {
        px[i] = (rx[i] - px[i + 1]) / rf[i];
        py[i] = (ry[i] - py[i + 1]) / rf[i];
      }
      px[n] = (3 * knots[n]._x - px[n_1]) / 2;
      py[n] = (3 * knots[n]._y - py[n_1]) / 2;

      // Now update the segments
      for (var i = paddingLeft, max = n - paddingRight, j = from;
           i <= max; i++, j++) {
        var segment = segments[j < 0 ? j + length : j],
          pt = segment._point,
          hx = px[i] - pt._x,
          hy = py[i] - pt._y;
        if (loop || i < max)
          segment.setHandleOut(hx, hy);
        if (loop || i > paddingLeft)
          segment.setHandleIn(-hx, -hy);
      }
    } else {
      // All other smoothing methods are handled directly on the segments:
      for (var i = from; i <= to; i++) {
        segments[i < 0 ? i + length : i].smooth(opts,
          !loop && i === from, !loop && i === to);
      }
    }
  }

  toShape(insert) {
    if (!this._closed)
      return null;

    var segments = this._segments,
      type,
      size,
      radius,
      topCenter;

    function isCollinear(i, j) {
      var seg1 = segments[i],
        seg2 = seg1.getNext(),
        seg3 = segments[j],
        seg4 = seg3.getNext();
      return seg1._handleOut.isZero() && seg2._handleIn.isZero()
        && seg3._handleOut.isZero() && seg4._handleIn.isZero()
        && seg2._point.subtract(seg1._point).isCollinear(
          seg4._point.subtract(seg3._point));
    }

    function isOrthogonal(i) {
      var seg2 = segments[i],
        seg1 = seg2.getPrevious(),
        seg3 = seg2.getNext();
      return seg1._handleOut.isZero() && seg2._handleIn.isZero()
        && seg2._handleOut.isZero() && seg3._handleIn.isZero()
        && seg2._point.subtract(seg1._point).isOrthogonal(
          seg3._point.subtract(seg2._point));
    }

    function isArc(i) {
      var seg1 = segments[i],
        seg2 = seg1.getNext(),
        handle1 = seg1._handleOut,
        handle2 = seg2._handleIn,
        kappa = /*#=*/Numerical.KAPPA;
      // Look at handle length and the distance to the imaginary corner
      // point and see if it their relation is kappa.
      if (handle1.isOrthogonal(handle2)) {
        var pt1 = seg1._point,
          pt2 = seg2._point,
          // Find the corner point by intersecting the lines described
          // by both handles:
          corner = new Line(pt1, handle1, true).intersect(
            new Line(pt2, handle2, true), true);
        return corner && Numerical.isZero(handle1.getLength() /
          corner.subtract(pt1).getLength() - kappa)
          && Numerical.isZero(handle2.getLength() /
            corner.subtract(pt2).getLength() - kappa);
      }
      return false;
    }

    function getDistance(i, j) {
      return segments[i]._point.getDistance(segments[j]._point);
    }

    // See if actually have any curves in the path. Differentiate
    // between straight objects (line, polyline, rect, and polygon) and
    // objects with curves(circle, ellipse, roundedRectangle).
    if (!this.hasHandles() && segments.length === 4
      && isCollinear(0, 2) && isCollinear(1, 3) && isOrthogonal(1)) {
      type = Shape.Rectangle;
      size = new Size(getDistance(0, 3), getDistance(0, 1));
      topCenter = segments[1]._point.add(segments[2]._point).divide(2);
    } else if (segments.length === 8 && isArc(0) && isArc(2) && isArc(4)
      && isArc(6) && isCollinear(1, 5) && isCollinear(3, 7)) {
      // It's a rounded rectangle.
      type = Shape.Rectangle;
      size = new Size(getDistance(1, 6), getDistance(0, 3));
      // Subtract side lengths from total width and divide by 2 to get the
      // corner radius size.
      radius = size.subtract(new Size(getDistance(0, 7),
        getDistance(1, 2))).divide(2);
      topCenter = segments[3]._point.add(segments[4]._point).divide(2);
    } else if (segments.length === 4
      && isArc(0) && isArc(1) && isArc(2) && isArc(3)) {
      // If the distance between (point0 and point2) and (point1
      // and point3) are equal, then it is a circle
      if (Numerical.isZero(getDistance(0, 2) - getDistance(1, 3))) {
        type = Shape.Circle;
        radius = getDistance(0, 2) / 2;
      } else {
        type = Shape.Ellipse;
        radius = new Size(getDistance(2, 0) / 2, getDistance(3, 1) / 2);
      }
      topCenter = segments[1]._point;
    }

    if (type) {
      var center = this.getPosition(true),
        shape = new type({
          center: center,
          size: size,
          radius: radius,
          insert: false
        });
      // Pass `true` to exclude the matrix, so we can prepend after
      shape.copyAttributes(this, true);
      shape._matrix.prepend(this._matrix);
      // Determine and apply the shape's angle of rotation.
      shape.rotate(topCenter.subtract(center).getAngle() + 90);
      if (insert === undefined || insert)
        shape.insertAbove(this);
      return shape;
    }
    return null;
  }


  compare(path) {
    // If a compound-path is involved, redirect to PathItem#compare()
    if (!path || path instanceof CompoundPath)
      return super.compare.call(this, path);
    var curves1 = this.getCurves(),
      curves2 = path.getCurves(),
      length1 = curves1.length,
      length2 = curves2.length;
    if (!length1 || !length2) {
      // If one path defines curves and the other doesn't, we can't have
      // matching geometries.
      return length1 == length2;
    }
    var v1 = curves1[0].getValues(),
      values2 = [],
      pos1 = 0, pos2,
      end1 = 0, end2;
    // First, loop through curves2, looking for the start of the overlapping
    // sequence with curves1[0]. Also cache curve values for later reuse.
    for (var i = 0; i < length2; i++) {
      var v2 = curves2[i].getValues();
      values2.push(v2);
      var overlaps = Curve.getOverlaps(v1, v2);
      if (overlaps) {
        // If the overlap doesn't start at the beginning of v2, then it
        // can only be a partial overlap with curves2[0], and the start
        // will be at curves2[length2 - 1]:
        pos2 = !i && overlaps[0][0] > 0 ? length2 - 1 : i;
        // Set end2 to the start of the first overlap on curves2, so
        // connection checks further down can work.
        end2 = overlaps[0][1];
        break;
      }
    }
    // Now loop through both curve arrays, find their overlaps, verify that
    // they keep joining, and see if we end back at the start on both paths.
    var abs = Math.abs,
      epsilon = /*#=*/Numerical.CURVETIME_EPSILON,
      v2 = values2[pos2],
      start2;
    while (v1 && v2) {
      var overlaps = Curve.getOverlaps(v1, v2);
      if (overlaps) {
        // Check that the overlaps are joining on curves1.
        var t1 = overlaps[0][0];
        if (abs(t1 - end1) < epsilon) {
          end1 = overlaps[1][0];
          if (end1 === 1) {
            // Skip to the next curve if we're at the end of the
            // current, and set v1 to null if at the end of curves1.
            v1 = ++pos1 < length1 ? curves1[pos1].getValues() : null;
            end1 = 0;
          }
          // Check that the overlaps are joining on curves2.
          var t2 = overlaps[0][1];
          if (abs(t2 - end2) < epsilon) {
            if (!start2)
              start2 = [pos2, t2];
            end2 = overlaps[1][1];
            if (end2 === 1) {
              // Wrap pos2 around the end on values2:
              if (++pos2 >= length2)
                pos2 = 0;
              // Reuse cached values from initial search.
              v2 = values2[pos2] || curves2[pos2].getValues();
              end2 = 0;
            }
            if (!v1) {
              // We're done with curves1. If we're not back at the
              // start on curve2, the two paths are not identical.
              return start2[0] === pos2 && start2[1] === end2;
            }
            // All good, continue to avoid the break; further down.
            continue;
          }
        }
      }
      // No overlap match found, break out early.
      break;
    }
    return false;
  }

  _hitTestSelf(point, options, viewMatrix, strokeMatrix) {
    var that = this,
      style = this.style(),
      segments = this._segments,
      numSegments = segments.length,
      closed = this._closed,
      // transformed tolerance padding, see Item#hitTest. We will add
      // stroke padding on top if stroke is defined.
      tolerancePadding = options._tolerancePadding,
      strokePadding = tolerancePadding,
      join, cap, miterLimit,
      area, loc, res,
      hitStroke = false, //options.stroke /*&& style.hasStroke()*/,
      hitFill = options.fill/* && style.hasFill()*/,
      hitCurves = true,

      strokeRadius = 0
    if (strokeRadius !== null) {
      /*if (strokeRadius > 0) {
        join = style.getStrokeJoin();
        cap = style.getStrokeCap();
        miterLimit = style.getMiterLimit();
        // Add the stroke radius to tolerance padding, taking
        // #strokeScaling into account through _getStrokeMatrix().
        strokePadding = strokePadding.add(
          Path._getStrokePadding(strokeRadius, strokeMatrix));
      } else {*/
      join = cap = 'round';
      //}
      // Using tolerance padding for fill tests will also work if there is
      // no stroke, in which case radius = 0 and we will test for stroke
      // locations to extend the fill area by tolerance.
    }

    function isCloseEnough(pt, padding) {
      return point.subtract(pt).divide(padding).length <= 1;
    }

    function checkSegmentPoint(seg, pt, name) {
      if (!options.selected || pt.isSelected()) {
        var anchor = seg._point;
        if (pt !== anchor)
          pt = pt.add(anchor);
        if (isCloseEnough(pt, strokePadding)) {
          return new HitResult(name, that, {
            segment: seg,
            point: pt
          });
        }
      }
    }

    function checkSegmentPoints(seg, ends) {
      // Note, when checking for ends, we don't also check for handles,
      // since this will happen afterwards in a separate loop, see below.
      return (ends || options.segments)
        && checkSegmentPoint(seg, seg._point, 'segment')
        || (!ends && options.handles) && (
          checkSegmentPoint(seg, seg._handleIn, 'handle-in') ||
          checkSegmentPoint(seg, seg._handleOut, 'handle-out'));
    }

    // Code to check stroke join / cap areas

    function addToArea(point) {
      area.add(point);
    }

    function checkSegmentStroke(segment) {
      // Handle joins / caps that are not round specifically, by
      // hit-testing their polygon areas.
      var isJoin = closed || segment._index > 0
        && segment._index < numSegments - 1;
      if ((isJoin ? join : cap) === 'round') {
        // Round join / cap is easy to handle.
        return isCloseEnough(segment._point, strokePadding);
      } else {
        // Create an 'internal' path without id and outside the scene
        // graph to run the hit-test on it.
        area = new Path({internal: true, closed: true});
        if (isJoin) {
          // Only add bevels to segments that aren't smooth.
          if (!segment.isSmooth()) {
            // _addBevelJoin() handles both 'bevel' and 'miter'.
            Path._addBevelJoin(segment, join, strokeRadius,
              miterLimit, null, strokeMatrix, addToArea, true);
          }
        } else if (cap === 'square') {
          Path._addSquareCap(segment, cap, strokeRadius, null,
            strokeMatrix, addToArea, true);
        }
        // See if the above produced an area to check for
        if (!area.isEmpty()) {
          // Also use stroke check with tolerancePadding if the point
          // is not inside the area itself, to use test caps and joins
          // with same tolerance.
          var loc;
          return area.contains(point)
            || (loc = area.getNearestLocation(point))
            && isCloseEnough(loc.getPoint(), tolerancePadding);
        }
      }
    }

    // If we're asked to query for segments, ends or handles, do all that
    // before stroke or fill.
    if (options.ends && !options.segments && !closed) {
      if (res = checkSegmentPoints(segments[0], true)
        || checkSegmentPoints(segments[numSegments - 1], true))
        return res;
    } else if (options.segments || options.handles) {
      for (var i = 0; i < numSegments; i++)
        if (res = checkSegmentPoints(segments[i]))
          return res;
    }
    // If we're querying for stroke, perform that before fill
    if (strokeRadius !== null) {
      loc = this.getNearestLocation(point);
      // Note that paths need at least two segments to have an actual
      // stroke. But we still check for segments with the radius fallback
      // check if there is only one segment.
      if (loc) {
        // Now see if we're on a segment, and if so, check for its
        // stroke join / cap first. If not, do a normal radius check
        // for round strokes.
        var time = loc.getTime();
        if (time === 0 || time === 1 && numSegments > 1) {
          if (!checkSegmentStroke(loc.getSegment()))
            loc = null;
        } else if (!isCloseEnough(loc.getPoint(), strokePadding)) {
          loc = null;
        }
      }
      // If we have miter joins, we may not be done yet, since they can be
      // longer than the radius. Check for each segment within reach now.
      if (!loc && join === 'miter' && numSegments > 1) {
        for (var i = 0; i < numSegments; i++) {
          var segment = segments[i];
          if (point.getDistance(segment._point)
            <= miterLimit * strokeRadius
            && checkSegmentStroke(segment)) {
            loc = segment.getLocation();
            break;
          }
        }
      }
    }
    // Don't process loc yet, as we also need to query for stroke after fill
    // in some cases. Simply skip fill query if we already have a matching
    // stroke. If we have a loc and no stroke then it's a result for fill.
    return !loc && hitFill && this._contains(point)
    || loc && !hitStroke && !hitCurves
      ? new HitResult('fill', this)
      : loc
        ? new HitResult(hitStroke ? 'stroke' : 'curve', this, {
          location: loc,
          // It's fine performance wise to call getPoint()
          // again since it was already called before.
          point: loc.getPoint()
        })
        : null;
  }

  getPointAt(offset) {
    var loc = this.getLocationAt(offset);
    return loc && loc.getPoint();
  }

  getTangentAt(offset) {
    var loc = this.getLocationAt(offset);
    return loc && loc.getTangentAt();
  }

  getNormalAt(offset) {
    var loc = this.getLocationAt(offset);
    return loc && loc.getNormalAt();
  }

  getWeightedTangentAt(offset) {
    var loc = this.getLocationAt(offset);
    return loc && loc.getWeightedTangent();
  }


  getLocationOf(point) {
    var/* point = Point.read(arguments),*/
      curves = this.getCurves();
    for (var i = 0, l = curves.length; i < l; i++) {
      var loc = curves[i].getLocationOf(point);
      if (loc)
        return loc;
    }
    return null;
  }

  /**
   * Returns the length of the path from its beginning up to up to the
   * specified point if it lies on the path, `null` otherwise.
   *
   * @param {Point} point the point on the path
   * @return {Number} the length of the path up to the specified point
   */
  getOffsetOf(/* point */) {
    var loc = this.getLocationOf.apply(this, arguments);
    return loc ? loc.getOffset() : null;
  }

  /**
   * Returns the curve location of the specified offset on the path.
   *
   * @param {Number} offset the offset on the path, where `0` is at
   * the beginning of the path and {@link Path#length} at the end
   * @return {CurveLocation} the curve location at the specified offset
   */
  getLocationAt(offset) {
    if (typeof offset === 'number') {
      var curves = this.getCurves(),
        length = 0;
      for (var i = 0, l = curves.length; i < l; i++) {
        var start = length,
          curve = curves[i];
        length += curve.getLength();
        if (length > offset) {
          // Found the segment within which the length lies
          return curve.getLocationAt(offset - start);
        }
      }
      // It may be that through imprecision of getLength, that the end of
      // the last curve was missed:
      if (curves.length > 0 && offset <= this.getLength()) {
        return new CurveLocation(curves[curves.length - 1], 1);
      }
    } else if (offset && offset.getPath && offset.getPath() === this) {
      // offset is already a CurveLocation on this path, just return it.
      return offset;
    }
    return null;
  }

  getOffsetsWithTangent(tangent) {
    //var tangent = Point.read(arguments);
    if (tangent.isZero()) {
      return [];
    }

    var offsets = [];
    var curveStart = 0;
    var curves = this.getCurves();
    for (var i = 0, l = curves.length; i < l; i++) {
      var curve = curves[i];
      // Calculate curves times at vector tangent...
      var curveTimes = curve.getTimesWithTangent(tangent);
      for (var j = 0, m = curveTimes.length; j < m; j++) {
        // ...and convert them to path offsets...
        var offset = curveStart + curve.getOffsetAtTime(curveTimes[j]);
        // ...avoiding duplicates.
        if (offsets.indexOf(offset) < 0) {
          offsets.push(offset);
        }
      }
      curveStart += curve.length;
    }
    return offsets;
  }


  _getBounds(matrix, options) {
    var method = options.handle
      ? 'getHandleBounds'
      : options.stroke
        ? 'getStrokeBounds'
        : 'getBounds';
    return Path[method](this._segments, this._closed, this, matrix, options);
  }

  static getBounds(segments, closed, path, matrix, options, strokePadding) {
    var first = segments[0];
    // If there are no segments, return "empty" rectangle, just like groups,
    // since #bounds is assumed to never return null.
    if (!first)
      return new Rectangle();
    var coords = new Array(6),
      // Make coordinates for first segment available in prevCoords.
      prevCoords = first._transformCoordinates(matrix, new Array(6)),
      min = prevCoords.slice(0, 2), // Start with values of first point
      max = min.slice(), // clone
      roots = new Array(2);

    function processSegment(segment) {
      segment._transformCoordinates(matrix, coords);
      for (var i = 0; i < 2; i++) {
        Curve._addBounds(
          prevCoords[i], // prev.point
          prevCoords[i + 4], // prev.handleOut
          coords[i + 2], // segment.handleIn
          coords[i], // segment.point,
          i, strokePadding ? strokePadding[i] : 0, min, max, roots);
      }
      // Swap coordinate buffers.
      var tmp = prevCoords;
      prevCoords = coords;
      coords = tmp;
    }

    for (var i = 1, l = segments.length; i < l; i++)
      processSegment(segments[i]);
    if (closed)
      processSegment(first);
    return new Rectangle(min[0], min[1], max[0] - min[0], max[1] - min[1]);
  }


  /**
   * Returns the bounding rectangle of the item including stroke width.
   *
   * @private
   */
  static getStrokeBounds(segments, closed, path, matrix, options) {
    var style = path.style(),
      stroke = false, //style.hasStroke(),
      strokeWidth = 0,//style.getStrokeWidth(),
      strokeMatrix = stroke && path._getStrokeMatrix(matrix, options),
      strokePadding = stroke && Path._getStrokePadding(strokeWidth,
        strokeMatrix),
      // Start with normal path bounds with added stroke padding. Then we
      // only need to look at each segment and handle join / cap / miter.
      bounds = Path.getBounds(segments, closed, path, matrix, options,
        strokePadding);
    if (!stroke)
      return bounds;
    var strokeRadius = strokeWidth / 2,
      join = style.getStrokeJoin(),
      cap = style.getStrokeCap(),
      miterLimit = style.getMiterLimit(),
      // Create a rectangle of padding size, used for union with bounds
      // further down
      joinBounds = new Rectangle(new Size(strokePadding));

    // helper function that is passed to _addBevelJoin() and _addSquareCap()
    // to handle the point transformations.
    function addPoint(point) {
      bounds = bounds.include(point);
    }

    function addRound(segment) {
      bounds = bounds.unite(
        joinBounds.setCenter(segment._point.transform(matrix)));
    }

    function addJoin(segment, join) {
      // When both handles are set in a segment and they are collinear,
      // the join setting is ignored and round is always used.
      if (join === 'round' || segment.isSmooth()) {
        addRound(segment);
      } else {
        // _addBevelJoin() handles both 'bevel' and 'miter' joins.
        Path._addBevelJoin(segment, join, strokeRadius, miterLimit,
          matrix, strokeMatrix, addPoint);
      }
    }

    function addCap(segment, cap) {
      if (cap === 'round') {
        addRound(segment);
      } else {
        // _addSquareCap() handles both 'square' and 'butt' caps.
        Path._addSquareCap(segment, cap, strokeRadius, matrix,
          strokeMatrix, addPoint);
      }
    }

    var length = segments.length - (closed ? 0 : 1);
    if (length > 0) {
      for (var i = 1; i < length; i++) {
        addJoin(segments[i], join);
      }
      if (closed) {
        // Go back to the beginning
        addJoin(segments[0], join);
      } else {
        // Handle caps on open paths
        addCap(segments[0], cap);
        addCap(segments[segments.length - 1], cap);
      }
    }
    return bounds;
  }

  /**
   * Returns the horizontal and vertical padding that a transformed round
   * stroke adds to the bounding box, by calculating the dimensions of a
   * rotated ellipse.
   */
  static _getStrokePadding(radius, matrix) {
    if (!matrix)
      return [radius, radius];
    // If a matrix is provided, we need to rotate the stroke circle
    // and calculate the bounding box of the resulting rotated ellipse:
    // Get rotated hor and ver vectors, and determine rotation angle
    // and ellipse values from them:
    var hor = new Point(radius, 0).transform(matrix),
      ver = new Point(0, radius).transform(matrix),
      phi = hor.getAngleInRadians(),
      a = hor.getLength(),
      b = ver.getLength();
    // Formula for rotated ellipses:
    // x = cx + a*cos(t)*cos(phi) - b*sin(t)*sin(phi)
    // y = cy + b*sin(t)*cos(phi) + a*cos(t)*sin(phi)
    // Derivatives (by Wolfram Alpha):
    // derivative of x = cx + a*cos(t)*cos(phi) - b*sin(t)*sin(phi)
    // dx/dt = a sin(t) cos(phi) + b cos(t) sin(phi) = 0
    // derivative of y = cy + b*sin(t)*cos(phi) + a*cos(t)*sin(phi)
    // dy/dt = b cos(t) cos(phi) - a sin(t) sin(phi) = 0
    // This can be simplified to:
    // tan(t) = -b * tan(phi) / a // x
    // tan(t) =  b * cot(phi) / a // y
    // Solving for t gives:
    // t = pi * n - arctan(b * tan(phi) / a) // x
    // t = pi * n + arctan(b * cot(phi) / a)
    //   = pi * n + arctan(b / tan(phi) / a) // y
    var sin = Math.sin(phi),
      cos = Math.cos(phi),
      tan = Math.tan(phi),
      tx = Math.atan2(b * tan, a),
      ty = Math.atan2(b, tan * a);
    // Due to symmetry, we don't need to cycle through pi * n solutions:
    return [Math.abs(a * Math.cos(tx) * cos + b * Math.sin(tx) * sin),
      Math.abs(b * Math.sin(ty) * cos + a * Math.cos(ty) * sin)];
  }

  static _addBevelJoin(segment, join, radius, miterLimit, matrix,
                       strokeMatrix, addPoint, isArea) {
    // Handles both 'bevel' and 'miter' joins, as they share a lot of code,
    // using different matrices to transform segment points and stroke
    // vectors to support Style#strokeScaling.
    var curve2 = segment.getCurve(),
      curve1 = curve2.getPrevious(),
      point = curve2.point1().transform(matrix),
      normal1 = curve1.getNormalAtTime(1).multiply(radius)
        .transform(strokeMatrix),
      normal2 = curve2.getNormalAtTime(0).multiply(radius)
        .transform(strokeMatrix),
      angle = normal1.getDirectedAngle(normal2);
    if (angle < 0 || angle >= 180) {
      normal1 = normal1.negate();
      normal2 = normal2.negate();
    }
    if (isArea)
      addPoint(point);
    addPoint(point.add(normal1));
    if (join === 'miter') {
      // Intersect the two lines
      var corner = new Line(point.add(normal1),
        new Point(-normal1.y, normal1.x), true
      ).intersect(new Line(point.add(normal2),
        new Point(-normal2.y, normal2.x), true
      ), true);
      // See if we actually get a bevel point and if its distance is below
      // the miterLimit. If not, make a normal bevel.
      if (corner && point.getDistance(corner) <= miterLimit * radius) {
        addPoint(corner);
      }
    }
    // Produce a normal bevel
    addPoint(point.add(normal2));
  }

  static _addSquareCap(segment, cap, radius, matrix, strokeMatrix,
                       addPoint, isArea) {
    // Handles both 'square' and 'butt' caps, as they share a lot of code.
    // Calculate the corner points of butt and square caps, using different
    // matrices to transform segment points and stroke vectors to support
    // Style#strokeScaling.
    var point = segment._point.transform(matrix),
      loc = segment.getLocation(),
      // Checking loc.getTime() for 0 is to see whether this is the first
      // or the last segment of the open path, in order to determine in
      // which direction to flip the normal.
      normal = loc.getNormal()
        .multiply(loc.getTime() === 0 ? radius : -radius)
        .transform(strokeMatrix);
    // For square caps, we need to step away from point in the direction of
    // the tangent, which is the rotated normal.
    if (cap === 'square') {
      if (isArea) {
        addPoint(point.subtract(normal));
        addPoint(point.add(normal));
      }
      point = point.add(normal.rotate(-90));
    }
    addPoint(point.add(normal));
    addPoint(point.subtract(normal));
  }

  /**
   * Returns the bounding rectangle of the item including handles.
   *
   * @private
   */
  static getHandleBounds(segments, closed, path, matrix, options) {
    var style = path.style(),
      stroke = options.stroke/* && style.hasStroke()*/,
      strokePadding,
      joinPadding;
    /* if (stroke) {
       var strokeMatrix = path._getStrokeMatrix(matrix, options),
         strokeRadius = style.getStrokeWidth() / 2,
         joinRadius = strokeRadius;
       if (style.getStrokeJoin() === 'miter')
         joinRadius = strokeRadius * style.getMiterLimit();
       if (style.getStrokeCap() === 'square')
         joinRadius = Math.max(joinRadius, strokeRadius * Math.SQRT2);
       strokePadding = Path._getStrokePadding(strokeRadius, strokeMatrix);
       joinPadding = Path._getStrokePadding(joinRadius, strokeMatrix);
     }*/
    var coords = new Array(6),
      x1 = Infinity,
      x2 = -x1,
      y1 = x1,
      y2 = x2;
    for (var i = 0, l = segments.length; i < l; i++) {
      var segment = segments[i];
      segment._transformCoordinates(matrix, coords);
      for (var j = 0; j < 6; j += 2) {
        // Use different padding for points or handles
        var padding = !j ? joinPadding : strokePadding,
          paddingX = padding ? padding[0] : 0,
          paddingY = padding ? padding[1] : 0,
          x = coords[j],
          y = coords[j + 1],
          xn = x - paddingX,
          xx = x + paddingX,
          yn = y - paddingY,
          yx = y + paddingY;
        if (xn < x1) x1 = xn;
        if (xx > x2) x2 = xx;
        if (yn < y1) y1 = yn;
        if (yx > y2) y2 = yx;
      }
    }
    return new Rectangle(x1, y1, x2 - x1, y2 - y1);
  }

  static getCurrentSegment(that) {
    var segments = that._segments;
    if (!segments.length)
      throw new Error('Use a moveTo() command first');
    return segments[segments.length - 1];
  }

  moveTo(point) {
    // moveTo should only be called at the beginning of paths. But it
    // can ce called again if there is nothing drawn yet, in which case
    // the first segment gets readjusted.
    var segments = this._segments;
    if (segments.length === 1)
      this.removeSegment(0);
    // Let's not be picky about calling moveTo() when not at the
    // beginning of a path, just bail out:
    if (!segments.length)
      this._add([new Segment(point)]);
  }

  moveBy(/* point */) {
    throw new Error('moveBy() is unsupported on Path items.');
  }

  lineTo(point) {
    // Let's not be picky about calling moveTo() first:
    this._add([new Segment(point)]);
  }

  cubicCurveTo(handle1, handle2, to) {
    /* var args = arguments,
       handle1 = Point.read(args),
       handle2 = Point.read(args),
       to = Point.read(args),*/
    // First modify the current segment:
    const current = Path.getCurrentSegment(this);
    // Convert to relative values:
    current.setHandleOut(handle1.subtract(current._point));
    // And add the new segment, with handleIn set to c2
    this._add([new Segment(to, handle2.subtract(to))]);
  }

  quadraticCurveTo(handle, to) {
    //var args = arguments,
    /*      handle = Point.read(args),
          to = Point.read(args),*/
    const current = Path.getCurrentSegment(this)._point;
    // This is exact:
    // If we have the three quad points: A E D,
    // and the cubic is A B C D,
    // B = E + 1/3 (A - E)
    // C = E + 1/3 (D - E)
    this.cubicCurveTo(
      handle.add(current.subtract(handle).multiply(1 / 3)),
      handle.add(to.subtract(handle).multiply(1 / 3)),
      to
    );
  }

  curveTo(through, to, time = 0.5) {
    var /*args = arguments,
      through = Point.read(args),
      to = Point.read(args),
      t = Base.pick(Base.read(args), 0.5),*/
      t1 = 1 - t,
      current = Path.getCurrentSegment(this)._point,
      // handle = (through - (1 - t)^2 * current - t^2 * to) /
      // (2 * (1 - t) * t)
      handle = through.subtract(current.multiply(t1 * t1))
        .subtract(to.multiply(t * t)).divide(2 * t * t1);
    if (handle.isNaN())
      throw new Error(
        'Cannot put a curve through points with parameter = ' + t);
    this.quadraticCurveTo(handle, to);
  }
  arcTo2(through, to) {
    // Get the start point:
    var args = arguments,
      abs = Math.abs,
      sqrt = Math.sqrt,
      current = Path.getCurrentSegment(this),
      from = current._point,

      peek = to,
      clockwise = to,
      center, extent, vector, matrix;
    // We're handling three different approaches to drawing arcs in one
    // large function:
    if (typeof clockwise === 'boolean') {
      // #1: arcTo(to, clockwise)
      var middle = from.add(to).divide(2),
        through = middle.add(middle.subtract(from).rotate(
          clockwise ? -90 : 90));
    }
    if (through) {
      // Calculate center, vector and extend for non SVG versions:
      // Construct the two perpendicular middle lines to
      // (from, through) and (through, to), and intersect them to get
      // the center.
      var l1 = new Line(from.add(through).divide(2),
        through.subtract(from).rotate(90), true),
        l2 = new Line(through.add(to).divide(2),
          to.subtract(through).rotate(90), true),
        line = new Line(from, to),
        throughSide = line.getSide(through);
      center = l1.intersect(l2, true);
      // If the two lines are collinear, there cannot be an arc as the
      // circle is infinitely big and has no center point. If side is
      // 0, the connecting arc line of this huge circle is a line
      // between the two points, so we can use #lineTo instead.
      // Otherwise we bail out:
      if (!center) {
        if (!throughSide)
          return this.lineTo(to);
        throw new Error(
          'Cannot create an arc with the given arguments');
      }
      vector = from.subtract(center);
      extent = vector.getDirectedAngle(to.subtract(center));
      var centerSide = line.getSide(center, true);
      if (centerSide === 0) {
        // If the center is lying on the line, we might have gotten
        // the wrong sign for extent above. Use the sign of the side
        // of the through point.
        extent = throughSide * abs(extent);
      } else if (throughSide === centerSide) {
        // If the center is on the same side of the line (from, to)
        // as the through point, we're extending bellow 180 degrees
        // and need to adapt extent.
        extent += extent < 0 ? 360 : -360;
      }
    }
    if (extent) {
      var epsilon = /*#=*/Numerical.GEOMETRIC_EPSILON,
        ext = abs(extent),
        // Calculate amount of segments required to approximate over
        // `extend` degrees (extend / 90), but prevent ceil() from
        // rounding up small imprecisions by subtracting epsilon.
        count = ext >= 360 ? 4 : Math.ceil((ext - epsilon) / 90),
        inc = extent / count,
        half = inc * Math.PI / 360,
        z = 4 / 3 * Math.sin(half) / (1 + Math.cos(half)),
        segments = [];
      for (var i = 0; i <= count; i++) {
        // Explicitly use to point for last segment, since depending
        // on values the calculation adds imprecision:
        var pt = to,
          out = null;
        if (i < count) {
          out = vector.rotate(90).multiply(z);
          if (matrix) {
            pt = matrix._transformPoint(vector);
            out = matrix._transformPoint(vector.add(out))
              .subtract(pt);
          } else {
            pt = center.add(vector);
          }
        }
        if (!i) {
          // Modify startSegment
          current.setHandleOut(out);
        } else {
          // Add new Segment
          var _in = vector.rotate(-90).multiply(z);
          if (matrix) {
            _in = matrix._transformPoint(vector.add(_in))
              .subtract(pt);
          }
          segments.push(new Segment(pt, _in, out));
        }
        vector = vector.rotate(inc);
      }
      // Add all segments at once at the end for higher performance
      this._add(segments);
    }
  }
  arcTo(/* to, clockwise | through, to
                | to, radius, rotation, clockwise, large */) {
    // Get the start point:
    var args = arguments,
      abs = Math.abs,
      sqrt = Math.sqrt,
      current = Path.getCurrentSegment(this),
      from = current._point,
      to = Point.read(args),
      through,
      peek = Base.peek(args),
      clockwise = Base.pick(peek, true),
      center, extent, vector, matrix;
    // We're handling three different approaches to drawing arcs in one
    // large function:
    if (typeof clockwise === 'boolean') {
      // #1: arcTo(to, clockwise)
      var middle = from.add(to).divide(2),
        through = middle.add(middle.subtract(from).rotate(
          clockwise ? -90 : 90));
    } else if (Base.remain(args) <= 2) {
      // #2: arcTo(through, to)
      through = to;
      to = Point.read(args);
    } else if (!from.equals(to)) {
      // #3: arcTo(to, radius, rotation, clockwise, large)
      // Draw arc in SVG style, but only if `from` and `to` are not
      // equal (#1613).
      var radius = Size.read(args),
        isZero = Numerical.isZero;
      // If rx = 0 or ry = 0 then this arc is treated as a
      // straight line joining the endpoints.
      // NOTE: radius.isZero() would require both values to be 0.
      if (isZero(radius.width) || isZero(radius.height))
        return this.lineTo(to);
      // See for an explanation of the following calculations:
      // https://www.w3.org/TR/SVG/implnote.html#ArcImplementationNotes
      var rotation = Base.read(args),
        clockwise = !!Base.read(args),
        large = !!Base.read(args),
        middle = from.add(to).divide(2),
        pt = from.subtract(middle).rotate(-rotation),
        x = pt.x,
        y = pt.y,
        rx = abs(radius.width),
        ry = abs(radius.height),
        rxSq = rx * rx,
        rySq = ry * ry,
        xSq = x * x,
        ySq = y * y;
      // "...ensure radii are large enough"
      var factor = sqrt(xSq / rxSq + ySq / rySq);
      if (factor > 1) {
        rx *= factor;
        ry *= factor;
        rxSq = rx * rx;
        rySq = ry * ry;
      }
      factor = (rxSq * rySq - rxSq * ySq - rySq * xSq) /
        (rxSq * ySq + rySq * xSq);
      if (abs(factor) < /*#=*/Numerical.EPSILON)
        factor = 0;
      if (factor < 0)
        throw new Error(
          'Cannot create an arc with the given arguments');
      center = new Point(rx * y / ry, -ry * x / rx)
        // "...where the + sign is chosen if fA != fS,
        // and the - sign is chosen if fA = fS."
        .multiply((large === clockwise ? -1 : 1) * sqrt(factor))
        .rotate(rotation).add(middle);
      // Now create a matrix that maps the unit circle to the ellipse,
      // for easier construction below.
      matrix = new Matrix().translate(center).rotate(rotation)
        .scale(rx, ry);
      // Transform from and to to the unit circle coordinate space
      // and calculate start vector and extend from there.
      vector = matrix._inverseTransform(from);
      extent = vector.getDirectedAngle(matrix._inverseTransform(to));
      // "...if fS = 0 and extent is > 0, then subtract 360, whereas
      // if fS = 1 and extend is < 0, then add 360."
      if (!clockwise && extent > 0)
        extent -= 360;
      else if (clockwise && extent < 0)
        extent += 360;
    }
    if (through) {
      // Calculate center, vector and extend for non SVG versions:
      // Construct the two perpendicular middle lines to
      // (from, through) and (through, to), and intersect them to get
      // the center.
      var l1 = new Line(from.add(through).divide(2),
        through.subtract(from).rotate(90), true),
        l2 = new Line(through.add(to).divide(2),
          to.subtract(through).rotate(90), true),
        line = new Line(from, to),
        throughSide = line.getSide(through);
      center = l1.intersect(l2, true);
      // If the two lines are collinear, there cannot be an arc as the
      // circle is infinitely big and has no center point. If side is
      // 0, the connecting arc line of this huge circle is a line
      // between the two points, so we can use #lineTo instead.
      // Otherwise we bail out:
      if (!center) {
        if (!throughSide)
          return this.lineTo(to);
        throw new Error(
          'Cannot create an arc with the given arguments');
      }
      vector = from.subtract(center);
      extent = vector.getDirectedAngle(to.subtract(center));
      var centerSide = line.getSide(center, true);
      if (centerSide === 0) {
        // If the center is lying on the line, we might have gotten
        // the wrong sign for extent above. Use the sign of the side
        // of the through point.
        extent = throughSide * abs(extent);
      } else if (throughSide === centerSide) {
        // If the center is on the same side of the line (from, to)
        // as the through point, we're extending bellow 180 degrees
        // and need to adapt extent.
        extent += extent < 0 ? 360 : -360;
      }
    }
    if (extent) {
      var epsilon = /*#=*/Numerical.GEOMETRIC_EPSILON,
        ext = abs(extent),
        // Calculate amount of segments required to approximate over
        // `extend` degrees (extend / 90), but prevent ceil() from
        // rounding up small imprecisions by subtracting epsilon.
        count = ext >= 360 ? 4 : Math.ceil((ext - epsilon) / 90),
        inc = extent / count,
        half = inc * Math.PI / 360,
        z = 4 / 3 * Math.sin(half) / (1 + Math.cos(half)),
        segments = [];
      for (var i = 0; i <= count; i++) {
        // Explicitly use to point for last segment, since depending
        // on values the calculation adds imprecision:
        var pt = to,
          out = null;
        if (i < count) {
          out = vector.rotate(90).multiply(z);
          if (matrix) {
            pt = matrix._transformPoint(vector);
            out = matrix._transformPoint(vector.add(out))
              .subtract(pt);
          } else {
            pt = center.add(vector);
          }
        }
        if (!i) {
          // Modify startSegment
          current.setHandleOut(out);
        } else {
          // Add new Segment
          var _in = vector.rotate(-90).multiply(z);
          if (matrix) {
            _in = matrix._transformPoint(vector.add(_in))
              .subtract(pt);
          }
          segments.push(new Segment(pt, _in, out));
        }
        vector = vector.rotate(inc);
      }
      // Add all segments at once at the end for higher performance
      this._add(segments);
    }
  }

  lineBy(to) {
    var /*to = Point.read(arguments),*/
      current = Path.getCurrentSegment(this)._point;
    this.lineTo(current.add(to));
  }

  curveBy(through, to, parameter) {
    var/* args = arguments,
      through = Point.read(args),
      to = Point.read(args),
      parameter = Base.read(args),*/
      current = Path.getCurrentSegment(this)._point;
    this.curveTo(current.add(through), current.add(to), parameter);
  }

  cubicCurveBy(handle1, handle2, to) {
    var /*args = arguments,
      handle1 = Point.read(args),
      handle2 = Point.read(args),
      to = Point.read(args),*/
      current = Path.getCurrentSegment(this)._point;
    this.cubicCurveTo(current.add(handle1), current.add(handle2),
      current.add(to));
  }

  quadraticCurveBy(handle, to) {
    var a/*rgs = arguments,
      handle = Point.read(args),
      to = Point.read(args),*/
    current = Path.getCurrentSegment(this)._point;
    this.quadraticCurveTo(current.add(handle), current.add(to));
  }

  // TODO: Implement version for: (to, radius, rotation, clockwise, large)
  arcBy(/* to, clockwise | through, to */) {
    var args = arguments,
      current = Path.getCurrentSegment(this)._point,
      point = current.add(Point.read(args)),
      // Peek at next value to see if it's clockwise, with true as
      // default value.
      clockwise = Base.pick(Base.peek(args), true);
    if (typeof clockwise === 'boolean') {
      this.arcTo(point, clockwise);
    } else {
      this.arcTo(point, current.add(Point.read(args)));
    }
  }

  closePath(tolerance) {
    this.setClosed(true);
    this.join(this, tolerance);
  }

  relativePoint(bounds) {


    /*let point = null;
    const _bounds = this.getBounds()
    const bounds = {
      x : _bounds.getX(),
      y : _bounds.getY(),
      width : _bounds.getWidth(),
      height : _bounds.getHeight(),
    }*/
    return this._segments.map(function (seg) {
      //[x1 * shapeObj.width + shapeObj.x, y1 * shapeObj.height + shapeObj.y],
      let point = {
        point: [
          (seg.point.x - bounds.x) / bounds.width,
          (seg.point.y - bounds.y) / bounds.height
        ],
      }
      if (!seg.handleIn.isZero()) {
        point.inControl = [
          (seg.handleIn.x + (seg.point.x - bounds.x)) / bounds.width,
          (seg.handleIn.y + (seg.point.y - bounds.y)) / bounds.height
        ]
      }
      if (!seg.handleOut.isZero()) {
        point.outControl = [
          (seg.handleOut.x + (seg.point.x - bounds.x)) / bounds.width,
          (seg.handleOut.y + (seg.point.y - bounds.y)) / bounds.height
        ]
      }
      point.angle = seg.point.angle

      if (seg.curve && seg.curve.length) {
        point.length = seg.curve.length

      }
      return point
    });
  }

  get points() {
   return this.segments.map(({point, handleIn, handleOut}) => {
      let p = {
        point: [point.x, point.y]
      }
      if (handleIn) p.handleIn = [handleIn.x, handleIn.y]
      if (handleOut)  p.handleOut = [handleOut.x, handleOut.y]
      return p
    })
  }

  get arr() {
    return this.segments.map(({point, handleIn, handleOut}) => {
      let p = [[point.x, point.y]]
      if (handleIn) p.push([handleIn.x, handleIn.y])
      if (handleOut)p.push([handleOut.x, handleOut.y])
      return p
    })
  }
}


export class CompoundPath extends PathItem {


  initialize(arg) {

    // CompoundPath has children and supports named children.
    this._children = [];
    this._namedChildren = {};

    if (!this._initialize(arg)) {
      if (typeof arg === 'string') {
        this.setPathData(arg);
      } else {


        this.addChildren(Array.isArray(arg) ? arg : arguments);
      }
    }
  }

  insertChildren(index, items) {
    // If we're passed a segment array describing a simple path instead of a
    // compound-path, wrap it in another array to turn it into the array
    // notation for compound-paths.
    var list = items,
      first = list[0];
    if (first && typeof first[0] === 'number')
      list = [list];
    // Perform some conversions depending on the type of item passed:
    // Convert array-notation to paths, and expand compound-paths in the
    // items list by adding their children to the it replacing their parent.
    for (var i = items.length - 1; i >= 0; i--) {
      var item = list[i];
      // Clone the list array before modifying it, as it may be a passed
      // children array from another item.
      if (list === items && !(item instanceof Path))
        list = Base.slice(list);
      if (Array.isArray(item)) {
        list[i] = new Path({segments: item, insert: false});
      }  else if (item && typeof item === 'object' && item.points && Array.isArray(item.points)) {

        list[i] = new Path({segments: item.points, insert: false});
        list[i].depth = item.depth || 0
      }  else if (item instanceof CompoundPath) {
        list.splice.apply(list, [i, 1].concat(item.removeChildren()));
        item.remove();
      }
    }
    return super.insertChildren.call(this, index, list);
  }


  reduce(options) {
    var children = this._children;
    for (var i = children.length - 1; i >= 0; i--) {
      var path = children[i].reduce(options);
      if (path.isEmpty())
        path.remove();
    }
    if (!children.length) { // Replace with a simple empty Path
      var path = new Path(Item.NO_INSERT);
      path.copyAttributes(this);
      path.insertAbove(this);
      this.remove();
      return path;
    }
    return super.reduce.call(this);
  }


  isClosed() {
    var children = this._children;
    for (var i = 0, l = children.length; i < l; i++) {
      if (!children[i]._closed)
        return false;
    }
    return true;
  }

  setClosed(closed) {
    var children = this._children;
    for (var i = 0, l = children.length; i < l; i++) {
      children[i].setClosed(closed);
    }
  }

  set closed(closed) {
    this.setClosed(closed)
  }

  getFirstSegment() {
    var first = this.getFirstChild();
    return first && first.getFirstSegment();
  }


  getLastSegment() {
    var last = this.getLastChild();
    return last && last.getLastSegment();
  }


  getCurves() {
    var children = this._children,
      curves = [];
    for (var i = 0, l = children.length; i < l; i++) {
      Base.push(curves, children[i].getCurves());
    }
    return curves;
  }

  get curves() {
    return this.getCurves();
  }

  getFirstCurve() {
    var first = this.getFirstChild();
    return first && first.getFirstCurve();
  }


  getLastCurve() {
    var last = this.getLastChild();
    return last && last.getLastCurve();
  }


  getArea() {
    var children = this._children,
      area = 0;
    for (var i = 0, l = children.length; i < l; i++)
      area += children[i].getArea();
    return area;
  }


  getLength() {
    var children = this._children,
      length = 0;
    for (var i = 0, l = children.length; i < l; i++)
      length += children[i].getLength();
    return length;
  }

  get length() {
    return this.getLength();
  }

  getPathData(_matrix, _precision) {
    // NOTE: #setPathData() is defined in PathItem.
    var children = this._children,
      paths = [];
    for (var i = 0, l = children.length; i < l; i++) {
      var child = children[i],
        mx = child._matrix;
      paths.push(child.getPathData(_matrix && !mx.isIdentity()
        ? _matrix.appended(mx) : _matrix, _precision));
    }
    return paths.join('');
  }

  _hitTestChildren(point, options, viewMatrix) {
    return super._hitTestChildren.call(this, point,
      // If we're not specifically asked to returns paths through
      // options.class == Path, do not test children for fill, since a
      // compound path forms one shape.
      // Also support legacy format `type: 'path'`.
      options.class === Path || options.type === 'path' ? options
        : Object.assign({}, options, {fill: false}),
      viewMatrix);
  }


  static getCurrentPath(that, check) {
    var children = that._children;
    if (check && !children.length)
      throw new Error('Use a moveTo() command first');
    return children[children.length - 1];
  }

  lineTo() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.lineTo.apply(path, arguments);
  }

  cubicCurveTo() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.cubicCurveTo.apply(path, arguments);
  }

  quadraticCurveTo() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.quadraticCurveTo.apply(path, arguments);
  }

  curveTo() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.curveTo.apply(path, arguments);
  }

  arcTo() {
    var path = CompoundPath.getCurrentPath(this, true);
    path[key].apply(path, arguments);
  }

  lineBy() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.lineBy.apply(path, arguments);
  }

  cubicCurveBy() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.cubicCurveBy.apply(path, arguments);
  }

  quadraticCurveBy() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.quadraticCurveBy.apply(path, arguments);
  }

  curveBy() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.curveBy.apply(path, arguments);
  }

  arcBy() {
    var path = CompoundPath.getCurrentPath(this, true);
    path.arcBy.apply(path, arguments);
  }

  moveTo(point) {
    var current = CompoundPath.getCurrentPath(this),
      // Reuse current path if nothing was added yet
      path = current && current.isEmpty() ? current
        : new Path(Item.NO_INSERT);
    if (path !== current)
      this.addChild(path);
    path.moveTo.apply(path, arguments);
  }

  moveBy(point) {
    var current = CompoundPath.getCurrentPath(this, true),
      last = current && current.getLastSegment()
    this.moveTo(last ? point.add(last._point) : point);
  }

  closePath(tolerance) {
    CompoundPath.getCurrentPath(this, true).closePath(tolerance);
  }

  reverse(param) {
    var children = this._children,
      res;
    for (var i = 0, l = children.length; i < l; i++) {
      res = children[i].reverse(param) || res;
    }
    return res;
  }

  flatten(param) {
    var children = this._children,
      res;
    for (var i = 0, l = children.length; i < l; i++) {
      res = children[i].flatten(param) || res;
    }
    return res;
  }

  simplify(param) {
    var children = this._children,
      res;
    for (var i = 0, l = children.length; i < l; i++) {
      res = children[i].simplify(param) || res;
    }
    return res;
  }

  smooth(param) {
    var children = this._children,
      res;
    for (var i = 0, l = children.length; i < l; i++) {
      res = children[i].smooth(param) || res;
    }
    return res;
  }

  // Redirect all other drawing commands to the current path
  /*return Base.each(['lineTo', 'cubicCurveTo', 'quadraticCurveTo', 'curveTo',
          'arcTo', 'lineBy', 'cubicCurveBy', 'quadraticCurveBy', 'curveBy',
          'arcBy'],
      function(key) {
          this[key] = function() {
              var path = getCurrentPath(this, true);
              path[key].apply(path, arguments);
          };
      }, {
          // NOTE: Documentation for these methods is found in PathItem, as
          // they are considered abstract methods of PathItem and need to be
          // defined in all implementing classes.
          moveTo: function(/!* point *!/) {
              var current = getCurrentPath(this),
                  // Reuse current path if nothing was added yet
                  path = current && current.isEmpty() ? current
                          : new Path(Item.NO_INSERT);
              if (path !== current)
                  this.addChild(path);
              path.moveTo.apply(path, arguments);
          },

          moveBy: function(/!* point *!/) {
              var current = getCurrentPath(this, true),
                  last = current && current.getLastSegment(),
                  point = Point.read(arguments);
              this.moveTo(last ? point.add(last._point) : point);
          },

          closePath: function(tolerance) {
              getCurrentPath(this, true).closePath(tolerance);
          }
      }
  );
  Base.each(['reverse', 'flatten', 'simplify', 'smooth'], function(key) {
  // Injection scope for methods forwarded to the child paths.
  // NOTE: Documentation is in PathItem
  this[key] = function(param) {
      var children = this._children,
          res;
      for (var i = 0, l = children.length; i < l; i++) {
          res = children[i][key](param) || res;
      }
      return res;
  };
}
*/
}

export class Group extends Item{
  /*_class: 'Group',
  _selectBounds: false,
  _selectChildren: true,
  _serializeFields: {
    children: []
  },*/
  constructor() {
    super(...arguments)
  }
  initialize(arg) {

    // Allow Group to have children and named children
    this._children = [];
    this._namedChildren = {};
    if (!this._initialize(arg))
      this.addChildren(Array.isArray(arg) ? arg : arguments);
  }

  _changed(flags) {
    super._changed.call(this, flags);
    if (flags & /*#=*/(ChangeFlag.CHILDREN | ChangeFlag.CLIPPING)) {
      // Clear cached clip item whenever hierarchy changes
      this._clipItem = undefined;
    }
  }

  _getClipItem() {
    // NOTE: _clipItem is the child that has _clipMask set to true.
    var clipItem = this._clipItem;
    // Distinguish null (no clipItem set) and undefined (clipItem was not
    // looked for yet).
    if (clipItem === undefined) {
      clipItem = null;
      var children = this._children;
      for (var i = 0, l = children.length; i < l; i++) {
        if (children[i]._clipMask) {
          clipItem = children[i];
          break;
        }
      }
      this._clipItem = clipItem;
    }
    return clipItem;
  }
  isClipped() {
    return !!this._getClipItem();
  }

  setClipped(clipped) {
    var child = this.getFirstChild();
    if (child)
      child.setClipMask(clipped);
  }

  _getBounds(matrix, options) {
    var clipItem = this._getClipItem();
    return clipItem
      ? clipItem._getCachedBounds(clipItem._matrix.prepended(matrix),
        Base.set({}, options, { stroke: false }))
      : super._getBounds.call(this, matrix, options);
  }

  _hitTestChildren(point, options, viewMatrix) {
    var clipItem = this._getClipItem();
    return (!clipItem || clipItem.contains(point))
      && super._hitTestChildren.call(this, point, options, viewMatrix,
        // Pass clipItem for hidden _exclude parameter
        clipItem);
  }

  _draw(ctx, param) {
    var clip = param.clip,
      clipItem = !clip && this._getClipItem();
    param = param.extend({ clipItem: clipItem, clip: false });
    if (clip) {
      // If told to clip with a group, we start our own path and draw each
      // child just like in a compound-path.
      ctx.beginPath();
      param.dontStart = param.dontFinish = true;
    } else if (clipItem) {
      clipItem.draw(ctx, param.extend({ clip: true }));
    }
    var children = this._children;
    for (var i = 0, l = children.length; i < l; i++) {
      var item = children[i];
      if (item !== clipItem)
        item.draw(ctx, param);
    }
  }
}

export {HitResult as HitResult} from './hitresult'
export {Point} from './point'
export {Segment} from './segment'
export {Rectangle} from './rectangle'
export {Size} from './sizes'




