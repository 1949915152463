// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.object-align-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: -8px;
}

.object-align-control .object-align-item {
  padding: 1px 2px 0 2px;

  display: block;
  border-radius: 4px;
  cursor: pointer;
}

.object-align-control .object-align-item.selected {

}

.object-align-control .object-align-item .icon {
  background: #333;
}

.object-align-control .object-align-item:hover .icon {
  background: #333;
}

.object-align-control .object-align-item.selected .icon {
  background: #333;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ObjectAlignControl.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;;EAEtB,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;AAEA;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".object-align-control {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  margin-right: -8px;\n}\n\n.object-align-control .object-align-item {\n  padding: 1px 2px 0 2px;\n\n  display: block;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.object-align-control .object-align-item.selected {\n\n}\n\n.object-align-control .object-align-item .icon {\n  background: #333;\n}\n\n.object-align-control .object-align-item:hover .icon {\n  background: #333;\n}\n\n.object-align-control .object-align-item.selected .icon {\n  background: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
