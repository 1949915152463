import React, {Component} from 'react'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import QS from 'qs'
import {parseJWT, recoveryPassword} from '../../utils/auth'
import {setCurrentUserToken} from '../../ducks/users'
import Form from './RecoverPasswordForm'
import AuthPage from './AuthPage'
import 'styles/Auth.css'

class RecoverPassword extends Component {
  constructor(props) {
    super(props)

    let { location } = props
    let { token } = QS.parse(location.search.slice(1))
    let error = null

    if (token) {
      let tokenBody = parseJWT(token)

      if (!tokenBody) {
        error =
          'Срок действия вашей ссылки для восстановления пароля истек. Срок действия приглашений истекает через 7 дней.'
      }
    }

    this.state = {
      error,
      recoveryToken: token,
    }
  }

  handleUpdate = data =>
    new Promise((resolve, reject) => {
      let { recoveryToken } = this.state
      let { history, setCurrentUserToken } = this.props
      data = { ...data, token : recoveryToken }

      recoveryPassword(data).then((response, a, b) => {

        let { headers: {token} } = response

        if (token) {
          setCurrentUserToken(token)
        }

        history.push('/')

      }).catch((err) => {

        const data = err.response?.data
        if (data) {
          return reject(new SubmissionError({_error: data.message}))
        }
        return reject(SubmissionError({_error: 'error'}))
      })

      /*updatePassword(data, ({ success, errors, sessionToken }) => {
        if (!success) {
          // Show errors
          console.log(errors)

          return reject(new SubmissionError(errors))
        }

        window.localStorage.setItem('protonSession', sessionToken)
        setCurrentUserToken(sessionToken)
        history.push('/')
        resolve()
      })*/
    })

  render() {
    let { error } = this.state

    return (
      <AuthPage className="login-page" error={error}>
        <Form onSubmit={this.handleUpdate} />
      </AuthPage>
    )
  }
}

export default connect(null, { setCurrentUserToken })(RecoverPassword)
