export default [
  {
    id: 1,
    name: '2017',
    description: 'Last Year',
    children: [
      {
        id: 2,
        name: 'Q1',
        description: '',
        children: [
          {
            id: 7,
            name: 'January',
            description: '',
            children: [
              {
                id: 8,
                name: 'Day 1',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 9,
                name: 'Day 2',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 10,
                name: 'Day 3',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 11,
                name: 'Day 4',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 12,
                name: 'Day 5',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 13,
                name: 'Day 6',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 14,
                name: 'Day 7',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 15,
                name: 'Day 8',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 16,
                name: 'Day 9',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 17,
                name: 'Day 10',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 18,
                name: 'Day 11',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 19,
                name: 'Day 12',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 20,
                name: 'Day 13',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
              {
                id: 21,
                name: 'Day 14',
                description: '',
                children: [],
                counter: 0,
                expanded: false,
                selected: false,
              },
            ],
            counter: 1,
            expanded: false,
            selected: false,
          },
        ],
        counter: 1,
        expanded: false,
        selected: false,
      },
      {
        id: 3,
        name: 'Q2',
        description: '',
        children: [],
        counter: 0,
        expanded: false,
        selected: false,
      },
      {
        id: 4,
        name: 'Q3',
        description: '',
        children: [],
        counter: 0,
        expanded: false,
        selected: false,
      },
      {
        id: 9,
        name: 'Q4',
        description: '',
        children: [],
        counter: 0,
        expanded: false,
        selected: false,
      },
    ],
    counter: 4,
    expanded: false,
    selected: false,
  },
  {
    id: 5,
    name: '2018',
    description: 'Current Year',
    children: [
      {
        id: 6,
        name: 'Q1',
        description: 'Current Quarter',
        children: [],
        counter: 0,
        expanded: false,
        selected: false,
      },
    ],
    counter: 1,
    expanded: false,
    selected: false,
  },
];
