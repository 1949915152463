// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-panel-screen-template-collapased {
  color: #3e87f8;
}

.right-panel-screen-template-collapased .icon {
  background-color: #3e87f8;
}

.right-panel-screen-template .image-input-wrapper {
  width: 100%;
  margin: 15px -16px 0 -16px;
}

.right-panel-screen-template .image-input {
  width: calc(100% + 32px);
  height: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ScreenControl.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;EACxB,aAAa;AACf","sourcesContent":[".right-panel-screen-template-collapased {\n  color: #3e87f8;\n}\n\n.right-panel-screen-template-collapased .icon {\n  background-color: #3e87f8;\n}\n\n.right-panel-screen-template .image-input-wrapper {\n  width: 100%;\n  margin: 15px -16px 0 -16px;\n}\n\n.right-panel-screen-template .image-input {\n  width: calc(100% + 32px);\n  height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
