import React, {Component} from 'react'
import classNames from 'classnames'
import Icon from '../Shared/Icon'
import 'styles/PlatformToggle.css'

export default class PlatformToggle extends Component {
  
  handleChange = val => {
    let {
      input: { onChange },
    } = this.props

    onChange(val)
  }

  render() {
    let {
      input: { value },
    } = this.props

    return (
      <div className="new-app-platform">
        
        <ToggleItem
          value="custom"
          label="Задать свои габариты кейса или ящика"
          onSelect={this.handleChange}
          selected={value === 'custom'}
        />

        <ToggleItem
          value="template"
          label="Выбрать модель DGCASE или подобрать шаблон кейса из нашей базы"
          onSelect={this.handleChange}
          selected={value === 'template'}
        />

      </div>
    )
  }
}

class ToggleItem extends Component {

  handleClick = () => {
    let { value, onSelect } = this.props

    onSelect(value)
  }

  render() {
    let { label, value, selected } = this.props

    return (
      <div
        className={classNames(
          'new-app-platform-item',
          `new-app-platform-${value}`,
          selected && 'new-app-platform-selected'
        )}
        onClick={this.handleClick}
      >
        <div className="new-app-platform-checkmark">
          <Icon type="done" />
        </div>
        <div className="new-app-platform-icon" />
        <div className="new-app-platform-title">{label}</div>
      </div>
    )
  }
}
