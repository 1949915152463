// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shape-editor-point {
  fill: #fff;
  stroke: #999;
  stroke-location: outside;
  stroke-width: 1;
}
.shape-editor-point-wrapper:hover .shape-editor-point {

  stroke: #3e87f8;

}
.shape-editor-point.selected {
/*  stroke-width: 2;*/
  stroke-width: 1;
  stroke: #3e87f8;
}
.shape-editor-hit-point {
  stroke-width: 1;
  stroke: #ff0000;
  fill:  #ff0000;
}
.shape-editor-point-wrapper {
  cursor: default;
}

.shape-editor-point-target {
  fill: none;
  stroke: none;
}
.shape-editor-line-angle {
  stroke: #999;
  fill: none;
  stroke-dasharray: 5;
}
.shape-editor-active-line, .shape-editor-line {
  fill: none;
  stroke: #999;
  stroke-width: 1;
  pointerEvents: none;

}

.shape-editor-active-line, .trace-editor-line {
  fill: rgba(255, 0, 0, 0.25);
  stroke: #ff0000;
  stroke-width: 1;
  pointerEvents: none;

}
.shape-editor-line.shape-editor-line-selected {
  stroke: #3e87f8;
}
.shape-editor-line-control-point {
   stroke: #3e87f8;
 }

.control-wrapper .shape-editor-point{
  stroke: #3e87f8;
  fill:  #3e87f8;
}
.shape-editor-point-wrapper:hover  .control-wrapper .shape-editor-point{
  stroke: #3e87f8;
}


`, "",{"version":3,"sources":["webpack://./src/styles/ShapeEditor.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,wBAAwB;EACxB,eAAe;AACjB;AACA;;EAEE,eAAe;;AAEjB;AACA;AACA,qBAAqB;EACnB,eAAe;EACf,eAAe;AACjB;AACA;EACE,eAAe;EACf,eAAe;EACf,cAAc;AAChB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,YAAY;EACZ,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;EACf,mBAAmB;;AAErB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,eAAe;EACf,mBAAmB;;AAErB;AACA;EACE,eAAe;AACjB;AACA;GACG,eAAe;CACjB;;AAED;EACE,eAAe;EACf,cAAc;AAChB;AACA;EACE,eAAe;AACjB","sourcesContent":[".shape-editor-point {\n  fill: #fff;\n  stroke: #999;\n  stroke-location: outside;\n  stroke-width: 1;\n}\n.shape-editor-point-wrapper:hover .shape-editor-point {\n\n  stroke: #3e87f8;\n\n}\n.shape-editor-point.selected {\n/*  stroke-width: 2;*/\n  stroke-width: 1;\n  stroke: #3e87f8;\n}\n.shape-editor-hit-point {\n  stroke-width: 1;\n  stroke: #ff0000;\n  fill:  #ff0000;\n}\n.shape-editor-point-wrapper {\n  cursor: default;\n}\n\n.shape-editor-point-target {\n  fill: none;\n  stroke: none;\n}\n.shape-editor-line-angle {\n  stroke: #999;\n  fill: none;\n  stroke-dasharray: 5;\n}\n.shape-editor-active-line, .shape-editor-line {\n  fill: none;\n  stroke: #999;\n  stroke-width: 1;\n  pointerEvents: none;\n\n}\n\n.shape-editor-active-line, .trace-editor-line {\n  fill: rgba(255, 0, 0, 0.25);\n  stroke: #ff0000;\n  stroke-width: 1;\n  pointerEvents: none;\n\n}\n.shape-editor-line.shape-editor-line-selected {\n  stroke: #3e87f8;\n}\n.shape-editor-line-control-point {\n   stroke: #3e87f8;\n }\n\n.control-wrapper .shape-editor-point{\n  stroke: #3e87f8;\n  fill:  #3e87f8;\n}\n.shape-editor-point-wrapper:hover  .control-wrapper .shape-editor-point{\n  stroke: #3e87f8;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
