// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
  height: 24px;
  position: relative;
}

.slider-track {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4)
}

.slider-knob {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: -8px;
  top: 4px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.slider-knob:hover {
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15);
}
`, "",{"version":3,"sources":["webpack://./src/styles/Slider.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,QAAQ;EACR,WAAW;EACX;AACF;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,QAAQ;EACR,kBAAkB;EAClB,gBAAgB;EAChB,wEAAwE;EACxE,eAAe;AACjB;;AAEA;EACE,yEAAyE;AAC3E","sourcesContent":[".slider {\n  height: 24px;\n  position: relative;\n}\n\n.slider-track {\n  position: absolute;\n  top: 50%;\n  left: 0;\n  right: 0;\n  height: 0px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.4)\n}\n\n.slider-knob {\n  position: absolute;\n  width: 16px;\n  height: 16px;\n  margin-left: -8px;\n  top: 4px;\n  border-radius: 50%;\n  background: #fff;\n  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n}\n\n.slider-knob:hover {\n  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
