import {COMPONENT} from 'common/constants'

import {
    deepMap,
    getId,
    getObject,
    nextPath,
    pathLength,
    remapSiblings,
    remove,
    removeChildren,
    subPath,
    translateChildren,
    update,
    updateParentBounds,
} from 'common/utils'

import {resetLibraryBindingIds} from 'utils/libraries'
import {getSnapValue} from 'utils/snapping'
import {unScale} from 'utils/zoom'
import {getAbsoluteBbox, getDist} from 'utils/geometry'
import {saveTouched} from 'utils/saving'
import {changeObjectParent, getParentIds} from 'utils/positioning'

import {changeChildIds} from 'utils/copying'

import {getParentId, performCreate, resizeParent} from './objects'
import {moveShape} from "../../utils/shapes";

export const BEGIN_DRAG = Symbol('BEGIN_DRAG')
export const END_DRAG = Symbol('END_DRAG')
export const DRAG = Symbol('DRAG')

export default (state, action) => {
  if (action.type === BEGIN_DRAG) {
    let { position } = action

    return {
      ...state,
      positioningStartPoint: position,
    }
  }

  if (action.type === DRAG) {


    let {
      list,
      map,
      typeIndex,
      zoom,
      selection,
      positioningObjects,
      positioningStartPoint,
      positioningConstraint,
    } = state

    let { position, shiftPressed, altPressed } = action



    if (!positioningObjects && getDist(position, positioningStartPoint) >= 5) {
      positioningObjects = {}
      let paths = removeChildren(selection.map(id => map[id]))

      let ids = paths.map(path => {
        return getObject(state.list, path).id
      })

      if (altPressed) {
        let newSelection = []
        // Copy all the objects

        for (let id of ids) {
          let path = state.map[id]
          let obj = getObject(list, path)
          let parentId = getObject(list, subPath(path, 1)).id
          let newId = getId()

          obj = changeChildIds(obj, { id: newId })
          obj = resetLibraryBindingIds(obj)


          let [newState] = performCreate(
            state,
            nextPath(path),
            obj,
            newId,
            parentId,
            true,
            true
          )

          state = newState
          newSelection.push(newId)
          list = state.list
          map = state.map
          typeIndex = state.typeIndex
        }

        selection = newSelection
        paths = selection.map(id => map[id])
      }

      for (let path of paths) {
        let obj = getObject(list, path)

        if (obj && paths.includes(path)) {
          positioningObjects[obj.id] = [obj.x, obj.y]
        }
      }
    }


    let currentXSnap = null
    let currentYSnap = null

    if (positioningObjects) {
      let [startX, startY] = unScale(positioningStartPoint, zoom)
      let [newX, newY] = unScale(position, zoom)
      let diffX = Math.round(newX - startX)
      let diffY = Math.round(newY - startY)

      let xCoords = {}
      let yCoords = {}

      // Snapping
      for (let id of Object.keys(positioningObjects)) {
        let obj = getObject(state.list, map[id])

        if (!obj) continue

        let component = getObject(state.list, subPath(map[id], 1))
        let [originalX, originalY] = positioningObjects[id]

        if (obj?.type !== COMPONENT) {
          originalX += component.x
          originalY += component.y
        }

        xCoords = {
          ...xCoords,
          [`${id}.left`]: originalX + diffX,
          [`${id}.right`]: originalX + obj?.width + diffX,
          [`${id}.center`]: originalX + obj?.width / 2 + diffX,
        }

        yCoords = {
          ...yCoords,
          [`${id}.top`]: originalY + diffY,
          [`${id}.bottom`]: originalY + obj.height + diffY,
          [`${id}.center`]: originalY + obj.height / 2 + diffY,
        }
      }

      let xSnap = getSnapValue(state.xGrid, xCoords, state.zoom)
      let ySnap = getSnapValue(state.yGrid, yCoords, state.zoom)


      if (xSnap) {
        let key = Object.keys(xSnap)[0]
        currentXSnap = xSnap[key]
        diffX += currentXSnap - xCoords[key]
      }

      if (ySnap) {
        let key = Object.keys(ySnap)[0]
        currentYSnap = ySnap[key]
        diffY += currentYSnap - yCoords[key]
      }

      // Shift
      if (shiftPressed && !positioningConstraint) {
        positioningConstraint = Math.abs(diffX) > Math.abs(diffY) ? 'x' : 'y'
      } else if (!shiftPressed && positioningConstraint) {
        positioningConstraint = null
      }

      if (positioningConstraint === 'x') {
        diffY = 0
        currentYSnap = null
      } else if (positioningConstraint === 'y') {
        diffX = 0
        currentXSnap = null
      }

      for (let id of Object.keys(positioningObjects)) {
        let path = map[id]
        let [originalX, originalY] = positioningObjects[id]
        let originalObject = getObject(list, path)

        if (!originalObject) {
          continue
        }

        let x = originalX + diffX
        let y = originalY + diffY

        let newObject = { ...originalObject, x, y }


/*        if (positioningObjects) {

          const ids = Object.keys(positioningObjects)

          let paths = removeChildren(ids.map(id => map[id]))

          paths = paths.filter(path => pathLength(path) > 1)
          let reducedIds = paths.map(path => getObject(list, path).id)

          for (let id of reducedIds) {
            let path = map[id]
            let object = getObject(list, path)
            let absoluteBbox = getAbsoluteBbox(object, list, map)

            let parentId = getParentId(list, map, typeIndex, {
              ...absoluteBbox,
              id,
            })

            const component = getObject(list, map[parentId])
            if (component && component.children) {
              const matchObjects = component.children


              let error = false
              for (let i = 0; i < matchObjects.length; i += 1) {

                let matchObj = matchObjects[i]
                if (matchObj.id  === newObject.id ) continue
                let matchObjPath = state.map[matchObj.id]
                console.log(matchObj.id );
                let intersection = getIntersection(newObject, matchObj)
                console.log(intersection);
                list = update(list, matchObjPath, {...matchObj, error : false })
                if (intersection ) {
                  error =  true
                }

              }

              newObject = {...newObject,error}
            }





          }
        }*/

        if (newObject.type !== COMPONENT) {
          newObject = translateChildren(newObject, originalObject)
        }


        //if (newObject.type === SHAPE || newObject.type === ELLIPSE || newObject.type === RECTANGLE ) {

          const resized = moveShape(newObject, originalObject, diffX, diffY)

          newObject = {
            ...newObject,
           ...resized
            //...resized
          }
        //}





        list = update(list, path, newObject )
        list = updateParentBounds(list, state.map, id, null, resizeParent)
      }
    }



    return {
      ...state,
      list,
      map,
      typeIndex,
      selection,
      currentXSnap: { coord: currentXSnap },
      currentYSnap: { coord: currentYSnap },
      positioningObjects,
      positioningConstraint,
      textEditing: false,
      shapeEditing: false,
    }
  }

  if (action.type === END_DRAG) {
    let { appId, list, map, typeIndex, positioningObjects, selection } = state

    if (positioningObjects) {
      const ids = Object.keys(positioningObjects)
      let paths = removeChildren(ids.map(id => map[id]))

      const componentIds = paths.map(
        path => getObject(list, subPath(path, 1))?.id
      )

      paths = paths.filter(path => pathLength(path) > 1)
      let reducedIds = paths.map(path => getObject(list, path).id)

      for (let id of reducedIds) {
        let path = map[id]
        let object = getObject(list, path)
        let absoluteBbox = getAbsoluteBbox(object, list, map)

        let newParentId = getParentId(list, map, typeIndex, {
          ...absoluteBbox,
          id,
        })

        if (!newParentId) {
          list = remove(list, path)
          map = remapSiblings(list, map, path)
          selection = selection.filter(id => id !== object.id)
        } else {

          let newComponent = getObject(list, subPath(map[newParentId], 1))
          let oldComponent = getObject(list, subPath(path, 1))

          let xDiff = newComponent.x - oldComponent.x
          let yDiff = newComponent.y - oldComponent.y

          let currentParentIds = getParentIds(list, map, object.id)
          let currentParentId = currentParentIds.slice(-1)[0] || null

          if (newParentId !== currentParentId) {
            let newObject = deepMap([object], obj => ({
              ...obj,
              x: obj.x - xDiff,
              y: obj.y - yDiff,
            }))[0]

            list = update(list, path, newObject)

            let [newList, newMap] = changeObjectParent(
              list,
              map,
              object.id,
              newParentId
            )

            list = newList
            map = newMap
          }
        }
      }

      saveTouched(
        appId,
        list,
        map,
        Object.keys(positioningObjects).concat(componentIds)
      )
    }

    return {
      ...state,
      map,
      list,
      selection: [...selection],
      positioningObjects: null,
      positioningStartPoint: null,
    }
  }

  return state
}

// Actions

export const beginDrag = position => ({
  type: BEGIN_DRAG,
  position,
})

export const drag = (position, shiftPressed, altPressed) => ({
  type: DRAG,
  position,
  shiftPressed,
  altPressed,
})

export const endDrag = () => ({
  type: END_DRAG,
})

// Selectors

export const getXGrid = state => {
  return state.editor.objects.present.xGrid
}

export const getYGrid = state => {
  return state.editor.objects.present.yGrid
}

export const getDragging = state => {
  return !!state.editor.objects.present.positioningStartPoint
}
