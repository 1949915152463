/*
axios.defaults.transformResponse = axios.defaults.transformResponse.concat((data) => {

  console.log(data) // this should now be JSON
})
*/

export const  doFetch = async (url, options) => {
  const {data} = await doFetchWithResponse(url, options);

  return data;
};

export const  doFetchWithResponse = async (url, options) => {
  const response = await fetch(url, this.getOptions(options));
  const headers = parseAndMergeNestedHeaders(response.headers);

  let data;
  try {
    data = await response.json();
  } catch (err) {
    throw {
      intl: {
        id: 'mobile.request.invalid_response',
        defaultMessage: 'Received invalid response from the server.',
      },
    };
  }



  if (response.ok) {
    return {
      response,
      headers,
      data,
    };
  }

  const msg = data.message || '';

  if (this.logToConsole) {
    console.error(msg); // eslint-disable-line no-console
  }

  throw {
    message: msg,
    server_error_id: data.id,
    status_code: data.status_code,
    url,
  };
};

function parseAndMergeNestedHeaders(originalHeaders) {
  // TODO: This is a workaround for https://github.com/matthew-andrews/isomorphic-fetch/issues/97
  // The real solution is to set Access-Control-Expose-Headers on the server
  const headers = new Map();
  let nestedHeaders = new Map();
  originalHeaders.forEach((val, key) => {
    const capitalizedKey = key.replace(/\b[a-z]/g, (l) => l.toUpperCase());
    let realVal = val;
    if (val && val.match(/\n\S+:\s\S+/)) {
      const nestedHeaderStrings = val.split('\n');
      realVal = nestedHeaderStrings.shift();
      const moreNestedHeaders = new Map(
        nestedHeaderStrings.map((h) => h.split(/:\s/))
      );
      nestedHeaders = new Map([...nestedHeaders, ...moreNestedHeaders]);
    }
    headers.set(capitalizedKey, realVal);
  });
  return new Map([...headers, ...nestedHeaders]);
}
