import React, {Component} from 'react'
import {connect} from 'react-redux'
import {MultiMenuTrigger} from 'react-multi-menu'
import {COMPONENT, GROUP} from 'common/constants'

import {
    deleteObject,
    divideObjects,
    excludeObjects,
    groupObjects,
    groupObjectsToList,
    intersectObjects,
    reorderObjectsMoveDown,
    reorderObjectsMoveFirst,
    reorderObjectsMoveLast,
    reorderObjectsMoveUp,
    subtractObjects,
    ungroupObjects,
    uniteObjects,
    updateObject,
} from '../../../ducks/editor/objects'
import Icon, {IconButton} from '../../Shared/Icon'
import {getCurrentUser} from '../../../ducks/users'

class OverflowMenu extends Component {
  handleChangeVisibility = e => {
    let { onShowVisibility } = this.props

    onShowVisibility(e)
  }

  handleEditName = () => {}

  handleDelete = () => {
    let { object, deleteObject } = this.props

    deleteObject(object.id)
  }

  handleUngroup = () => {
    const { object, ungroupObjects } = this.props
    ungroupObjects([object.id])
  }

  handleMakeList = () => {
    const { object, groupObjectsToList } = this.props
    groupObjectsToList([object.id])
  }

  handleMakeUnite = () => {
    const { object, uniteObjects } = this.props
    uniteObjects([object.id])
  }
  handleMakeIntersect = () => {
    const { object, intersectObjects } = this.props
    intersectObjects([object.id])
  }
  handleMakeSubtract = () => {
    const { object, subtractObjects } = this.props
    subtractObjects([object.id])
  }

  handleMakeExclude = () => {
    const { object, excludeObjects } = this.props
    excludeObjects([object.id])
  }
  handleMakeDivide = () => {
    const { object, divideObjects } = this.props
    divideObjects([object.id])
  }

  handleMakeGroup = () => {
    const { object, groupObjects } = this.props
    groupObjects([object.id])
  }

  handleBringForward = e => {
    const { object, reorderObjectsMoveUp } = this.props
    e.stopPropagation()
    reorderObjectsMoveUp([object.id])
  }

  handleSendBackward = e => {
    const { object, reorderObjectsMoveDown } = this.props
    e.stopPropagation()
    reorderObjectsMoveDown([object.id])
  }

  handleSendBack = e => {
    const { object, reorderObjectsMoveLast } = this.props
    e.stopPropagation()
    reorderObjectsMoveLast([object.id])
  }

  handleBringFront = e => {
    const { object, reorderObjectsMoveFirst } = this.props
    e.stopPropagation()
    reorderObjectsMoveFirst([object.id])
  }

  handleCreateScreenTemplate = () => {
    const { onShowScreenTemplates } = this.props
    onShowScreenTemplates()
  }

  getMenu = () => {
    const { object, isAdmin } = this.props

    let menuItems = [

      object.type !== COMPONENT && {
        label: (
          <div className="right-panel-overflow-option">
            <i className="material-icons">flip_to_front</i>
            Положение
          </div>
        ),
        children: [
          {
            label: (
              <div className="right-panel-overflow-option">На передний фронт</div>
            ),
            onClick: this.handleBringFront,
          },
          {
            label: (
              <div className="right-panel-overflow-option">На задний фронт</div>
            ),
            onClick: this.handleSendBack,
          },
          null,
          {
            label: (
              <div className="right-panel-overflow-option">Вверх</div>
            ),
            onClick: this.handleBringForward,
          },
          {
            label: (
              <div className="right-panel-overflow-option">Вниз</div>
            ),
            onClick: this.handleSendBackward,
          },
        ],
      },
      /*
      {
        label: (
          <div className="right-panel-overflow-option">
            <Icon type="pencil-small" />
            Edit Name
          </div>
        ),
        onClick: this.handleEditName,
      },
      */

      /*{
        label: (
          <div className="right-panel-overflow-option">
            <i className="material-icons">list</i>
            Make List
          </div>
        ),
        onClick: this.handleMakeList,
      },*/
      object.type === GROUP && {
        label: (
          <div className="right-panel-overflow-option">
            <i className="material-icons">call_split</i>
            Разгруппировать
          </div>
        ),
        onClick: this.handleUngroup,
      },
      {
        label: (
          <div className="right-panel-overflow-option">
            <i className="material-icons">merge_type</i>
            Сгруппировать
          </div>
        ),
        onClick: this.handleMakeGroup,
      },
      /*{
        label: (
          <div className="right-panel-overflow-option">
            <i className="material-icons">merge_type</i>
            Union
          </div>
        ),
        onClick: this.handleMakeUnion,
      },*/
      {
        label: (
          <div className="right-panel-overflow-option">
            <Icon type="trash-small" />
            Удалить
          </div>
        ),
        onClick: this.handleDelete,
      },
    ].filter(Boolean)

    return menuItems
  }

  render() {
    return (
      <div className="right-panel-overflow-menu">
        <MultiMenuTrigger
          menu={this.getMenu()}
          className="right-panel-overflow-menu-trigger"
          rowHeight={40}
        >
          <IconButton type="more-vert" />
        </MultiMenuTrigger>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const currentUser = getCurrentUser(state)

  return {
    isAdmin: !!(currentUser && currentUser.admin),
  }
}

export default connect(mapStateToProps, {
  deleteObject,
  ungroupObjects,
  updateObject,
  groupObjects,
  groupObjectsToList,
  uniteObjects,
  intersectObjects,
  subtractObjects,
  excludeObjects,
  divideObjects,
  reorderObjectsMoveFirst,
  reorderObjectsMoveLast,
  reorderObjectsMoveUp,
  reorderObjectsMoveDown,
})(OverflowMenu)
