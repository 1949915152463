import immutabilityHelper from 'immutability-helper';
import {comparePaths, sortPaths} from './sorting';

import {getBoundingBox} from './geometry';
import {INPUT} from 'common/constants';
import {internalMove} from "../../utils/shapes";

const _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
} : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

const _extends = Object.assign || function (target) {
    for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i];

        for (let key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        }
    }

    return target;
};


function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
        for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
            arr2[i] = arr[i];
        }

        return arr2;
    } else {
        return Array.from(arr);
    }
}

function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }

    return obj;
} // Gets the optimal insert path for an object
// Takes:
//   objects - array of objects
//   basePath - expicitly specified path
//   prefix - path of parent - usually screen or current selection


export const getInsertPath = function (objects, basePath) {

    const prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    const nest = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true; // basePath is invalid

    if (basePath && prefix && subPath(basePath, pathLength(prefix)) !== prefix) {
        basePath = prefix;
    } // basePath is not specified


    basePath = basePath || prefix;
    const target = getObject(objects, basePath);

    if (!target) {
        return '' + objects.length;
    }

    if (target.children && nest) {
        return basePath + '.' + target.children.length;
    }

    return nextPath(basePath);
};

export const getObject = (objects, path) => {
    if (!objects || !path || path.length === 0) {
        return null;
    }

    if (typeof path === 'string') {
        path = path.split('.');
    }

    if (path.length === 1) {
        return objects[path[0]];
    }

    const newObject = objects[path[0]];
    return newObject && getObject(newObject.children, path.slice(1)) || null;
};

export const getObjectId = (objects, path) => {
    const object = getObject(objects, path);
    return object && object.id;
};

export const nextPath = (path) => {
    const pieces = path.split('.');
    const nextPieces = pieces.slice(0, pieces.length - 1).concat([+pieces[pieces.length - 1] + 1]);
    return nextPieces.join('.');
};

export const insert = function (objects, newPath) {
    for (var _len = arguments.length, newObjects = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        newObjects[_key - 2] = arguments[_key];
    }

    newObjects = newObjects.slice().reverse();
    newObjects.forEach(function (newObject) {
        objects = immutabilityHelper(objects, buildUpdate(newPath, newObject, true));
    });
    return objects;
};

export const update = (list, path, newObject) => {
    return immutabilityHelper(list, buildUpdate(path, newObject));
};

export const remove = (objects, path) => {
    return update(objects, path);
};

export const buildUpdate = function (path, obj) {
    const insert = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    const removeCount = insert ? 0 : 1;

    if (!path) {
        path = [];
    }

    if (typeof path === 'string') {
        path = path.split('.');
    }

    if (path.length === 0) {
        return {};
    }

    if (path.length === 1) {
        const spliceObj = [path[0], removeCount]; // Only add this if not undefined / null

        if (obj) {
            spliceObj.push(obj);
        }

        return {
            $splice: [spliceObj]
        };
    }

    return _defineProperty({}, path[0], {
        children: buildUpdate(path.slice(1), obj, insert)
    });
};

export const getSiblings = (list, path) => {
    const parentPath = getParentPath(path);

    if (parentPath === null) {
        return list;
    }

    const parentObj = getObject(list, parentPath);
    return parentObj && parentObj.children || [];
};

export const getParentPath = (path) => {
    if (!path) {
        return null;
    }
    const pieces = path.split('.');
    const parentPath = pieces.slice(0, pieces.length - 1).join('.');

    if (parentPath === '') {
        return null;
    }

    return parentPath;
};

export const joinPaths = function () {
    for (var _len2 = arguments.length, paths = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        paths[_key2] = arguments[_key2];
    }

    paths = paths.filter(function (path) {
        return path;
    });
    return paths.join('.');
};












export const remapSiblingsV2 = function (list, map = {}, path = '0') {
    // Функция для копирования свойств объектов
    const _extends = Object.assign || function (target) {
        for (let i = 1; i < arguments.length; i++) {
            const source = arguments[i];
            for (let key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };

    // Получить путь родителя
    const getParentPath = (path) => {
        if (!path) {
            return null;
        }
        const pieces = path.split('.');
        const parentPath = pieces.slice(0, pieces.length - 1).join('.');
        return parentPath === '' ? null : parentPath;
    };

    // Получить siblings
    const getSiblings = (list, path) => {
        const parentPath = getParentPath(path);
        if (parentPath === null) {
            return list;
        }
        const parentObj = getObject(list, parentPath);
        return parentObj && parentObj.children || [];
    };

    // Объединить пути
    const joinPaths = function (...paths) {
        return paths.filter(Boolean).join('.');
    };

    // Рекурсивная функция для создания карты
    const remapSiblings = function (list, map = {}, path = '0') {
        map = _extends({}, map);
        const parentPath = getParentPath(path);
        const siblings = getSiblings(list, path);

        siblings.forEach((obj, position) => {
            const currentPath = joinPaths(parentPath, `${position}`);
            map[obj.id] = currentPath;

            if (obj.children) {
                const childrenPath = joinPaths(currentPath, '0');
                const childrenMap = remapSiblings(list, {}, childrenPath);
                map = _extends({}, map, childrenMap);
            }
        });

        return map;
    };

    return remapSiblings(list, map, path);
}














export const remapSiblings = function (list) {
    let map = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '0';
    map = _extends({}, map);
    const parentPath = getParentPath(path);
    const siblings = getSiblings(list, path);
    siblings.forEach(function (obj, position) {
        const path = joinPaths(parentPath, '' + position);
        map[obj.id] = path;

        if (obj.children) {
            const childrenPath = joinPaths(path, '0');
            const childrenMap = remapSiblings(list, {}, childrenPath);
            map = _extends({}, map, childrenMap);
        }
    });
    return map;
};


export const subPath = (path, length) => {
    return (path || '').split('.').slice(0, length).join('.');
};

export const pathLength = (path) => {
    if (!path) {
        return 0;
    }

    return path.split('.').length;
};


























export const getGroupPathV2 = (paths) => {

    const pathLength = (path) => {
        if (!path) {
            return 0
        }
    
        return path.split('.').length
    }

    const subPath = (path, length) => {
        return (path || '').split('.').slice(0, length).join('.')
    }

    const sortPaths = (paths) => {
        paths = [].concat(_toConsumableArray(paths))
        paths.sort(function (a, b) {
            return comparePaths(a, b)
        })
        return paths
    }
      
    if (paths.length === 0) {
        return null
    }

    let commonPath = paths[0]

    paths.forEach(function (path) {
        for (let l = pathLength(commonPath); l >= 0; l -= 1) {
            if (subPath(path, l) === subPath(commonPath, l)) {
                commonPath = subPath(commonPath, l)
                break
            }
        }
    });

    paths = sortPaths(paths)

    return paths[0].split('.').slice(0, commonPath.length + 1).join('.')
}









export const getGroupPath = (paths) => {

    if (paths.length === 0) {
        return null;
    }

    let commonPath = paths[0];
    paths.forEach(function (path) {
        for (let l = pathLength(commonPath); l >= 0; l -= 1) {
            if (subPath(path, l) === subPath(commonPath, l)) {
                commonPath = subPath(commonPath, l);
                break;
            }
        }
    });
    paths = sortPaths(paths);
    return paths[0].split('.').slice(0, commonPath.length + 1).join('.');
};







export const getCommonParent = (paths) => {
    const minDepth = Math.min.apply(Math, _toConsumableArray(paths.map(function (p) {
        return pathLength(p);
    })));

    const _loop = function _loop(depth) {
        const firstVal = subPath(paths[0], depth);
        let error = false;
        let _iteratorNormalCompletion = true;
        let _didIteratorError = false;
        let _iteratorError = undefined;

        try {
            for (var _iterator = paths[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                const path = _step.value;

                if (subPath(path, depth) !== firstVal) {
                    error = true;
                    break;
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }

        if (!error) {
            const pathsAtDepth = sortPaths(paths.map(function (p) {
                return subPath(p, depth + 1);
            }));
            return {
                v: [firstVal || null, pathsAtDepth[0], pathsAtDepth[pathsAtDepth.length - 1]]
            };
        }
    };

    for (var depth = minDepth - 1; depth >= 0; depth -= 1) {
        const _ret = _loop(depth);

        if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
    }
}; // Gets screen paths of each object in PATHS




export const getComponentPathsV2 = (paths) => {

    const subPath = (path, length) => {
        return (path || '').split('.').slice(0, length).join('.');
    };

    const componentPaths = paths = paths.map(function (p) {
        return subPath(p, 1);
    });

    const sortPaths = (paths) => {
        paths = [].concat(_toConsumableArray(paths));
        paths.sort(function (a, b) {
            return comparePaths(a, b);
        });
        return paths;
    };

    return sortPaths(Array.from(new Set(componentPaths)));
}; // Removes child paths from PATHS

export const getComponentPaths = (paths) => {
    const componentPaths = paths = paths.map(function (p) {
        return subPath(p, 1);
    });
    return sortPaths(Array.from(new Set(componentPaths)));
}; // Removes child paths from PATHS


export const removeChildren = (paths) => {
    const map = {};
    paths.forEach(function (path) {
        map[path] = true;
    });
    paths.forEach(function (path) {
        for (let l = 0; l <= pathLength(path) - 1; l += 1) {
            if (map[subPath(path, l)]) {
                delete map[path];
                break;
            }
        }
    });
    return paths.filter(function (path) {
        return map[path];
    });
};

export const isSibling = (path1, path2) => {
    const length = pathLength(path1);

    if (length !== pathLength(path2)) {
        return false;
    }

    return subPath(path1, length - 1) === subPath(path2, length - 1);
};

export const getDropPath = (dropPath, itemPaths) => {
    const length = pathLength(dropPath);
    const pieces = dropPath.split('.');
    const pathsByLength = {};
    itemPaths.forEach(function (path) {
        const length = pathLength(path);

        if (!pathsByLength[length]) {
            pathsByLength[length] = [];
        }

        pathsByLength[length].push(path);
    });

    const _loop2 = function _loop2(i) {
        const l = i + 1;
        const paths = pathsByLength[l] || [];
        const subDropPath = subPath(dropPath, l);
        paths.forEach(function (path) {
            if (isSibling(subDropPath, path) && comparePaths(path, subDropPath) === -1) {
                // Do something
                pieces[i] -= 1;
            }
        });
    };

    for (var i = 0; i < length; i += 1) {
        _loop2(i);
    }

    return pieces.join('.');
};

export const incrementPath = function (path) {
    const amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    const length = pathLength(path);
    const start = subPath(path, length - 1);
    const pieces = path.split('.');
    let last = pieces[pieces.length - 1];
    last = '' + (+last + +amount);
    return joinPaths(start, last);
};

export const decrementPath = function (path) {
    const amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    const length = pathLength(path);
    const start = subPath(path, length - 1);
    const pieces = path.split('.');
    let last = pieces[pieces.length - 1];
    last = '' + Math.max(0, +last - +amount);
    return joinPaths(start, last);
};

export const isChildPath = (parentPath, childPath) => {
    if (pathLength(childPath) <= pathLength(parentPath)) {
        return false;
    }

    return subPath(childPath, pathLength(parentPath)) === parentPath;
};

export const anyField = (obj, fields, func) => {
    for (let i = 0; i < fields.length; i += 1) {
        const field = fields[i];

        if (func(obj[field])) {
            return true;
        }
    }

    return false;
};

export const getObjectsInRect = (objects, rect) => {
    if (!rect) {
        return [];
    }

    const x = rect.x,
        y = rect.y,
        width = rect.width,
        height = rect.height;
    const results = [];
    objects.forEach(function (obj) {
        if (anyField(obj, ['x', 'y', 'width', 'height'], function (f) {
            return typeof f !== 'number';
        })) {
            return;
        }

        if (!(obj.x + obj.width < x || x + width < obj.x) && !(obj.y + obj.height < y || y + height < obj.y)) {
            results.push(obj.id);
        }
    });
    return results;
};

export const shallowEqual = (obj1, obj2, fields) => {
    if (!obj1 || !obj2 || (typeof obj1 === 'undefined' ? 'undefined' : _typeof(obj1)) !== 'object' || (typeof obj2 === 'undefined' ? 'undefined' : _typeof(obj2)) !== 'object') {
        return obj1 === obj2;
    }

    if (!fields) {
        fields = Object.keys(_extends({}, obj1, obj2));
    }

    for (let i = 0; i < fields.length; i += 1) {
        if (obj1[fields[i]] !== obj2[fields[i]]) {
            return false;
        }
    }

    return true;
};

export const updateBounds = function (list, map, path) {
    const shouldUpdate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    const obj = getObject(list, path);

    if (!obj) {
        return list;
    }

    if (shouldUpdate && !shouldUpdate(obj)) {
        return list;
    }

    let minX = Infinity,
        minY = Infinity,
        maxX = -Infinity,
        maxY = -Infinity,
        maxD = obj.depth || 0;


    obj.children.forEach(function (child) {
        if (child.x < minX) {
            minX = child.x;
        }

        if (child.x + child.width > maxX) {
            maxX = child.x + child.width;
        }

        if (child.y < minY) {
            minY = child.y;
        }

        if (child.y + child.height > maxY) {
            maxY = child.y + child.height;
        }

        if (child.depth >= maxD) {
            maxD = child.depth;
        }


    });

    obj.compound?.forEach(function (child) {
        if (child.depth >= maxD) {
            maxD = child.depth;
        }
    });

    const x = removeInfinity(minX, 0);
    const y = removeInfinity(minY, 0);
    const width = removeInfinity(maxX - minX, 0);
    const height = removeInfinity(maxY - minY, 0);

    const updatedObj = _extends({}, obj, {
        x: x,
        y: y,
        width: width,
        height: height,
        depth: maxD

    });

    if (!shallowEqual(obj, updatedObj, ['x', 'y', 'width', 'height', 'depth'])) {
        list = update(list, path, updatedObj);
        const parentPath = subPath(path, pathLength(path) - 1);
        list = updateBounds(list, map, parentPath, shouldUpdate);
    }

    return list;
};

export const removeInfinity = (val, fallback) => {
    if (!isFinite(val)) {
        return fallback;
    }

    return val;
};

export const updateParentBounds = (list, map, id, path, shouldUpdate) => {

    path = path || map[id];

    if (!path) {
        return list;
    }

    const parentPath = subPath(path, pathLength(path) - 1);
    return updateBounds(list, map, parentPath, shouldUpdate);
};

export const evaluate = (newObject, object) => {
    const diff = {};
    Object.keys(newObject).forEach(function (key) {
        if (typeof newObject[key] === 'function') {
            diff[key] = newObject[key](object);
        } else {
            diff[key] = newObject[key];
        }
    });
    return diff;
};

export const translateChildren = (group, oldGroup) => {
    if (!group.children || !group.children.length) {
        return group;
    }

    const diffX = group.x - oldGroup.x;
    const diffY = group.y - oldGroup.y;
    const diffA = group.angle - oldGroup.angle;
    const error = group.error !== oldGroup.error;
    if (!diffX && !diffY && !diffA) {
        return group;
    }

    const translated = _extends({}, group);

    translated.children = translated.children.map(function (child) {

        const {compound, points, angle,} = child
        return translateChildren(_extends({}, child, internalMove({
            ...child,
          x: child.x + diffX,
            y: child.y + diffY,
        }), {
            x: child.x + diffX,
            y: child.y + diffY,
            angle: child.angle + diffA,
            error: group.error,
        }), child);

    });
    console.log(translated);
    return translated;
};

export const resizeObjects = function (objects, newBbox) {
    const resizeChildren = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {
        return true;
    };

    if (newBbox.width < 0 || newBbox.height < 0) {
        return objects;
    }

    const bbox = getBoundingBox(objects);
    let newObjects = objects;

    if (bbox.width === 0) {
        newObjects = deepMap(newObjects, function (obj) {
            return _extends({}, obj, {
                x: newBbox.x,
                width: newBbox.width
            });
        });
    } else {
        newObjects = deepMap(newObjects, function (obj) {
            const relativeX = (obj.x - bbox.x) / bbox.width;
            return _extends({}, obj, {
                x: newBbox.x + newBbox.width * relativeX,
                width: obj.width * newBbox.width / bbox.width
            });
        });
    }

    if (bbox.height === 0) {
        newObjects = deepMap(newObjects, function (obj) {
            return _extends({}, obj, {
                y: newBbox.y,
                height: newBbox.height
            });
        });
    } else {
        newObjects = deepMap(newObjects, function (obj) {
            const relativeY = (obj.y - bbox.y) / bbox.height;
            return _extends({}, obj, {
                y: newBbox.y + newBbox.height * relativeY,
                height: obj.height * newBbox.height / bbox.height
            });
        });
    }

    return newObjects;
}; // Depth-first tree-traversal





export const traverse = (objects, func, traverseChildren, parentObj) => {

    for (let i = 0; i < objects.length; i += 1) {
        const obj = objects[i];
        func(obj, parentObj, objects[i - 1], objects[i + 1]);

        if (obj && obj.children && (!traverseChildren || traverseChildren(obj))) {
            if (!Array.isArray(obj.children)) {
                throw new Error('obj.children is not an Array: ' + JSON.stringify(obj));
            }

            traverse(obj.children, func, traverseChildren, obj);
        }
    }
}; // Depth-first tree map




export const deepMap = (objects, func, mapChildren) => {
    const result = [];
    let _iteratorNormalCompletion2 = true;
    let _didIteratorError2 = false;
    let _iteratorError2 = undefined;

    try {
        for (var _iterator2 = objects[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            const obj = _step2.value;
            let newObj = obj;
            const originalChildren = obj ? obj.children : undefined;

            if (newObj && newObj.children && (!mapChildren || mapChildren(newObj))) {
                newObj = _extends({}, newObj, {
                    children: deepMap(newObj.children, func)
                });
            }

            newObj = func(newObj, originalChildren);
            result.push(newObj);
        }
    } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
            }
        } finally {
            if (_didIteratorError2) {
                throw _iteratorError2;
            }
        }
    }

    return result;
};

export const deepFilter = (objects, func) => {
    const result = [];
    let _iteratorNormalCompletion3 = true;
    let _didIteratorError3 = false;
    let _iteratorError3 = undefined;

    try {
        for (var _iterator3 = objects[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            const obj = _step3.value;

            if (!func(obj)) {
                continue;
            }

            let newObj = obj;

            if (obj.children) {
                newObj = _extends({}, obj, {
                    children: deepFilter(obj.children, func)
                });
            }

            result.push(newObj);
        }
    } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
            }
        } finally {
            if (_didIteratorError3) {
                throw _iteratorError3;
            }
        }
    }

    return result;
};

export const deepFind = (objects, func) => {
    const result = [];
    traverse(objects, function (obj) {
        if (func(obj)) {
            result.push(obj);
        }
    });
    return result;
};

export const getInputObjects = (objects) => {
    return getObjectsOfType(objects, INPUT);
};

export const getObjectsOfType = (objects, type) => {
    const results = [];
    traverse(objects, function (obj) {
        if (obj.type === type) {
            results.push(obj.id);
        }
    });
    return results;
};

export const buildIndex = function (objects, keyGetter) {
    const multi = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    const index = {};

    for (let i = 0; i < objects.length; i += 1) {
        const obj = objects[i];
        const key = keyGetter(obj);

        if (multi) {
            if (!index[key]) {
                index[key] = [];
            }

            index[key].push(obj);
        } else {
            index[key] = obj;
        }
    }

    return index;
}; // Returns object with highest score returned from FUNC
// Runs in O(N) because does not attempt to sort entire list


export const optimize = (objects, func) => {
    let best = null;
    let bestScore = -Infinity;

    for (let i = 0; i < objects.length; i += 1) {
        const obj = objects[i];
        const score = func(obj);

        if (score > bestScore) {
            best = obj;
            bestScore = score;
        }
    }

    return best;
}; // Deep equivalent of { ...obj1, ...obj2 }


export const deepMerge = (obj1, obj2) => {
    if (obj1 !== undefined && obj2 === undefined) {
        return obj1;
    }

    if (obj2 !== undefined && !obj1 === undefined) {
        return obj2;
    }

    if (!obj1 || (typeof obj1 === 'undefined' ? 'undefined' : _typeof(obj1)) !== 'object' || Array.isArray(obj1) || !obj2 || (typeof obj2 === 'undefined' ? 'undefined' : _typeof(obj2)) !== 'object' || Array.isArray(obj2)) {
        return obj2;
    }

    const result = {};
    const obj2Keys = new Set(Object.keys(obj2));

    for (let key in _extends({}, obj1, obj2)) {
        if (!obj2[key] && obj2Keys.has(key)) {
            result[key] = obj2[key];

            if (typeof result[key] === 'undefined') {
                delete result[key];
            }

            continue;
        }

        result[key] = deepMerge(obj1[key], obj2[key]);
    }

    return result;
};

export const deepGet = function (obj) {
    const key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    if (key.length === 0) {
        return obj;
    }

    if (!obj) {
        return undefined;
    }

    return deepGet(obj[key[0]], key.slice(1));
};

export const deepSet = function (obj) {
    const key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const value = arguments[2];

    if (key.length === 0) {
        return value;
    }

    if (!obj) {
        obj = {};
    }

    return _extends({}, obj, _defineProperty({}, key[0], deepSet(obj[key[0]], key.slice(1), value)));
};
