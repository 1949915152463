import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {omit} from 'lodash'

import {GroupedAccordion as Accordion} from 'components/Shared/Accordion'

import ItemHeader from './Header'

import './Item.scss'

class Item extends Component {
  static Header = ItemHeader

  renderHeader = expanded => {
    let {
      title,
      subtitle,
      icon,
      hideCarret,
      boxed,
      group,
      renderActions,
    } = this.props

    if (group && !icon && !hideCarret && !boxed) {
      if (expanded) {
        icon = 'expand-vertical'
      } else {
        icon = 'expand'
      }
    }

    return (
      <ItemHeader
        title={title}
        subtitle={subtitle}
        icon={icon}
        expanded={expanded}
        renderActions={renderActions}
      />
    )
  }

  render() {
    const { group, type, className, title } = this.props

    const Element = group ? Accordion : 'button'

    const props = () => {
      const { children, group } = this.props
      let props = { ...this.props }

      // remove unnecessary props
      props = omit(props, [
        'renderAction',
        'defaultExpanded',
        'uppercase',
        'fluid',
        'icon',
        'color',
        'content',
        'hidden',
        'item',
        'header',
        'className',
      ])

      if (group) {
        const { defaultExpanded, hideCarret, boxed } = this.props

        props = {
          ...props,
          group,
          defaultExpanded,
          hideCarret,
          boxed,
          renderChildren: () => children,
          renderHeader: boxed || hideCarret ? this.renderHeader : null,
        }
      }

      return props
    }

    const { uppercase, fluid, color, item, hidden, header } = this.props

    const classes = classNames('panel-item', className, {
      uppercase,
      fluid,
      [color]: color,
      item,
      hidden,
      header,
    })

    return (
      <Element className={classes} title={title} type={type} {...props()}>
        {!group && this.renderHeader()}
      </Element>
    )
  }
}

Item.propTypes = {
  color: PropTypes.oneOf(['data', 'orange', 'screens', 'teal']),
  defaultExpanded: PropTypes.bool,
  fluid: PropTypes.bool,
  icon: PropTypes.string,
  item: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  uppercase: PropTypes.bool,
  hidden: PropTypes.bool,
  title: PropTypes.string,
}

Item.defaultProps = {
  defaultExpanded: false,
  fluid: false,
  type: 'button',
  uppercase: false,
  item: false,
  hidden: false,
}

export default Item
