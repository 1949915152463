const START_DRAG = Symbol('START_DRAG')
const DRAG = Symbol('DRAG')
const END_DRAG = Symbol('END_DRAG')

const INITIAL_STATE = null

export default (state = INITIAL_STATE, action) => {
  if (action.type === START_DRAG) {
    let {
      position: [x, y],
    } = action

    return {
      x,
      y,
      width: 0,
      height: 0,
    }
  }

  if (state && action.type === DRAG) {
    let { position } = action

    let [x, y] = position

    let width = x - state.x
    let height = y - state.y

    return {
      ...state,
      width,
      height,
    }
  }

  if (action.type === END_DRAG) {
    return INITIAL_STATE
  }

  return state
}

// Actions

export const startDrag = position => ({
  type: START_DRAG,
  position,
})

export const drag = position => ({
  type: DRAG,
  position,
})

export const endDrag = () => ({
  type: END_DRAG,
})

// Selectors

export const getMarquee = state => {
  let { marquee } = state.editor

  if (!marquee) {
    return marquee
  }

  let result = { ...marquee }

  if (result.width < 0) {
    result.width = Math.abs(result.width)
    result.x -= result.width
  }

  if (result.height < 0) {
    result.height = Math.abs(result.height)
    result.y -= result.height
  }

  return result
}
