// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loadingScreen {
  opacity: 1;
  transition: opacity 1.5s ease 0s;
  pointer-events: none;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  place-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1920px;
  height: 590px;
  background-color: black;
}

#loadingText {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: 80px;
  width: 100%;
  height: 20px;
  font-family: Arial;
  font-size: 14px;
  color: white;
  text-align: center;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Editor/Preview/loader.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gCAAA;EACA,oBAAA;EACA,aAAA;EACA,wBAAA;EACA,2BAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;AACF","sourcesContent":["#loadingScreen {\n  opacity: 1;\n  transition: opacity 1.5s ease 0s;\n  pointer-events: none;\n  display: grid;\n  grid-template-rows: 100%;\n  grid-template-columns: 100%;\n  place-items: center;\n  position: absolute;\n  left: 0px;\n  top: 0px;\n  width: 1920px;\n  height: 590px;\n  background-color: black;\n}\n\n#loadingText {\n  position: absolute;\n  left: 0px;\n  top: 50%;\n  margin-top: 80px;\n  width: 100%;\n  height: 20px;\n  font-family: Arial;\n  font-size: 14px;\n  color: white;\n  text-align: center;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
