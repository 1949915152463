// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-note {
  padding: 8px 4px 8px 8px;
  color: #8a8a8a;
  margin-bottom: 12px;
}

.save-success {
  text-align: center;
}

.save-success h2 {
  font-size: 24px;
  margin-bottom: 12px;
}

.save-success .save-success-text {
  font-size: 16px;
}

.save-success-button-wrapper {
  margin-top: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Editor/CheckoutModal/CheckoutModal.scss"],"names":[],"mappings":"AAAC;EACC,wBAAA;EACC,cAAA;EAEA,mBAAA;AAAH;;AAEA;EACE,kBAAA;AACF;;AAEC;EACE,eAAA;EACA,mBAAA;AACH;;AAEC;EACE,eAAA;AACH;;AAEC;EAGE,gBAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADL","sourcesContent":[" .checkout-note {\n  padding: 8px 4px 8px 8px;\n   color: #8a8a8a;\n\n   margin-bottom: 12px;\n}\n.save-success {\n  text-align: center;\n\n}\n .save-success h2{\n   font-size: 24px;\n   margin-bottom: 12px;\n }\n\n .save-success .save-success-text{\n   font-size: 16px;\n\n }\n .save-success-button-wrapper {\n\n\n   margin-top: 20px;\n     text-align: center;\n     display: flex;\n     align-items: center;\n     justify-content: center;\n\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
