// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Panel_component__m9ttw {
  padding: 16px 24px;
  padding-bottom: 0;
  width: 380px;
  height: auto;
  top: 20px;
  left: 20px;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  box-shadow: 0 0 16px rgba(11, 31, 53, 0.08), 0 8px 16px rgba(11, 31, 53, 0.16);
  box-sizing: border-box;
}

.Panel_mb20__RGLs- {
  margin-bottom: 20px;
}

.Panel_row__szq-b {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  margin-bottom: 20px;
}

.Panel_item__qWw4t {
  padding: 4px;
  flex: 1 1 auto;
  box-sizing: border-box;
}

.Panel_x12__YJTFL {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
}

.Panel_x6__DPgVn {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
}

.Panel_content__RpUbI {
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
}`, "",{"version":3,"sources":["webpack://./src/components/Editor/Trace/Panel/index.module.scss"],"names":[],"mappings":"AAAA;EAEE,kBAAA;EACA,iBAAA;EAEA,YAAA;EACA,YAAA;EAEA,SAAA;EAEA,UAAA;EAEA,UAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EAEA,iCAAA;EAEA,sBAAA;EACA,8EAAA;EAEA,sBAAA;AAPF;;AAWA;EACE,mBAAA;AARF;;AAqBA;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AAlBF;;AAqBA;EACE,YAAA;EACA,cAAA;EACA,sBAAA;AAlBF;;AAqBA;EACE,YAAA;EACA,eAAA;EACA,gBAAA;AAlBF;;AAqBA;EACE,YAAA;EACA,cAAA;EACA,eAAA;AAlBF;;AAqBA;EACE,WAAA;EAEA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;EACA,cAAA;EAEA,iCAAA;AApBF","sourcesContent":[".component {\n\n  padding: 16px 24px;\n  padding-bottom: 0;\n\n  width: 380px;\n  height: auto;\n\n  top: 20px;\n\n  left: 20px;\n\n  outline: 0;\n  z-index: 1200;\n  position: fixed;\n  overflow: hidden;\n\n  -webkit-overflow-scrolling: touch;\n\n  background-color: #fff;\n  box-shadow: 0 0 16px rgb(11 31 53 / 8%), 0 8px 16px rgb(11 31 53 / 16%);\n\n  box-sizing: border-box;\n\n}\n\n.mb20 {\n  margin-bottom: 20px;\n}\n\n.wrapper {\n\n}\n\n\n.header {\n\n}\n\n\n.row {\n  flex-grow: 0;\n  max-width: 100%;\n  flex-basis: 100%;\n  display: flex;\n  margin-bottom: 20px;\n}\n\n.item {\n  padding: 4px;\n  flex: 1 auto;\n  box-sizing: border-box;\n}\n\n.x12 {\n  flex-grow: 0;\n  max-width: 100%;\n  flex-basis: 100%;\n}\n\n.x6 {\n  flex-grow: 0;\n  max-width: 50%;\n  flex-basis: 50%;\n}\n\n.content {\n  width: 100%;\n\n  align-items: center;\n  display: flex;\n  flex-wrap: wrap;\n  box-sizing: border-box;\n  flex: 1 1 auto;\n\n  -webkit-overflow-scrolling: touch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": `Panel_component__m9ttw`,
	"mb20": `Panel_mb20__RGLs-`,
	"row": `Panel_row__szq-b`,
	"item": `Panel_item__qWw4t`,
	"x12": `Panel_x12__YJTFL`,
	"x6": `Panel_x6__DPgVn`,
	"content": `Panel_content__RpUbI`
};
export default ___CSS_LOADER_EXPORT___;
