// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-component-outline {
  stroke: rgba(0, 0, 0, 0.4);
  stroke-width: 0.5px;
  fill: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/AddLibraryComponent.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".add-component-outline {\n  stroke: rgba(0, 0, 0, 0.4);\n  stroke-width: 0.5px;\n  fill: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
