import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocumentEvents from 'react-document-events'
import {Route, Switch} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import {getAppTitle} from 'utils/titles'
import { loadDraft, } from 'ducks/apps/actions'
import { getApp, canEditDraft } from "ducks/apps/selectors";
import {requestData, setData} from 'ducks/editor/objects'
import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'
import Canvas from './Canvas'
import Clipboard from './Clipboard'
import LeftPanel from './LeftPanel'
import LeftNav from './LeftNav'
import {Screens} from './Routes'
import LayerEditor from './LayerEditor'
import ModalWrapper from './ModalWrapper'


import './Editor.scss'
import Trace from "./Trace";
import { isAdmin } from "ducks/users";

export {default as EditorNav} from '../Nav'

class Editor extends Component {

  state = {
    error: null,
    librariesReady: true,
  }

  handleWheel = e => {
    if (e.ctrlKey) {
      e.preventDefault()
    }
  }

  handleLibrariesLoad = librariesReady => {
    //this.setState({ librariesReady })
  }

  componentDidMount() {
    
    let {
      match,
      requestData,
      setData,
      loadDraft
    } = this.props

    let { appId } = match.params

    loadDraft(appId).then(({value}) => {
      const app = value.data; setData(app.id, app.components, app)
    })

    document.body.classList.add('editor-body')

  }

  componentWillUnmount() {
    document.body.classList.remove('editor-body')
    document.body.classList.remove('editor-app-unpaid')
  }

  componentDidUpdate() { }

  componentDidCatch(error, errorInfo) {
    this.setState({error})
  }

  render() {

    let { librariesReady, error } = this.state

    let {
      app,
      match,
      children,
      canEdit,
      isAdmin
    } = this.props


    let {appId} = match.params

    let title = getAppTitle(app)

    if (error) {
      return (
        <div className="editor-error">
          <EmptyState greedy>
            <h2>Ошибка!</h2>
            <p>Пожалуйста, обновите страницу и попробуйте еще раз.</p>
          </EmptyState>
          {children}
        </div>
      )
    }

    return (
      <>
        <DocumentTitle title={title}/>

        <div className="editor">
          {/*{app ? (
            <>
              <LibraryLoader app={app} onLoad={this.handleLibrariesLoad} />
              <FontsLoader app={app} />
            </>
          ) : null}*/}
          <Clipboard appId={match.params.appId}/>

          <DocumentEvents capture onWheel={this.handleWheel} passive={false}/>

          {librariesReady ? (
            <Canvas key={`canvas_${appId}`} appId={appId}/>
          ) : (
            <EmptyState className="editor-body-loader">
              <Loading large/>
            </EmptyState>
          )}

          {(canEdit || isAdmin) && (
              <><LeftPanel/>
            <LeftNav/></>
          )}

          {/*<ObjectPanel />*/}

          <Routes/>

          <ModalWrapper/>

        </div>

        {children}
      </>
    )
  }
}

const mapStateToProps = (state, {match}) => {

  const {appId} = match.params
  const app = getApp(state, appId)

  return {
    app,
    canEdit: canEditDraft(state, appId),
    isAdmin: isAdmin(state),
  }

}

export default connect(mapStateToProps, {
  requestData,
  loadDraft,
  setData
})(Editor)

const Routes = () => {
  return (
    <Switch>
      <Route path="/apps/:appId/screens" component={Screens}/>
      <Route path="/apps/:appId/trace" component={Trace}/>
      <Route path="/apps/:appId/layers" component={LayerEditor}/>
    </Switch>
  )
}
