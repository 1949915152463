// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  position: relative;
  cursor: default;
}

.label-editing .label-text {
  white-space: pre;
  color: transparent;
}

.label input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  font: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  display: block;
  background: transparent;
  border: none;
  outline: none;
  min-width: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Label.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,WAAW;EACX,aAAa;EACb,cAAc;EACd,UAAU;EACV,SAAS;EACT,cAAc;EACd,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,cAAc;AAChB","sourcesContent":[".label {\n  position: relative;\n  cursor: default;\n}\n\n.label-editing .label-text {\n  white-space: pre;\n  color: transparent;\n}\n\n.label input {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  font: inherit;\n  color: inherit;\n  padding: 0;\n  margin: 0;\n  display: block;\n  background: transparent;\n  border: none;\n  outline: none;\n  min-width: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
