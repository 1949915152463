// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  padding: 0;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
  margin-bottom: 20px;
}
.menu__item {
  padding: 6px 20px;
  list-style-type: none;
  transition: background-color 200ms;
  cursor: pointer;
  position: relative;
}
.menu__item:hover {
  background-color: #a0c4fc;
}
.menu__item a {
  text-decoration: none;
}
.menu__item a:hover {
  text-decoration: none;
}
.menu__item a:active .mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}
.menu__item--active::before {
  content: "";
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  border-bottom-width: 2px;
  border-color: #3e87f8;
  border-bottom-style: solid;
}
.menu__item--active a {
  color: #3e87f8;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Menu.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;EACA,qBAAA;EACA,kCAAA;EACA,eAAA;EACA,kBAAA;AACJ;AAAI;EACE,yBAAA;AAEN;AACI;EAEE,qBAAA;AAAN;AAEM;EAEE,qBAAA;AADR;AAKQ;EACE,aAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,UAAA;AAHV;AASM;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,UAAA;EACA,wBAAA;EACA,qBAAA;EACA,0BAAA;AAPR;AAUM;EACE,cAAA;AARR","sourcesContent":[".menu {\n  padding: 0;\n  position: relative;\n  display: flex;\n  flex: 1 0 auto;\n  transform: none;\n  will-change: transform;\n  margin-bottom: 20px;\n\n  &__item {\n    padding: 6px 20px;\n    list-style-type: none;\n    transition: background-color 200ms;\n    cursor: pointer;\n    position: relative;\n    &:hover {\n      background-color: lighten(#3e87f8, 20%);\n    }\n\n    a {\n\n      text-decoration: none;\n\n      &:hover {\n\n        text-decoration: none;\n      }\n\n      &:active {\n        .mdc-tab-indicator {\n          display: flex;\n          position: absolute;\n          top: 0;\n          left: 0;\n          justify-content: center;\n          width: 100%;\n          height: 100%;\n          pointer-events: none;\n          z-index: 1;\n        }\n      }\n    }\n\n    &--active {\n      &::before{\n        content: \"\";\n        display: flex;\n        position: absolute;\n        top: 0;\n        left: 0;\n        justify-content: center;\n        width: 100%;\n        height: 100%;\n        pointer-events: none;\n        z-index: 1;\n        border-bottom-width: 2px;\n        border-color: #3e87f8;\n        border-bottom-style: solid;\n    }\n\n      a {\n        color: #3e87f8;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
