// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formula-control.formula-control-invalid .entity-textarea {
  background-color: #fdd;
}

.formula-control {
  position: relative;
}

.formula-control.formula-control-contained .bindable-text-control-header {
  margin: 0;
}

.formula-control.formula-control-contained .bindable-text-control-label {
  display: none;
}

.formula-control.formula-control-contained .bindable-text-control-trigger-wrapper {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.formula-control.formula-control-contained .entity-textarea {
  margin: 0;
  padding-right: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/FormulaControl.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,mBAAmB;AACrB","sourcesContent":[".formula-control.formula-control-invalid .entity-textarea {\n  background-color: #fdd;\n}\n\n.formula-control {\n  position: relative;\n}\n\n.formula-control.formula-control-contained .bindable-text-control-header {\n  margin: 0;\n}\n\n.formula-control.formula-control-contained .bindable-text-control-label {\n  display: none;\n}\n\n.formula-control.formula-control-contained .bindable-text-control-trigger-wrapper {\n  position: absolute;\n  top: 0;\n  right: 10px;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1;\n}\n\n.formula-control.formula-control-contained .entity-textarea {\n  margin: 0;\n  padding-right: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
