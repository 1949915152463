// index.js

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './components/App'
import {connectApp} from './utils/auth'
import store from './redux-store'
import './index.css';


// Set window globals for React, and Proton
window.React = React

//connectSocket(store)
connectApp(store)

//const HMRBundle = hot(module)();

export default ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)