// TemplateSelect.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import 'styles/TemplateSelect.css';
import { pxToUnit } from '../../../../utils/unit';
import {
  DEFAULT_PER_PAGE,
  fetchTemplates,
  fetchTemplateTypes,
  searchTemplates,
} from '../../../../utils/io';
import { Tree } from '../../../Shared/Tree';
import Scrollbar from '../../../Shared/Scrollbar';
import Loading from '../../../Shared/Loading';
import ImagePlaceholder from '../../../Shared/ImagePlaceholder';

const TemplateSelect = (props) => {
  const {
    value,
    onChange,
    options,
    methodCut,
    className,
    searchPlaceholder,
    selectCallback,
    libraries,
    sections,
    loadChildrenPaginated: propsLoadChildrenPaginated,
  } = props;

  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [types, setTypes] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchTemplateTypes().then((result) => {
      setLoading(false);
      setLoaded(true);
      setTypes(result || []);
    });
  }, []);

  const handleSearch = (e) => {
    const v = e.target.value || '';
    const needLoading = !!v;

    setSearch(v);

    if (needLoading) {
      setLoading(true);
      setLoaded(false);

      searchTemplates(v).then((result) => {
        setLoading(false);
        setLoaded(true);
        setSearchResult(result || []);
      });
    } else {
      setLoading(false);
      setLoaded(false);
    }
  };

  const handleClick = (e, node, selected) => {
    if (onChange) {
      onChange(node.id);
    }
  };

  const loadChildrenPaginated = async (node, page = 0, pageLimit = DEFAULT_PER_PAGE) => {
    const data = await fetchTemplates(node.id, { page, perPage: pageLimit });

    let children = [];
    if (data) {
      children = data.map((item) => ({
        id: item.id,
        name: `${item.name}`,
        description: '',
        children: [],
        counter: 0,
        type: item.value,
        size: `${pxToUnit(item.width)} x ${pxToUnit(item.height)} x ${pxToUnit(item.depth)} mm`,
        page: node.page || 0,
        thumb: item.thumb || '',
        expanded: false,
        selected: false,
      }));
    }

    return children;
  };

  const results = search ? searchResult : types;

  return (
    <>
      <div className="editor-add-panel-search">
        <i className="material-icons">search</i>
        <input
          placeholder={searchPlaceholder}
          value={search}
          onChange={handleSearch}
          autoFocus
        />
      </div>
      <div className="template-select__wrapper">
        <Scrollbar>
          {loading && (
            <div className="components-tree editor-add-panel-accordion">
              <div className="components-tree-content">
                <Loading />
              </div>
            </div>
          )}
          {!search && (
            <Tree
              nodes={results}
              value={value}
              loadChildren={loadChildrenPaginated}
              pageLimit={DEFAULT_PER_PAGE}
              paginated={true}
              badge={true}
              selectCallback={handleClick}
              useLocalState={true}
            />
          )}
          {search && (
            <div className="components-tree editor-add-panel-accordion">
              <div className="components-tree-content">
                {loading && <Loading />}
                {searchResult.length > 0 && (
                  <div className="components-tree-children">
                    {searchResult.map((childNode) => (
                      <div className="component-tree-add-item-wrapper" key={childNode.id}>
                        <div
                          onClick={(e) => handleClick(e, childNode)}
                          className={classNames('component-tree-add-item')}
                        >
                          <ImagePlaceholder
                            src={`${childNode.thumb}`}
                            width="300"
                            height="300"
                            className={classNames('component-tree-add-icon', childNode.iconClassName)}
                          />
                          <div className="component-tree-add-name">
                            <div className="component-name">{childNode.name}</div>
                            {childNode.size && (
                              <div className="component-size">{childNode.size}</div>
                            )}
                          </div>
                          {value === childNode.id && (
                            <div className="template-select-option-selected-badge">
                              <span className="icon icon-done"></span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </Scrollbar>
      </div>
    </>
  );
};

TemplateSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired
  ),
  methodCut: PropTypes.any,
  className: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  selectCallback: PropTypes.func,
  libraries: PropTypes.any,
  sections: PropTypes.any,
  loadChildrenPaginated: PropTypes.func,
};

export default TemplateSelect;















// import React, {Component} from 'react'
// import PropTypes from 'prop-types'
// import classNames from 'classnames'

// import 'styles/TemplateSelect.css'
// import { pxToUnit } from "../../../utils/unit";
// import { DEFAULT_PER_PAGE, fetchTemplates, fetchTemplateTypes, searchTemplates } from "../../../utils/io";
// import { Tree } from "../../Shared/Tree";
// import Scrollbar from "../../Shared/Scrollbar";
// import Loading from "../../Shared/Loading";
// import ImagePlaceholder from "../../Shared/ImagePlaceholder";

// export default class TemplateSelect extends Component {

//   static propTypes = {
//     options: PropTypes.arrayOf(
//       PropTypes.shape({
//         id: PropTypes.string.isRequired,
//       }).isRequired
//     ).isRequired,
//   }

//   constructor(props) {

//     super(props)

//     this.state = {
//       error: null,
//       search: "",
//       types: [],
//       searchResult: [],
//       loading: false,
//       loaded: false,
//     }

//   }

//   componentDidMount() {
//     fetchTemplateTypes().then((result) => {
//         this.setState({loading: false, loaded: true, types: result || []})
//       }
//     )
//   }

//   handleSearch = e => {
//     const v = e.target.value || ""

//     const {searchComponents,} = this.props

//     const needLoading = !!v

//     if (needLoading) {

//       this.setState({loading: needLoading, loaded: false, search: v})

//       searchTemplates(v).then((result) => {
//         this.setState({loading: false, loaded: true, searchResult: result || []})
//       })

//     } else {
//       this.setState({loading: false, loaded: false, search: v})
//     }


//   }

//   handleClick = (e, node, selected) => {

//     const { input } = this.props

//     if (input) {
//       input.onChange(node.id)
//     }
    
//   }

//   loadChildrenPaginated = async (node, page = 0, pageLimit = DEFAULT_PER_PAGE) => {

//     let {loaded} = this.props
//     const data = await fetchTemplates(node.id, {page: page, perPage: pageLimit})

//     let children = [];
//     if (data) {
//       children = data.map((item) => (
//           {
//             id: item.id,
//             name: `${item.name}`,
//             description: '',
//             children: [],
//             counter: 0,
//             type: item.value,
//             size: `${pxToUnit(item.width)} x ${pxToUnit(item.height)} x ${pxToUnit(item.depth)} mm`,
//             page: node.page || 0,
//             thumb: item.thumb || "",
//             expanded: false,
//             selected: false,
//           }
//       ))
//     }

//     return children;
//   };

//   render() {
//     const { input, options, methodCut, className,
//       searchPlaceholder,
//       selectCallback,
//       libraries,
//       sections,
//       loadChildrenPaginated
//     } = this.props
//     const value = input && input.value
//     const {search, searchResult, loading, loaded, types} = this.state

//     const results = search ? searchResult : types
//     return (
//       <>
//         {input ? <input {...input} type="hidden" /> : null}



//           <div className="editor-add-panel-search">
//             <i className="material-icons">search</i>
//             <input
//                 placeholder={searchPlaceholder}
//                 value={search}
//                 onChange={this.handleSearch}
//                 autoFocus
//             />
//           </div>
//           <div className={'template-select__wrapper'}>
//           <Scrollbar>

//             {loading &&<div className={'components-tree editor-add-panel-accordion'}><div className="components-tree-content"><Loading/></div></div>}
//             {!search && < Tree nodes={results}
//                                          value = {value}
//                                          loadChildren={this.loadChildrenPaginated}
//                                          pageLimit={DEFAULT_PER_PAGE}
//                                          paginated={true}
//                                          badge={true}
//                                          selectCallback={this.handleClick}
//                                          useLocalState={true}
//             />}

//             {search && (<div className={'components-tree editor-add-panel-accordion'}>
//               <div className="components-tree-content">
//                 {loading && <Loading/>}
//                 {searchResult.length > 0 && (
//                     <div className="components-tree-children">


//                       {searchResult.map((childNode) => (
//                           <div className="component-tree-add-item-wrapper">

//                             <div onClick={e => this.handleClick(e, childNode)} key={childNode.id} className={classNames('component-tree-add-item')}>

//                               <ImagePlaceholder
//                                 src={`${childNode.thumb}`}
//                                 width='300'
//                                 height='300'
//                                 className={classNames('component-tree-add-icon', childNode.iconClassName)}
//                               />

//                               <div className="component-tree-add-name 1">
//                                 <div className={'component-name'}>{childNode.name}</div>
//                                 {childNode.size && <div className={'component-size'}>{childNode.size}</div>}
//                               </div>

//                                {value === childNode.id ? (
//                                     <div className="template-select-option-selected-badge"><span className="icon icon-done"></span></div>

//                                 ) : null}

//                             </div>
//                           </div>
//                       ))}

//                     </div>
//                 )}

//               </div>
//             </div>)}

//           </Scrollbar>
//       </div>
//       </>
//     )
//   }
// }