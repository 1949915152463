// apiUtils.js
import { apiURL } from '../../../utils/io';


// /api/v4/components/{component_id:[A-Za-z0-9]+}
export const getOrientationById = async (id) => {

    try {
        const response = await fetch(`${apiURL}/orientations/${id}/decode`);
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('Error fetching ComponentById types:', error);
    }
}

export const getComponentById = async (id) => {
    try {
        const response = await fetch(`${apiURL}/components/${id}`);
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('Error fetching template types:', error);
    }
}

export const updateOrientation = async (data) => {

    console.log(data)

    try {

        const response = await fetch(`${apiURL}/orientations`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if (!response.ok) {
            throw new Error('Failed to updateComponent');
        }

    } catch (error) {
        console.error('Error updateComponent:', error);
    }

}

export const createTemplate = async (history, template) => {

    try {
        const response = await fetch(`${apiURL}/templates`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(template),
        });

        if (response.ok) {
     
            history.push(`/admin/template-type/${template.template_type_id}/templates`);

        } else {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Ошибка при добавлении нового шаблона');
        }
    } catch (error) {
        console.error('Error adding new template type:', error);
        throw error;
    }

}