import {GROUP} from 'common/constants'

import {getObject, pathLength, subPath, update} from 'common/utils'

export const updateOptions = (list, map, groupId, path = null) => {
  if (!path) {
    path = map[groupId]
  }

  let obj = getObject(list, path)

  if (obj.type !== GROUP) {
    return updateParentOptions(list, map, obj.id)
  }

  let newObj = obj


  if (obj !== newObj) {
    list = update(list, path, newObj)
  }

  return list
}

export const updateParentOptions = (list, map, objectId) => {
  let path = map[objectId]

  if (pathLength(path) > 1) {
    let parentPath = subPath(path, pathLength(path) - 1)
    let parentObj = getObject(list, parentPath)
    let parentId = parentObj.id

    list = updateOptions(list, map, parentId)
  }

  return list
}
