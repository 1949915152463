// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapped-text {
  padding: 8px 4px 8px 8px;
}

.wrapped-text .wrapped-text-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapped-text label {
  font-size: 15px;
  margin-top: 12px;
  line-height: 12px;
  text-align: left;
  display: block;
  flex-shrink: 0;
  color: #8a8a8a;
  pointer-events: none;
  margin-bottom: 12px;
}

.wrapped-text textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Forms/WrappedText.scss"],"names":[],"mappings":"AAAC;EACC,wBAAA;AACF;;AAGC;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAAH;;AAGC;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA;EACA,cAAA;EACA,oBAAA;EACA,mBAAA;AAAH;;AAEC;EACE,WAAA;EACA,yBAAA;AACH","sourcesContent":[" .wrapped-text {\n  padding: 8px 4px 8px 8px;\n\n}\n\n .wrapped-text .wrapped-text-inner {\n   display: flex;\n   flex-direction: column;\n   width: 100%;\n}\n\n .wrapped-text label {\n   font-size: 15px;\n   margin-top: 12px;\n   line-height: 12px;\n   text-align: left;\n   display: block;\n   flex-shrink: 0;\n   color: #8a8a8a;\n   pointer-events: none;\n   margin-bottom: 12px;\n }\n .wrapped-text textarea {\n   width: 100%;\n   border: 1px solid #e0e0e0;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
