// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-dropdown {
  border-radius: 6px;
  margin: 0 -16px;
  overflow: hidden;

}

.color-dropdown-inner-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px 10px 16px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.color-dropdown:not(.disabled) {
  cursor: pointer;
}

.color-dropdown {
  margin-top: 16px;
}

.color-dropdown-texts {
  flex: 1 1;
}

.color-dropdown-dark .color-dropdown-icon {
  background-color: #fff;
}
.color-dropdown-dark .color-dropdown-texts {
  color: #fff;
}

.color-dropdown-light .color-dropdown-icon {
  background-color: rgba(0, 0, 0, 0.87);
}
.color-dropdown-light .color-dropdown-texts {
  color: rgba(0, 0, 0, 0.87);
}

.color-dropdown-title {
  font-size: 15px;
  line-height: 15px;
}

.color-dropdown-hex {
  font-size: 14px;
  line-height: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ColorDropdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;;AAElB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,4BAA4B;EAC5B,8CAA8C;EAC9C,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;;AAEA;EACE,qCAAqC;AACvC;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".color-dropdown {\n  border-radius: 6px;\n  margin: 0 -16px;\n  overflow: hidden;\n\n}\n\n.color-dropdown-inner-wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 10px 12px 10px 16px;\n  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);\n  border-radius: 6px;\n}\n\n.color-dropdown:not(.disabled) {\n  cursor: pointer;\n}\n\n.color-dropdown {\n  margin-top: 16px;\n}\n\n.color-dropdown-texts {\n  flex: 1 1;\n}\n\n.color-dropdown-dark .color-dropdown-icon {\n  background-color: #fff;\n}\n.color-dropdown-dark .color-dropdown-texts {\n  color: #fff;\n}\n\n.color-dropdown-light .color-dropdown-icon {\n  background-color: rgba(0, 0, 0, 0.87);\n}\n.color-dropdown-light .color-dropdown-texts {\n  color: rgba(0, 0, 0, 0.87);\n}\n\n.color-dropdown-title {\n  font-size: 15px;\n  line-height: 15px;\n}\n\n.color-dropdown-hex {\n  font-size: 14px;\n  line-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
