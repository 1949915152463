import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import { deleteApp } from '../../../ducks/apps/actions'
import { getApps } from '../../../ducks/apps/selectors'

import './List.css'
import OverflowMenu from "./OverflowMenu";

class DraftList extends Component {

  render() {
    
    let { apps } = this.props

    if (!apps ) {
      return null
    }

    return (
      <div>
        <h1>
          Проекты
        </h1>
        <div className="boards-grid-inner">
            <div className="boards-grid">
              
          <Link className="board-brick create-new-board-brick"  to={`/draft/init/mode`}>
            <div className="board-brick__content">
              <span>Новый проект test</span>
            </div>
          </Link>

          <Link className="board-brick create-new-board-brick"  to={`/first-app-setup`}>
            <div className="board-brick__content">
              <span>Новый проект</span>
            </div>
          </Link>

          {apps.map((app) => (
            <Link key={app.id} className="board-item board-brick" to={`/apps/${app.id}`}>
              <div className="board-brick__content">

                <div className="board-brick__preview">
                  <div className="board-brick__preview__overlay"></div>
                </div>

                <div className="board-brick__footer">
                  <div>
                    <div className="board-brick__title">
                      {app.name}
                    </div>
                    <div className="board-brick__last-modified"> {/*{app.update_at}*/}</div>
                  </div>

                </div>

                <OverflowMenu appId={app.id}/>

              </div>
            </Link>
          ))}
        </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => ({
  apps: getApps(state),
})

export default withRouter(
  connect(mapStateToProps, {
    deleteApp,
  })(DraftList)
)