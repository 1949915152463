
export const TOLERENCE = 3;


export const positionObject = (object, allObjects) => {
  Object.keys(allObjects).filter(function (id) {
    return id !== object.id;
  }).forEach(function (id) {
    const sibling = allObjects[id];
  });
  return object;
};

export const calculateSnapPoints = function(elements, params)  {
  const excludeIds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const map = {};
  Object.keys(elements).forEach(function (id) {
    if (excludeIds.indexOf(id) !== -1) {
      return;
    }

    const el = elements[id];
    const bbox = el.getBoundingClientRect();
    const points = params.map(function (param) {
      return Math.round(bbox[param]);
    });
    points.forEach(function (pt) {
      if (!map[pt]) {
        map[pt] = [];
      }

      map[pt].push(id);
    });
  });
  const arr = Object.keys(map).map(function (i) {
    return +i;
  });
  arr.sort(function (a, b) {
    return a - b;
  });
  const result = arr.map(function (point) {
    return {
      point: point,
      objects: map[point]
    };
  });
  return result;
};

export const  getSnap = (snapPoints, point) => {
  // Refactor to use in-place array tree operation
  // Find point before and after
  let previousPoint = void 0;

  for (let i = 0; i < snapPoints.length; i += 1) {
    if (snapPoints[i].point === point) {
      return snapPoints[i];
    } else if (snapPoints[i].point > point) {
      const posDiff = snapPoints[i].point - point;

      if (posDiff > TOLERENCE) {
        return previousPoint;
      }

      if (!previousPoint) {
        return snapPoints[i];
      }

      const negDiff = point - previousPoint.point;
      const posScore = snapPoints[i].objects.length / posDiff;
      const negScore = previousPoint.objects.length / negDiff;
      return posScore > negScore ? snapPoints[i] : previousPoint;
    } else if (point - snapPoints[i].point <= TOLERENCE) {
      previousPoint = snapPoints[i];
    }
  }

  return previousPoint;
};
