// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-page-tabs {
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 40px;
}

.modal .settings-page-tabs {
  margin: 0 -56px 40px;
  padding: 0 40px;
}

.settings-modal {
  padding-top: 0;
  width: 580px;
}

.settings-page-tab {
  color: #8a8a8a;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  padding: 16px;
  display: block;
  position: relative;
  margin-bottom: -2px;
}

* + .settings-page-tab {
  margin-left: 20px;
}

.settings-page-tab.active {
  color: #000;
}

.settings-page-tab.active:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  border-radius: 2px;
  background-color: #000;
}

.settings-page-close-button {
  position: absolute;
  top: 16px;
  right: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Settings.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,qBAAqB;EACrB,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb","sourcesContent":[".settings-page-tabs {\n  border-bottom: 1px solid #ddd;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  margin: 0 0 40px;\n}\n\n.modal .settings-page-tabs {\n  margin: 0 -56px 40px;\n  padding: 0 40px;\n}\n\n.settings-modal {\n  padding-top: 0;\n  width: 580px;\n}\n\n.settings-page-tab {\n  color: #8a8a8a;\n  font-weight: 500;\n  font-size: 16px;\n  text-decoration: none;\n  padding: 16px;\n  display: block;\n  position: relative;\n  margin-bottom: -2px;\n}\n\n* + .settings-page-tab {\n  margin-left: 20px;\n}\n\n.settings-page-tab.active {\n  color: #000;\n}\n\n.settings-page-tab.active:before {\n  content: '';\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  height: 3px;\n  border-radius: 2px;\n  background-color: #000;\n}\n\n.settings-page-close-button {\n  position: absolute;\n  top: 16px;\n  right: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
