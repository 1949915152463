// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Анимация появления */
.accordion-animation-enter {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease-in, max-height 300ms ease-in, visibility 300ms ease-in;
}

.accordion-animation-enter-active {
    max-height: 500px; /* Максимальная высота вашего контента */
    opacity: 1;
    visibility: visible;
}

/* Анимация исчезновения */
.accordion-animation-exit {
    max-height: 500px;
    opacity: 1;
    visibility: visible;
    transition: opacity 300ms ease-out, max-height 300ms ease-out, visibility 300ms ease-out;
}

.accordion-animation-exit-active {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Editor/AddObject/ComponentsAccordion/Accordion.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,UAAU;IACV,kBAAkB;IAClB,qFAAqF;AACzF;;AAEA;IACI,iBAAiB,EAAE,wCAAwC;IAC3D,UAAU;IACV,mBAAmB;AACvB;;AAEA,0BAA0B;AAC1B;IACI,iBAAiB;IACjB,UAAU;IACV,mBAAmB;IACnB,wFAAwF;AAC5F;;AAEA;IACI,aAAa;IACb,UAAU;IACV,kBAAkB;AACtB","sourcesContent":["/* Анимация появления */\n.accordion-animation-enter {\n    max-height: 0;\n    opacity: 0;\n    visibility: hidden;\n    transition: opacity 300ms ease-in, max-height 300ms ease-in, visibility 300ms ease-in;\n}\n\n.accordion-animation-enter-active {\n    max-height: 500px; /* Максимальная высота вашего контента */\n    opacity: 1;\n    visibility: visible;\n}\n\n/* Анимация исчезновения */\n.accordion-animation-exit {\n    max-height: 500px;\n    opacity: 1;\n    visibility: visible;\n    transition: opacity 300ms ease-out, max-height 300ms ease-out, visibility 300ms ease-out;\n}\n\n.accordion-animation-exit-active {\n    max-height: 0;\n    opacity: 0;\n    visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
