import React, {Component} from 'react'
import {formValueSelector, reduxForm} from 'redux-form'
import {connect} from 'react-redux'


import {defaultBranding} from 'utils/colors'
import Stepper from 'components/Shared/Stepper'

import WizardFormPlatformPage from './WizardFormPlatformPage'
import WizardFormBrandingPage from './WizardFormBrandingPage'

import 'styles/NewApp.css'
import {FORM_NAME} from './shared'
import WizardFormTemplatesPage from './WizardFormTemplatesPage'
import {ensureCreateDraft} from "../../ducks/apps/actions";

class WizardForm extends Component {

  static propTypes = {}

  state = {
    page: 1,
  }

  componentDidMount() {

  }

  close = () => {

    const { history } = this.props

    history.push(`/`)

  }

  handleNextPage = () => {
    this.setState(state => ({ page: state.page + 1 }))
  }

  handlePreviousPage = () => {
    this.setState(state => ({ page: state.page - 1 }))
  }

  handleSubmitAdvanced = () => {
    this.setState({ page: 3 })
  }

  handleFinalSubmit = ({ ...data }) => {

    const {ensureCreateDraft, history} = this.props

    if (data.sizes == 'custom') {
      data.template_id = ""
    }

    console.log(data)



    return ensureCreateDraft(data).then((action)=>{

      const {value: {data}} = action

      if (data && data.id) {
        history.push(`/apps/${data.id}`)
      }

    })
    
  }

  renderStepper() {
    const { page } = this.state

    if (page === 'advanced') {
      return null
    }

    return (
      <Stepper
        steps={['Тип кейса', 'Выбор кейса', 'Настройки']}
        currentStep={page - 1}
      />
    )
  }

  render() {

    const { page } = this.state

    const initialValues = {

    }

    return (
      <>

        {this.renderStepper()}

        {page === 1 && (
          <WizardFormPlatformPage
            initialValues={initialValues}
            onSubmit={this.handleNextPage}
            handlePreviousPage={this.close}
          />
        )}

        {page === 2 && (
            <WizardFormTemplatesPage
                handlePreviousPage={this.handlePreviousPage}
                initialValues={initialValues}
                onSubmit={this.handleNextPage}
            />
        )}

        {page === 3 && (
          <WizardFormBrandingPage
            handlePreviousPage={this.handlePreviousPage}
            initialValues={initialValues}
            onSubmit={this.handleFinalSubmit}
          />
        )}

        <WizardFormDestroyer />

      </>
    )
  }
}

const WizardFormDestroyer = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(() => null)

const mapStateToProps = state => {
  const selector = formValueSelector(FORM_NAME)
  const templateId = selector(state, 'templateId')

  const branding =defaultBranding

  return {
    templateId,
    branding,
  }
}

const mapDispatchToProps = {
  ensureCreateDraft
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardForm)
