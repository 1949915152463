// DraftInitCustomSizesSelectName.js
import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import classNames from 'classnames'


import { ensureCreateDraft } from '../../../ducks/apps/actions'
import { setDraftInitTemplateSize } from './store/actions'

import WizardFooter from '../wizard_footer'

import WrappedTextInput from './components/wrapped_text_input'

const DraftInitTemplateSizesSelectName = ({
  history,
  draftInitTemplateSizes,
  setDraftInitTemplateSize,
  ensureCreateDraft,
}) => {

  const { name, template_id } = draftInitTemplateSizes

  useEffect(() => {

    if (!template_id) {
      history.push('/draft/init/template/sizes')
    }

  }, [template_id, history])



  const handleChange = (value) => {
    setDraftInitTemplateSize({ name: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = { ...draftInitTemplateSizes }

    ensureCreateDraft(data).then((action) => {

      const appData = action.value.data
      if (appData && appData.id) {
        history.push(`/apps/${appData.id}`)
      }

    })
  }

  const isFormValid = () => {
    return name && name.trim() !== ''
  }

  return (
    <>

      <div className="stepper stepper-teal">
        <div className="step step-done">
          <div className="step-bullet">
            <span className="icon icon-done" expanded="false"></span>
          </div>
          <div className="step-text">Тип кейса</div>
        </div>
        <hr />
        <div className="step step-done">
          <div className="step-bullet">
            <span className="icon icon-done" expanded="false"></span>
          </div>
          <div className="step-text">Выбор кейса</div>
        </div>
        <hr />
        <div className="step step-done step-current">
          <div className="step-bullet">3</div>
          <div className="step-text">Настройки</div>
        </div>
      </div>

      <div id="new-app-branding-form" className="new-app-form">
        <div className="panel-section">

          <div className="panel-section-header">
            <h4>Общее</h4>
          </div>

          <div className={classNames('panel-section-body')}>

            <WrappedTextInput
              autoFocus={true}
              label="Название проекта"
              placeholder="Введите наименование рабочей области"
              name="name"
              value={name}
              onChange={handleChange}
            />

          </div>
        </div>
      </div>

      <WizardFooter>

        <Link to={`/draft/init/template/sizes`} className="btn previous">
          Назад
        </Link>

        {isFormValid() && (
          <div className="btn next align-right" onClick={handleSubmit}>
            Создать
          </div>
        )}

      </WizardFooter>
    </>
  );
};

const mapStateToProps = (state) => ({
  draftInitTemplateSizes: state.draftInitTemplateSizes,
})

const mapDispatchToProps = {
  ensureCreateDraft,
  setDraftInitTemplateSize,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DraftInitTemplateSizesSelectName)
)
