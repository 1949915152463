// DraftInit.js
import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';

import 'styles/NewApp.css';
import 'styles/PlatformToggle.css';

import Icon from '../Shared/Icon';
import WizardFooter from './wizard_footer';

const DraftInit = () => {

  const [sizes, setSizes] = useState('')

  const handleSelect = (selectedSize) => {
    setSizes(selectedSize)
  }

  return (
    <>

      <div className="stepper stepper-teal">

        <div className="step step-done step-current">
          <div className="step-bullet">1</div>
          <div className="step-text">Тип кейса</div>
        </div>

        <hr />

        <div className="step">
          <div className="step-bullet">2</div>
          <div className="step-text">Выбор кейса</div>
        </div>

        <hr />

        <div className="step">
          <div className="step-bullet">3</div>
          <div className="step-text">Настройки</div>
        </div>
        
      </div>

      <div id="new-app-platform-form" className="new-app-form">

        <div className="new-app-platform">

          <div className={classNames(
              'new-app-platform-item',
              'new-app-platform-custom',
              { 'new-app-platform-selected': sizes === 'custom' }
            )}
            onClick={() => handleSelect('custom')}
          >

            <div className="new-app-platform-link">
              <div className="new-app-platform-checkmark">
                <Icon type="done" />
              </div>
              <div className="new-app-platform-icon"></div>
              <div className="new-app-platform-title">
                Задать свои габариты кейса или ящика
              </div>
            </div>

          </div>

          <div className={classNames(
              'new-app-platform-item',
              'new-app-platform-template',
              { 'new-app-platform-selected': sizes === 'template' }
            )}
            onClick={() => handleSelect('template')}
          >
            
            <div className="new-app-platform-link">
              <div className="new-app-platform-checkmark">
                <Icon type="done" />
              </div>
              <div className="new-app-platform-icon"></div>
              <div className="new-app-platform-title">
                Выбрать модель DGCASE или подобрать шаблон кейса из нашей базы
              </div>
            </div>

          </div>

        </div>
      </div>

      <WizardFooter>

        <Link to={`/`} className="btn previous">
          Назад
        </Link>

        {sizes === 'custom' && (
          <Link to={`/draft/init/custom/sizes`} className="btn next align-right">
            Далее
          </Link>
        )}

        {sizes === 'template' && (
          <Link to={`/draft/init/template/sizes`} className="btn next align-right">
            Далее
          </Link>
        )}

      </WizardFooter>

    </>

  )
}

export default withRouter(DraftInit)