
import { mergeReducers, sort, traverse } from 'common/utils';

import {
    CREATE_DRAFT,
    DELETE_DRAFT,
    LOAD_DRAFTS,
    LOAD_ALL_DRAFTS,
    LOAD_DRAFT,
    UPDATE_DRAFT,
    PATCH_DRAFT,
    CHECKOUT_DRAFT,
    COPY_DRAFT,
    LOAD_TEMPLATES_LIST,
    LOAD_TEMPLATE,
    SET_DRAFT,
    LOAD_APP,
    DELETE_APP,
    SET_APP_LIBRARIES,
    LOAD_COMPONENT,
    LOAD_APPS_LIST,
    UNSET_LAUNCH_COMPONENT,
    REQUEST_APP,
    REQUEST_ALL,
    UPDATE_APP,
    SET_LOADING_TEMPLATES,


    UPDATE_APP_OUTER_RULE,
    UPDATE_APP_PARTS,
    UPDATE_APP_NAME,
    UPDATE_APP_WIDTH,
    UPDATE_APP_HEIGHT,
    UPDATE_APP_DEPTH,
    UPDATE_APP_LOWER_WIDTH,
    UPDATE_APP_LOWER_HEIGHT,
    UPDATE_APP_BORDER_WIDTH,
    UPDATE_APP_BORDER_RADIUS,
    UPDATE_COMPONENT_POSITION,
    UPDATE_COMPONENT_NAME,
    UPDATE_COMPONENT_WIDTH,
    UPDATE_COMPONENT_HEIGHT,
    UPDATE_COMPONENT_DEPTH,
    INITIALIZE_STATE,

} from './actionTypes';

import { DELETE_OBJECT } from '../editor/objects';

// Импорт функции инициализации начального состояния
import { initializeState, templatesInitState } from './initialState';





const TEMPLATES_INITIAL_STATE = templatesInitState()
const INITIAL_STATE = initializeState()


/* как было изначально

const TEMPLATES_INITIAL_STATE = {
    list: [],
    loading: false,
    loaded: false,
}

const INITIAL_STATE = {
    apps: {},
    list: [],
    all: [],
    loading: false,
    loaded: false,
    uploadInProgress: false,
    templates: null,
}

*/






const updateAppList = (list, app) => {
    return list.map(appFromList => appFromList.id === app.id ? { ...appFromList } : appFromList)
                .sort((a, b) => b.update_at - a.update_at);
};

export default mergeReducers(
    INITIAL_STATE,
    (state, action) => {
        switch (action.type) {



            case INITIALIZE_STATE: {

                const { appId, app } = action.payload;

                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: { 
                            ...state.apps[appId],
                            ...app,
                        },
                    },
                };

            }

            case UPDATE_APP_PARTS: {
                const { appId, parts } = action.payload;
                const app = { ...state.apps[appId], parts: parts };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_APP_OUTER_RULE: {
                const { appId, outerRule } = action.payload;
                const app = { ...state.apps[appId], outer_rule: outerRule };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_APP_NAME: {
                const { appId, name } = action.payload;
                const app = { ...state.apps[appId], name };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            // Обновление ширины приложения и компонента
            case UPDATE_APP_WIDTH: {
                const { appId, width } = action.payload;
                const app = {
                    ...state.apps[appId],
                    width,
                    components: {
                        ...state.apps[appId].components,
                        [appId]: {
                            ...state.apps[appId].components[appId],
                            width,
                        },
                    },
                };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            // Обновление высоты приложения и компонента
            case UPDATE_APP_HEIGHT: {
                const { appId, height } = action.payload;
                const app = {
                    ...state.apps[appId],
                    height,
                    components: {
                        ...state.apps[appId].components,
                        [appId]: {
                            ...state.apps[appId].components[appId],
                            height,
                        },
                    },
                };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            // Обновление глубины приложения и компонента
            case UPDATE_APP_DEPTH: {
                const { appId, depth } = action.payload;
                const app = {
                    ...state.apps[appId],
                    depth,
                    components: {
                        ...state.apps[appId].components,
                        [appId]: {
                            ...state.apps[appId].components[appId],
                            depth,
                        },
                    },
                };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_APP_LOWER_WIDTH: {
                const { appId, lowerWidth } = action.payload;
                const app = { ...state.apps[appId], lower_width: lowerWidth };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_APP_LOWER_HEIGHT: {
                const { appId, lowerHeight } = action.payload;
                const app = { ...state.apps[appId], lower_height: lowerHeight };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_APP_BORDER_WIDTH: {
                const { appId, borderWidth } = action.payload;
                const app = { ...state.apps[appId], border_width: borderWidth };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_APP_BORDER_RADIUS: {
                const { appId, borderRadius } = action.payload;
                const app = { ...state.apps[appId], border_radius: borderRadius };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            // Обновление свойств компонента
            case UPDATE_COMPONENT_POSITION: {
                const { appId, componentId, x, y } = action.payload;
                const component = { ...state.apps[appId].components[componentId], x, y };
                const app = {
                    ...state.apps[appId],
                    components: {
                        ...state.apps[appId].components,
                        [componentId]: component,
                    },
                };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_COMPONENT_NAME: {
                const { appId, componentId, name } = action.payload;
                const component = { ...state.apps[appId].components[componentId], name };
                const app = {
                    ...state.apps[appId],
                    components: {
                        ...state.apps[appId].components,
                        [componentId]: component,
                    },
                };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_COMPONENT_WIDTH: {
                const { appId, componentId, width } = action.payload;
                const component = { ...state.apps[appId].components[componentId], width };
                const app = {
                    ...state.apps[appId],
                    components: {
                        ...state.apps[appId].components,
                        [componentId]: component,
                    },
                };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_COMPONENT_HEIGHT: {
                const { appId, componentId, height } = action.payload;
                const component = { ...state.apps[appId].components[componentId], height };
                const app = {
                    ...state.apps[appId],
                    components: {
                        ...state.apps[appId].components,
                        [componentId]: component,
                    },
                };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case UPDATE_COMPONENT_DEPTH: {
                const { appId, componentId, depth } = action.payload;
                const component = { ...state.apps[appId].components[componentId], depth };
                const app = {
                    ...state.apps[appId],
                    components: {
                        ...state.apps[appId].components,
                        [componentId]: component,
                    },
                };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }
            
            case SET_DRAFT: {

                let app = action.payload;
                app = { ...state.apps[app.id], ...app };

                
                const temp = {
                    ...state,
                    loading: false,
                    apps: {
                        ...state.apps,
                        [app.id]: app,
                    },
                    list: updateAppList(state.list, app),
                }

                // console.log(temp)

                return temp
            }

            case `${LOAD_DRAFT}_FULFILLED`: {
                let { payload: { data: app } } = action;
                app = { ...state.apps[app.id], ...app };
                return {
                    ...state,
                    loading: false,
                    apps: {
                        ...state.apps,
                        [app.id]: app,
                    },
                    list: updateAppList(state.list, app),
                };
            }

            case `${LOAD_DRAFTS}_FULFILLED`: {
                let { payload: { data } } = action;
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    list: (data || []).map(app => ({
                        id: app.id,
                        name: app.name,
                        update_at: new Date(app.update_at),
                    }))
                };
            }

            case `${LOAD_ALL_DRAFTS}_FULFILLED`: {
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    all: action.payload.data,
                };
            }

            case DELETE_OBJECT: {
                const { id } = action;
                let newState = { ...state };
                Object.keys(newState.apps).forEach(appId => {
                    if (newState.apps[appId].components[id]) {
                        const { [id]: removed, ...newComponents } = newState.apps[appId].components;
                        newState.apps[appId].components = newComponents;
                    }
                });
                return newState;
            }

            case LOAD_APP: {
                let { app } = action;
                app = { ...state.apps[app.id], ...app };
                return {
                    ...state,
                    loading: false,
                    apps: {
                        ...state.apps,
                        [app.id]: app,
                    },
                    list: updateAppList(state.list, app),
                };
            }

            case `${CREATE_DRAFT}_FULFILLED`: {
                let { payload: { data: app } } = action;
                return {
                    ...state,
                    loading: false,
                    apps: {
                        ...state.apps,
                        [app.id]: app,
                    },
                    list: updateAppList(state.list, app),
                };
            }

            case `${DELETE_DRAFT}_FULFILLED`: {
                let { payload: { data: { id } } } = action;
                return {
                    ...state,
                    list: state.list.filter(app => app.id !== id),
                };
            }

            case `${COPY_DRAFT}_FULFILLED`: {
                let { payload: { data: app } } = action;
                return {
                    ...state,
                    loading: false,
                    apps: {
                        ...state.apps,
                        [app.id]: app,
                    },
                    list: [...state.list, {
                        id: app.id,
                        name: app.name,
                        update_at: app.update_at
                    }].sort((a, b) => b.update_at - a.update_at),
                };
            }

            case LOAD_COMPONENT: {
                const { appId, componentId, component } = action;
                let app = state.apps[appId];
                if (!app) return state;

                if (component) {
                    app = {
                        ...app,
                        components: {
                            ...app.components,
                            [componentId]: component,
                        },
                    };
                } else {
                    app = { ...app, component: { ...app.components } };
                    delete app.components[componentId];
                }

                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case LOAD_APPS_LIST: {
                const { apps, lastApp } = action;
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    list: apps.map(app => ({
                        ...app,
                        accessedAt: new Date(app.accessedAt),
                    })),
                    lastApp,
                };
            }

            case `${LOAD_TEMPLATES_LIST}_FULFILLED`: {
                const { payload: { data } } = action;
                return {
                    ...state,
                    templates: {
                        list: [{ id: 'blank', name: 'Blank' }, ...data],
                        loading: false,
                        loaded: true,
                    },
                };
            }

            case `${LOAD_TEMPLATE}_FULFILLED`: {

                const { payload: { data } } = action;
                return {
                    ...state,
                    templates: {
                        ...state.templates,
                        selected: data,
                        loading: false,
                        loaded: true,
                    },
                };


            }

            case SET_LOADING_TEMPLATES: {
                const { payload: { data, loading, loaded } } = action;
                return {
                    ...state,
                    templates: {
                        ...state.templates,
                        ...TEMPLATES_INITIAL_STATE,
                        loading,
                        loaded,
                    },
                };
            }

            case UPDATE_DRAFT: {
                const { appId, data } = action;
                const app = { ...state.apps[appId], ...data };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case `${PATCH_DRAFT}_FULFILLED`: {
                const { payload: { data: app } } = action;
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [app.id]: app,
                    },
                    list: updateAppList(state.list, app),
                };
            }

            case `${CHECKOUT_DRAFT}_FULFILLED`: {
                const { payload: { data: app } } = action;
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [app.id]: app,
                    },
                    list: updateAppList(state.list, app),
                };
            }

            case UPDATE_APP: {

                const { appId, data } = action
                const app = { ...state.apps[appId], ...data }
                
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                }

            }

            case DELETE_APP: {
                const { appId } = action;
                return {
                    ...state,
                    list: state.list.filter(app => app.id !== appId),
                };
            }

            case UNSET_LAUNCH_COMPONENT: {
                const { appId, componentId } = action;
                let app = state.apps[appId];
                if (!app) return state;

                let { launchComponentId, authComponentId } = app;
                let ids = Object.keys(app.components).filter(k => k !== componentId);

                let otherComponentId = optimize(ids, id => {
                    let component = app.components[id];
                    return -(component.x + component.y);
                }) || null;

                if (launchComponentId === componentId) launchComponentId = otherComponentId;
                if (authComponentId === componentId) authComponentId = otherComponentId;

                let appChanges = { launchComponentId, authComponentId };

                app = { ...app, ...appChanges };
                return {
                    ...state,
                    apps: {
                        ...state.apps,
                        [appId]: app,
                    },
                };
            }

            case SET_APP_LIBRARIES: {
                const { appId, libraries } = action.payload;
                return {
                    ...state,
                    loading: false,
                    apps: {
                        ...state.apps,
                        [appId]: {
                            ...state.apps[appId],
                            libraries,
                        },
                    },
                };
            }

            default:
                return state;
        }
    }
);