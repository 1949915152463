// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-component-backdrop {
  fill: none;
  pointer-events: all;
}
`, "",{"version":3,"sources":["webpack://./src/styles/AddComponentInstance.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,mBAAmB;AACrB","sourcesContent":[".add-component-backdrop {\n  fill: none;\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
