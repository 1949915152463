import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import Button from '../Shared/Button'
import Loading from '../Shared/Loading'
import WizardFooter from './WizardFooter'
import {FORM_NAME} from './shared'

import { ensureTemplatesAreLoaded } from '../../ducks/apps/actions'
import { getTemplatesState } from '../../ducks/apps/selectors'

import TemplateSelect from './TemplateSelect'
import classNames from "classnames";
import WrappedNumberInput from "../Shared/Forms/WrappedNumberInput";

const fakeLoadingTemplates = []

class WizardFormTemplatesPage extends Component {

    componentDidMount() {
        const {ensureTemplatesAreLoaded} = this.props; ensureTemplatesAreLoaded()
    }

    render() {
        const {
            handleSubmit,
            handlePreviousPage,
            selectedTemplate,
            templates,
            sizes,
            loading,
        } = this.props

        const {invalid} = this.props
        return (
            <>
                <form
                    id="new-app-template-form"
                    className="new-app-form"
                    onSubmit={handleSubmit}
                >
                    {sizes === 'custom' ? (
                        <div className="panel-section">
                            <div className="panel-section-header">
                                <h4>Укажите габариты вашего пространства</h4>
                            </div>
                            <div className={classNames('panel-section-body')} >
                                <Field
                                    autoFocus={true}
                                    type="number"
                                    label="Ширина"
                                    name="width"
                                    converted={true}

                                    suffix={'mm'}
                                    component={WrappedNumberInput}
                                />

                                <Field

                                    type="number"
                                    label="Высота"
                                    name="height"
                                    converted={true}

                                    suffix={'mm'}
                                    component={WrappedNumberInput}
                                />
                                <Field

                                    type="number"
                                    label="Глубина"
                                    name="depth"
                                    converted={true}

                                    suffix={'mm'}
                                    component={WrappedNumberInput}
                                />

                            </div>
                        </div>
                    ) : loading ? (
                        <div className="new-app-template-loading">

                            <TemplateSelect
                                options={fakeLoadingTemplates}
                            />

                            <Loading expanded/>
                        </div>
                    ) : (

                        <Field
                            component={TemplateSelect}
                            name="template_id"
                            options={templates}

                        />
                        
                    )}

                </form>
                <WizardFooter>
                    {handlePreviousPage ? (
                        <Button className="previous" onClick={handlePreviousPage}>
                            Назад
                        </Button>
                    ) : null}
                    <Button
                        className="next align-right"
                        type="submit"
                        disabled={loading || invalid}
                        form="new-app-template-form"
                    >
                        Далее
                    </Button>
                </WizardFooter>
            </>
        )
    }
}

function mapStateToProps(state) {
    const selector = formValueSelector(FORM_NAME)
    const sizes = selector(state, 'sizes')
    const templatesState = getTemplatesState(state, sizes)

    return {
        sizes,
        templates: templatesState.list,
        loading: templatesState.loading,
        selectedTemplate: selector(state, 'template_id'),
    }
}

const mapDispatchToProps = {
    ensureTemplatesAreLoaded,
}

export const validate = data => {

    let errors = {}
    if (data.sizes === "custom") {
        if (!data.height) {
            errors.height = 'Укажите глубину слоя'
        }
        if (!data.width) {
            errors.width = 'Укажите глубину слоя'
        }
        if (!data.depth) {
            errors.depth = 'Укажите глубину слоя'
        }
    } else {
        if (!data.template_id) {
            errors.template_id = 'Выберите шаблон'
        }
    }


    return errors
}
export default reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(connect(mapStateToProps, mapDispatchToProps)(WizardFormTemplatesPage))
