// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snap-grid {
}

.snap-grid line {
  fill: none;
  stroke: #b8b8b8;
}
`, "",{"version":3,"sources":["webpack://./src/styles/SnapGrid.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,UAAU;EACV,eAAe;AACjB","sourcesContent":[".snap-grid {\n}\n\n.snap-grid line {\n  fill: none;\n  stroke: #b8b8b8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
