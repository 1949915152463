// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-inspect-boolean-binding {
  padding: 2px 4px 2px 10px;
  color: #fff;
  background: #3e87f8;
  flex: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
}

.library-inspect-boolean-binding .icon {
  background: #fff;
  cursor: pointer;
}

.library-inspect-boolean-add-binding {
  color: #8a8a8a;
  margin-left: 10px;
}

.boolean-control-boxed {
  background-color: white;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
}
.boolean-control-boxed:hover,
.boolean-control-boxed > * {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Editor/Inspect/Libraries/BooleanControl.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,uBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AACF;AACE;;EAEE,eAAA;AACJ","sourcesContent":[".library-inspect-boolean-binding {\n  padding: 2px 4px 2px 10px;\n  color: #fff;\n  background: #3e87f8;\n  flex: unset;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 4px;\n}\n\n.library-inspect-boolean-binding .icon {\n  background: #fff;\n  cursor: pointer;\n}\n\n.library-inspect-boolean-add-binding {\n  color: #8a8a8a;\n  margin-left: 10px;\n}\n\n.boolean-control-boxed {\n  background-color: white;\n  padding: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: 6px;\n\n  &:hover,\n  > * {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
