import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import Button from '../Shared/Button'
import WizardFooter from './WizardFooter'
import {ensureCreateDraft} from '../../ducks/apps/actions'


import {FORM_NAME} from './shared'
import classNames from "classnames";
import WrappedNumberInput from "../Shared/Forms/WrappedNumberInput";

class WizardFormBrandingPage extends Component {

  render() {
    let {
      handleSubmit,
      handlePreviousPage,
      name,
      submitting,
    } = this.props

    return (
      <>
        <form
          id="new-app-branding-form"
          className="new-app-form"
          onSubmit={handleSubmit}
        >

          <div className="panel-section">

            <div className="panel-section-header">
              <h4>Общее</h4>
            </div>

            <div
                className={classNames('panel-section-body')}
            >
              <Field
                  autoFocus={true}
                  type="text"
                  label="Название проекта"
                  name="name"


                  component={WrappedNumberInput}
              />


            </div>


          </div>
        </form>
        <WizardFooter>
          {handlePreviousPage ? (
            <Button className="previous" onClick={handlePreviousPage}>
              Назад
            </Button>
          ) : null}
          <Button
            className="next align-right"
            type="submit"
            form="new-app-branding-form"
            disabled={!name}
            loading={submitting}
          >
            Создать
          </Button>
        </WizardFooter>
      </>
    )
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector(FORM_NAME)
  const templateId = selector(state, 'templateId')

  return {
    templateId,
    name: selector(state, 'name'),
  }
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {ensureCreateDraft})(WizardFormBrandingPage)
)
