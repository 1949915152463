import React, {Component} from 'react'

import {Field, reduxForm} from 'redux-form'

import Modal from 'components/Shared/Modal'
import WrappedInput from 'components/Shared/Forms/WrappedInput'


import 'styles/CopyApp.css'


class RenameDraftForm extends Component {
    render() {
        let { onCancel, handleSubmit } = this.props

        return (
            <form onSubmit={handleSubmit}>
                <Modal.Header title="Переименовать" />
                <Modal.Content>
                    <Field
                        autoSelect
                        name="name"
                        label="Название"
                        component={WrappedInput}
                        placeholder="Название"
                    />

                </Modal.Content>
                <Modal.Actions>
                    <Modal.Button type="button" text onClick={onCancel}>
                        Отмена
                    </Modal.Button>
                    <Modal.Button type="submit">Переименовать</Modal.Button>
                </Modal.Actions>
            </form>
        )
    }
}

export default reduxForm({ form: 'renameDraft' })(RenameDraftForm)
