// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackbar {
  font-size: 14px;
  background: #333;
  color: #fff;
  border-radius: 6px;
  overflow: hidden;
  min-width: 200px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
              0px 4px 5px 0px rgba(0, 0, 0, 0.14),
              0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.snackbar-stripe {
  height: 4px;
}

.snackbar-content {
  padding: 20px 50px 20px 28px;
  line-height: 1.3em;
}

.snackbar .icon-button-wrapper {
  position: absolute;
  top: 30px;
  right: 14px;
  opacity: 0;
  transition: opacity 0.2;
}

.snackbar:hover .icon-button-wrapper {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Snackbar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB;;kDAEgD;AAClD;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".snackbar {\n  font-size: 14px;\n  background: #333;\n  color: #fff;\n  border-radius: 6px;\n  overflow: hidden;\n  min-width: 200px;\n  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),\n              0px 4px 5px 0px rgba(0, 0, 0, 0.14),\n              0px 1px 10px 0px rgba(0, 0, 0, 0.12);\n}\n\n.snackbar-stripe {\n  height: 4px;\n}\n\n.snackbar-content {\n  padding: 20px 50px 20px 28px;\n  line-height: 1.3em;\n}\n\n.snackbar .icon-button-wrapper {\n  position: absolute;\n  top: 30px;\n  right: 14px;\n  opacity: 0;\n  transition: opacity 0.2;\n}\n\n.snackbar:hover .icon-button-wrapper {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
