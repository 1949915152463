import React from 'react'
import {connect} from 'react-redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import Button from '../Shared/Button'
import WizardFooter from './WizardFooter'
import {FORM_NAME} from './shared'

import PlatformToggle from './PlatformToggle'

const WizardFormPlatformPage = props => {
  
  const { handleSubmit, handlePreviousPage, sizes } = props

  return (
    <>
      <form
        id="new-app-platform-form"
        className="new-app-form"
        onSubmit={handleSubmit}
      >

        <Field name="sizes" component={PlatformToggle} />

      </form>
      
      <WizardFooter>
        {handlePreviousPage ? (
          <Button className="previous" onClick={handlePreviousPage}>
            Назад
          </Button>
        ) : null}
        <Button
          className="next align-right"
          type="submit"
          disabled={!sizes}
          form="new-app-platform-form"
        >
          Далее
        </Button>
      </WizardFooter>
    </>
  )
}

function mapStateToProps(state) {
  let selector = formValueSelector(FORM_NAME)

  return {
    sizes: selector(state, 'sizes'),
  }
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(mapStateToProps)(WizardFormPlatformPage))
