// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-inspect .library-inspect-help-text {
  font-size: 11px;
  color: #777;
  margin-top: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/HelpText.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB","sourcesContent":[".library-inspect .library-inspect-help-text {\n  font-size: 11px;\n  color: #777;\n  margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
