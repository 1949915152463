// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inline-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inline-form .wrapped-select .Select-input,
.inline-form .wrapped-select .Select-control {
  height: 26px;
}

.inline-form .wrapped-select .Select-placeholder {
  line-height: 26px;
}

.inline-form .wrapped-select .Select-control .Select-value {
  line-height: 26px;
}

.inline-form input {
}

.inline-form input[type="text"] {
  width: 100%;
  font: inherit;
  background: #fff;
  border: 2px solid #eee;
  outline: none;
  border-radius: 8px;
  padding: 0 8px;
  height: 32px;
}

.inline-form .multi-select-menu {
  height: 32px;
}

.inline-form .multi-select-menu .multi-select-menu-selection {
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 20px;
}

.inline-form > * {
  flex: 1 1;
}

.inline-form > * + * {
  margin-left: 15px;
}

.inline-form > .auto-width {
  flex: none;
}

.inline-form a.deemphasize {
  font-size: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/InlineForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;AACA;;AAEA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".inline-form {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.inline-form .wrapped-select .Select-input,\n.inline-form .wrapped-select .Select-control {\n  height: 26px;\n}\n\n.inline-form .wrapped-select .Select-placeholder {\n  line-height: 26px;\n}\n\n.inline-form .wrapped-select .Select-control .Select-value {\n  line-height: 26px;\n}\n\n.inline-form input {\n}\n\n.inline-form input[type=\"text\"] {\n  width: 100%;\n  font: inherit;\n  background: #fff;\n  border: 2px solid #eee;\n  outline: none;\n  border-radius: 8px;\n  padding: 0 8px;\n  height: 32px;\n}\n\n.inline-form .multi-select-menu {\n  height: 32px;\n}\n\n.inline-form .multi-select-menu .multi-select-menu-selection {\n  padding-top: 6px;\n  padding-bottom: 6px;\n  line-height: 20px;\n}\n\n.inline-form > * {\n  flex: 1;\n}\n\n.inline-form > * + * {\n  margin-left: 15px;\n}\n\n.inline-form > .auto-width {\n  flex: none;\n}\n\n.inline-form a.deemphasize {\n  font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
