// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes notification-slide-in {
  from {
    transform: translateY(160px);
    margin-top: -64px;
  }

  to {
    transform: translateY(0);
    margin-top: 20px;
  }
}

@keyframes notification-slide-out {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
    margin-top: 20px;
  }

  50% {
    opacity: 0;
    margin-top: -64px;
  }

  100% {
    opacity: 0;
    margin-top: -64px;
  }
}

.notifications {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 100001;
  pointer-events: none;
  max-width: 300px;
}

.notification-item {
  margin-top: 20px;
  pointer-events: all;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.notification-item-enter {
  animation: notification-slide-in 0.3s 1;
}

.notification-item-exit {
  animation: notification-slide-out 1s 1;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Notifications.css"],"names":[],"mappings":"AAAA;EACE;IACE,4BAA4B;IAC5B,iBAAiB;EACnB;;EAEA;IACE,wBAAwB;IACxB,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,iBAAiB;EACnB;AACF;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":["@keyframes notification-slide-in {\n  from {\n    transform: translateY(160px);\n    margin-top: -64px;\n  }\n\n  to {\n    transform: translateY(0);\n    margin-top: 20px;\n  }\n}\n\n@keyframes notification-slide-out {\n  0% {\n    opacity: 1;\n  }\n\n  25% {\n    opacity: 0;\n    margin-top: 20px;\n  }\n\n  50% {\n    opacity: 0;\n    margin-top: -64px;\n  }\n\n  100% {\n    opacity: 0;\n    margin-top: -64px;\n  }\n}\n\n.notifications {\n  position: fixed;\n  bottom: 40px;\n  right: 40px;\n  z-index: 100001;\n  pointer-events: none;\n  max-width: 300px;\n}\n\n.notification-item {\n  margin-top: 20px;\n  pointer-events: all;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.notification-item-enter {\n  animation: notification-slide-in 0.3s 1;\n}\n\n.notification-item-exit {\n  animation: notification-slide-out 1s 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
