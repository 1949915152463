import * as  _ from 'underscore'


export const match = (A, B, rankA, rankB) => {
  if (!A || !B || !A.length || !B.length) return [];
  if (A.length == B.length) return _match(A, B, rankA, rankB); // TODO: this is a brute-force implementation of getting both
  //       lists to be of symmetric length... make this "better".
  //       for example, build this directly into _match() or use
  //       deterministic exclusion of the longer data set.

  var sA = _.rest(A, 0);

  var sB = _.rest(B, 0);

  var mlen = Math.max(sA, sB);

  while (sA.length < mlen) {
    sA.push(null);
  }

  while (sB.length < mlen) {
    sB.push(null);
  }

  var sRA = function sRA(a) {
    var ret = rankA(a);

    while (ret.length < mlen) {
      ret.push(null);
    }

    return ret;
  };

  var sRB = function sRB(b) {
    var ret = rankB(b);

    while (ret.length < mlen) {
      ret.push(null);
    }

    return ret;
  };

  var ret = _match(sA, sB, sRA, sRB);

  return _.filter(ret, function (pair) {
    return pair[0] != null && pair[1] != null;
  });
}; //---------------------------------------------------------------------------


export const _match =(A, B, rankA, rankB) =>{
  // this translates sets A and B to indeces, since _imatch can only work
  // with sets of elements that can be used as the key in a hash (in this
  // implementation).
  var iA = _.range(A.length);

  var iB = _.range(B.length);

  var iRA = function iRA(ia) {
    var ret = rankA(A[ia]);
    return _.map(ret, function (item) {
      return _.indexOf(B, item);
    });
  };

  var iRB = function iRB(ib) {
    var ret = rankB(B[ib]);
    return _.map(ret, function (item) {
      return _.indexOf(A, item);
    });
  };

  var ret = _imatch(iA, iB, iRA, iRB);

  return _.map(ret, function (item) {
    return [A[item[0]], B[item[1]]];
  });
}; //---------------------------------------------------------------------------


export const _imatch = (A, B, rankA, rankB) => {
  // TODO: improve this... it was a brute-force porting of
  //         https://github.com/paulgb/Python-Gale-Shapley
  //       without any eye on optimal outcome or performance...
  //: `partners` is a paring hash of { a => [b, rank] }
  var partners = {};

  _.each(A, function (a) {
    partners[a] = [rankA(a)[0], 0];
  }); //: `stable` indicates stability of the current pairing in `partners`


  var stable = false;

  while (!stable) {
    stable = true;

    _.each(B, function (b) {
      var paired = false;

      for (var n = 0; n < A.length; n++) {
        var a = rankB(b)[n];
        var pair = partners[a];

        if (pair[0] == b) {
          if (paired) {
            stable = false;
            partners[a] = [rankA(a)[pair[1] + 1], pair[1] + 1];
          } else paired = true;
        }
      }
    });
  }

  return _.map(_.keys(partners), function (a) {
    return [a, partners[a][0]];
  });
};
