import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import './Layers.scss'

import {shallowEqual} from 'utils/arrays'

import {getHoverSelection, getSelection, setLayersHover, setSelection,} from 'ducks/editor/selection'

import {getPath, reorderObjects, selectObject, updateObject,} from 'ducks/editor/objects'

import {drag, endDrag, getLayers, setDropTarget, startDrag,} from 'ducks/editor/layers'

import {setActiveAddTab, setActiveTab} from 'ducks/editor/tabs'

import {PanelItem} from 'components/Shared/Panel'

import LayerItem, {EMPTY_ARRAY} from './Item'
import DragLayer from './DragLayer'

class LayersMenu extends Component {
  static childContextTypes = {
    getSelection: PropTypes.func,
    getLayers: PropTypes.func,
    execute: PropTypes.func,
    getDraggingObjects: PropTypes.func,
  }

  getChildContext = () => ({
    getSelection: this.getSelection,
    getLayers: this.getLayers,
    execute: this.handleExecute,
    getDraggingObjects: this.getDraggingObjects,
  })

  getSelection = () => {
    let { rawSelection } = this.props

    return rawSelection
  }

  getLayers = () => {
    let { layers } = this.props

    return layers
  }

  getDraggingObjects = () => {
    let { dragLayerObjects } = this.props

    return dragLayerObjects
  }

  handleExecute = (func, ...args) => {
    // eslint-disable-next-line react/destructuring-assignment
    let funcValue = this.props[func]

    if (!funcValue) {
      throw new Error(`execute('${func}') failed: ${func} is not defined`)
    }

    return funcValue(...args)
  }

  handleAddComponent = async () => {
    const { setActiveTab, setActiveAddTab } = this.props
    await setActiveAddTab('component')

    return setActiveTab('add')
  }

  shouldComponentUpdate(newProps) {
    let { objects, selection, layers } = this.props

    if (
      !shallowEqual(newProps.objects, objects) ||
      !shallowEqual(newProps.selection, selection)
    ) {
      return true
    }

    if (newProps.layers !== layers) {
      return true
    }

    return false
  }

  render() {
    let {
      layers,
      objects,
      selection,
      hoverSelection,
      dropTarget,
      dragLayerObjects,
      path,
    } = this.props

    let dropBefore = !layers.options.dropAfter && !layers.options.dropInside
    let dropAfter = layers.options.dropAfter
    let dropInside = layers.options.dropInside


    return (
      <>
        <div className="layer-items">
          {objects.length > 0 &&
            objects
              .slice()
              .reverse()
              .map((obj, i) => (
                <LayerItem
                  key={obj.id}
                  path={`${path}.${String(objects.length - i - 1)}`}
                  object={obj}
                  selection={selection}
                  hoverSelection={hoverSelection}
                  dragInProgress={layers.dragging}
                  dropTarget={dropTarget}
                  dropBefore={dropBefore}
                  dropAfter={dropAfter}
                  dropInside={dropInside}
                />
              ))}
          {layers.dragging ? (
            <DragLayer
              {...layers}
              objectIds={layers.objects}
              objects={dragLayerObjects}
            />
          ) : null}
        </div>
        <PanelItem
          className="layer-item"
          title="Добавить объект"
          onClick={this.handleAddComponent}
          icon="plus"
          color="screens"
          uppercase
        />
      </>
    )
  }
}

const mapStateToProps = (state, { objectId }) => {
  let layers = getLayers(state)
  let dragLayerObjects = EMPTY_ARRAY

  if (layers.objects && layers.objects.length > 0) {
    dragLayerObjects = layers.objects.map(id => {
      return selectObject(state, id)
    })
  }

  let object = selectObject(state, objectId)
  let path = getPath(state, objectId)
  let children = object?.children || EMPTY_ARRAY

  return {
    layers,
    dragLayerObjects,
    objects: children,
    path,
    dropTarget: getPath(state, layers.dropTarget),
    rawSelection: getSelection(state),
    selection: getSelection(state).map(id => getPath(state, id)),
    hoverSelection: getHoverSelection(state).map(id => getPath(state, id)),
  }
}

const ConnectedLayersMenu = connect(mapStateToProps, {
  setLayersHover,
  setSelection,
  reorderObjects,
  updateObject,
  startDrag,
  drag,
  endDrag,
  setDropTarget,
  setActiveTab,
  setActiveAddTab,
})(LayersMenu)

export default ConnectedLayersMenu
