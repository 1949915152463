// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-icon-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-icon-option-label {
  flex: 1 1;
}

.select-icon-option .icon {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/IconOption.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".select-icon-option {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.select-icon-option-label {\n  flex: 1;\n}\n\n.select-icon-option .icon {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
